<template lang="pug">
  .organization-plan-billing-consumption
    fp-loader(type="chart" v-if="loading")
    .placeholder(v-if="noData && !loading")
      img(:src="require('@/core/assets/img/placeholder_chart.png')")
      .text {{ $t('echarts.no-activity') }}
    fpui-input-select.select-type(
      v-model="type",
      :clearable="false"
      :options="types"
      ,v-if="!loading && !noData"
    )
    fpui-echarts(
      v-if="!noData && !loading"
      :options="options"
      style="height: 210px"
    )
</template>

<script>
import moment from 'moment'
import _merge from 'lodash/merge'
import _uniq from 'lodash/uniq'
import _flatten from 'lodash/flatten'
import _concat from 'lodash/concat'
import hatch1 from '@/core/assets/img/hatch-1.svg'
import hatch2 from '@/core/assets/img/hatch-2.svg'

export default {
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      type: 'fpu'
    }
  },
  computed: {
    series () {
      const hatchedPattern1 = new Image()
      const hatchedPattern2 = new Image()
      hatchedPattern1.src = hatch1
      hatchedPattern2.src = hatch2
      const colors = ['rgb(0,204,246)', 'rgb(0, 137, 192)']
      const patterns = [hatchedPattern1, hatchedPattern2]
      const data = this.organization?.consumptions?.byDay || {}
      if (this.type === 'fpu' || this.type === 'fpug') {
        const providers = _uniq(_flatten(_concat(Object.values(data).map(d => Object.keys(d.fpu)))))
        return providers.map((provider, i) => {
          return {
            name: this.$t(`organization.chart.${this.type}`) + ` (${provider})`,
            type: 'bar',
            stack: true,
            data: Object.keys(data).map(d => {
              const isPrevision = moment(d).isSameOrAfter(moment(), 'day')
              const currentData = {
                value: data[d][this.type][provider].days
              }
              if (isPrevision) {
                currentData.itemStyle = {
                  color: {
                    image: isPrevision ? patterns[i % colors.length] : null,
                    value: !isPrevision ? colors[i % colors.length] : null,
                    color: colors[i % colors.length],
                    repeat: 'repeat'
                  }
                }
              }
              return currentData
            }),
            itemStyle: {
              color: () => {
                return colors[i % colors.length]
              }
            }
          }
        })
      }
      return [
        {
          name: this.$t(`organization.chart.${this.type}`),
          type: 'bar',
          tooltip: {
            formatter: (params) => {
              return 'hej'
            }
          },
          data: Object.keys(data).map(d => {
            // This can't reach that conditional since it already falls into that same one above
            // if (this.type === 'fpu') {
            //   let total = 0
            //   for (const provider in d[this.type]) {
            //     total += d[this.type][provider].days
            //   }
            //   return total
            // }
            const isPrevision = moment(d).isSameOrAfter(moment(), 'day')
            const currentData = { value: data[d][this.type].days }
            if (isPrevision) {
              currentData.itemStyle = {
                color: {
                  image: isPrevision ? patterns[0] : null,
                  value: !isPrevision ? colors[0] : null,
                  color: colors[0],
                  repeat: 'repeat'
                }
              }
            }
            return currentData
          }),
          itemStyle: {
            color: colors[0]
          }
        }
      ]
    },
    options () {
      const data = this.organization?.consumptions?.byDay || {}
      return _merge(this.barChartOptions, {
        noData: false,
        xAxis: {
          data: Object.keys(data).map(v => moment(v).format('DD/MM'))
        },
        yAxis: [
          {
            axisLabel: {
              formatter: (value) => value
            }
          }
        ],
        series: this.series,
        tooltip: {
          formatter: (params) => {
            const tooltipTitle = `<div style="font-weight: bold; line-height: 20px; font-size: 13px;">${params[0].axisValueLabel}</div>`
            const tooltipTitlePrevision = `<div style="font-weight: bold; line-height: 20px; font-size: 13px;">${params[0].axisValueLabel}</div>`
            let series = ''
            let hasPrevision = false
            params.forEach(serie => {
              if (!hasPrevision) hasPrevision = !!serie.color?.image
              const serieColorStyle = `font-size: 30px; line-height: 25px; color: ${serie.color?.image ? serie.color.color : serie.color}; margin-right: 5px;`
              const serieColorElement = `<span style="${serieColorStyle}">•</span>`
              const serieName = `<div>${serie.seriesName}</div>:`
              const serieValue = `<div style="margin-left: 5px; font-weight: bold">${Math.floor(serie.value)}</div>`
              series += `<div style="display: flex; justify-content: space-between; align-items: center;"><div style="display: flex; align-items: center;">${serieColorElement} ${serieName}</div>${serieValue}</div>`
            })
            return (hasPrevision ? tooltipTitlePrevision : tooltipTitle) + series
          }
        }
      })
    },
    loading () {
      return false
    },
    noData () {
      return !this.organization.consumptions
    },
    types () {
      return [{
        label: this.$t('organization.plan.billing.fpus'),
        value: 'fpu'
      }, {
        label: this.$t('organization.plan.billing.fpug'),
        value: 'fpug'
      }, {
        label: this.$t('organization.plan.billing.dataplant'),
        value: 'dataplant'
      }]
    },
    isCustom () {
      return this.subscription?.plan === 'custom'
    },
    account () {
      if (!this.organization.customerAccountId) return null
      return this.$store.getters.ACCOUNT_BY_ID(this.organization.customerAccountId)
    },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization?.subscription
    }
  }
}
</script>

<style lang="less">
.organization-plan-billing-consumption {
  position: relative;
  .select-type {
    position: absolute;
    right:0;
    top:-25px;
    width: 150px;
  }

  .placeholder {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 110px;

    img {
      width: 150px;
      margin-top: 10px;
      margin-bottom: 5px;
    }

    .text {
      color: #97A7B7;
    }
  }
}

</style>
