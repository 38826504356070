<template lang="pug">
  .multi-factor-authentication
    .title-container
      .title {{ $t('profile.security.mfa') }}
      fpui-input-toggle(
        :value="user.mfa.active"
        @input="update"
      )
    .text {{ $t('profile.security.mfa.content') }}
    .reset-mfa(v-if="user.mfa.active")
      fpui-button(
        color="blue-flash"
        @click="resetMFA()"
        auto-width
      ) {{ mfaButtonText }}
      .qr-code-container(v-if="qrcode")
        img(:src="qrcode")
        .secret {{$t('profile.mfa.qr_helper',[secret])}}
</template>
<script>

export default {
  data () {
    return {
      secret: null,
      qrcode: null
    }
  },
  computed: {
    user () {
      return this.$store.getters.SESSION
    },
    mfaButtonText () {
      return this.user.mfa?.generated_at
        ? this.$t('profile.security.mfa.regenerate')
        : this.$t('profile.security.mfa.generate')
    }
  },
  methods: {
    async resetMFA () {
      const res = await this.$api.KING.resetMFA()
      this.qrcode = res.qrcode
      this.secret = res.secret
    },
    update () {
      this.user.update('mfa.active', !this.user.mfa.active, true)
      this.$emit('load-orgs')
    }
  }
}
</script>
<style lang="less">
  .multi-factor-authentication {
    width: 100%;
    .title-container {
      display: flex;
      justify-content: space-between;
      .fpui-input-toggle {
        width: auto;
      }
    }
    .reset-mfa {
      .button-container {
        margin-top: 10px;
        display: inline-block;
      }
      text-align: center;
    }
  }
</style>
