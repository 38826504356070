<template lang="pug">
.project-dashboard
  fp-loader(v-if="loading")
  .project-layout-empty(
    v-if="(!layoutTiles.length) && !project.services.length"
  )
    span Project empty

  .move-information-container(
    v-if="layoutTiles.length && editing"
  )
    i.fp4.fp4-arrows-up-down-left-right
    span {{ $t('project.editing_mode_active') }}
  .project-layout-container(
    v-if="layoutTiles.length"
    :class="{ editing }"
  )
    dashboard-layout.services-layout(
      :class="{ editing }"
      :layout="layout"
      :col-num="colNum"
      :row-height="rowHeight"
      :is-draggable="!editing"
      :is-resizable="false"
      :editing="editing"
      :horizontal-compact="false"
      :use-css-transforms="true"
      :min-w="minWidth"
      :min-h="rowHeight"
      :margin="margin"
      is-bounded
      @layout-updated="layoutUpdated"
    )
      dashboard-item.service-item(
        v-for="tile in layoutTiles"
        v-show="!tile.hide"
        :key="tile.i"
        :x="tile.x"
        :y="tile.y"
        :w="tile.width"
        :h="tile.height"
        :i="tile.i"
        :type="tile.type"
        :min-h="rowHeight"
        :min-w="minWidth"
        :max-w="maxWidth"
        :static="tile.static"
        :is-resizable="false"
        :is-draggable="editing && !tile.static"
        :style-override="tile.styleOverride"
        :style="{ 'z-index': zIndex(tile) }"
        :editing="editing"
        @mouseover.native="tileHovered(tile)"
        @mouseleave.native="tileNotHovered(tile)"
      )
        .service-item-content(
          v-if="tile.type !== 'empty-spot'"
          :style="{ 'border-color': tile.type === 'category' ? tile.color : '#CBD3DB', background: tile.type === 'category' ? hexToRGBA(tile.color, 0.02) : '#FFFFFF' }"
          :class="[tile.type]"
        )
          dashboard-tile(
            v-if="tile.type === 'service' && project.services.includes(['app', 'api'].includes(tile.i) ? 'appservice' : tile.i)"
            :key="project.id"
            :service="tile.i"
            :hasAccess="serviceHasAccess(tile.i)"
            :project-id="projectId"
            :loading="['api', 'app'].includes(tile.i) ? loadingApps : loading"
            :error="hasError(tile.i)"
            :mode="getMode(tile.i)"
            :tile="tile"
            :service-dependencies="servicesDependencies"
            :service-required="servicesRequired"
            :editing="editing"
            @delete-tile="deleteTile"
            @active-tile="activeTile"
            @deactive-tile="deactiveTile"
            @create-api-app="create"
            @open-app="openApp"
            @edit-app="editApp"
            @resize-tile="resizeService"
            @set-editing-mode="setEditingMode"
          )

          .category-content(
            v-if="tile.type === 'category'"
          )
            .category-name-container(
              :style="{ background: tile.type === 'category' ? tile.color : '#FFFFFF' }"
            )
              span {{ categoryName(tile.i) }}
              i.fp4.fp4-burger(
                v-if="editing"
              )

        .service-item-empty-spot(
          v-if="tile.type === 'empty-spot'"
        )

  .footer
    .floating-container
      fpui-button(
        v-if="!editing"
        icon='fp4 fp4-plus'
        icon-left
        color="blue-flash"
        @click="addService"
        auto-width
      ) {{ $t('project.footer.add_service') }}
      fpui-button(
        v-if="editing"
        icon='fp4 fp4-check-bold'
        icon-left
        color="green"
        @click="confirmEdit"
        auto-width
      ) {{ $t('project.footer.confirm_changes') }}
      fpui-button.cancel(
        v-if="editing"
        icon='fp4 fp4-xmark-bold'
        icon-left
        color="red"
        @click="cancelEdit"
        auto-width
      ) {{ $t('cancel') }}
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import _isEqual from 'lodash/isEqual'
import _get from 'lodash/get'

import AppServiceCreateModal from '@/core/components/AppService/CreateModal'
import Store from '@/shared/components/store'
import Config from '@/shared/Config'

import { DashboardLayout, DashboardItem } from './DashboardLayout'
import DashboardTile from './DashboardTile'
import Layout from './DashboardLayout/helpers/layout'
import { getDefaultTemplateForServices } from './DashboardLayout/helpers/default'
import qs from 'qs'


export default {
  components: {
    DashboardLayout,
    DashboardItem,
    DashboardTile
  },
  props: {
    projectId: { type: String, required: true }
  },
  data () {
    return {
      layout: null,
      colNum: 4,
      minWidth: 1,
      maxWidth: this.colNum,
      rowHeight: 150,
      margin: [32, 32],
      editing: false,
      activeTiles: [],
      hoveredTiles: [],
      hasAccess: {
        dpe: true,
        ml: true,
        gab: true,
        iam: true,
        dm: true,
        am: true,
        appservice: true,
        datastore: true,
        'control-center': true
      },
      session: null,
      applications: null,
      errors: {
        app: null,
        session: null
      },
      loading: true,
      loadingApps: true,
      servicesDependencies: {},
      servicesRequired: [],
      confirmProcess: false
    }
  },
  computed: {
    project () {
      const project = this.$store.getters.DATAPLANT_BY_ID(this.projectId)

      // We need to do this here because services data-catalog and lakehouse are removed when we save the project
      if (project.services.includes('dm')) {
        if (!project.services.includes('data-catalog')) project.services.push('data-catalog')
        if (!project.services.includes('lakehouse')) project.services.push('lakehouse')
      }

      return project
    },
    tiles () {
      return _cloneDeep(this.project?.display_settings?.home_layout || [])
    },
    layoutTiles () {
      return this.layout?.tiles || []
    },
    canAmQueryHistory () {
      return this.$store.getters.ACL('canAmQueryHistory')
    },
    canAmDashboardRead () {
      return this.$store.getters.ACL('canAmDashboardRead')
    },
    canDmLogicalRead () {
      return this.$store.getters.ACL('canDmLogicalRead')
    },
    canMlNotebookRead () {
      return this.$store.getters.ACL('canMlNotebookRead')
    },
    canCcAlertRead () {
      return this.$store.getters.ACL('canCcAlertRead')
    },
    canIamMetricRead () {
      return this.$store.getters.ACL('canIamMetricRead')
    },
    canIamUserRead () {
      return this.$store.getters.ACL('canIamUserRead')
    },
    canAabAssistantRead () {
      return this.$store.getters.ACL('canAabAssistantRead')
    },
    canAabKnowledgeBasesRead () {
      return this.$store.getters.ACL('canAabKbRead')
    }
  },
  watch: {
    async 'project.id' () {
      if (this.editing) this.cancelEdit()
      this.updateDmTilesToDataCatalogAndLakehouse()
      this.$store.commit('SET_CARD_INFO_LOADING', true)
      await this.loadDashboard()
    },
    async 'project.display_settings.services_wizard' (val) {
      if (!_isEqual(val, this.project?.display_settings?.services_wizard)) await this.initLayout()
    },
    editing: {
      handler (val) {
        if (val) this.layout.addEmptySpotTiles()
        else this.layout.removeEmptySpotTiles()
      }
    },
    'layout.tiles': {
      deep: true,
      handler () {
        if (!this.editing) this.layoutUpdated()
      }
    }
  },
  async mounted () {
    this.updateDmTilesToDataCatalogAndLakehouse()

    // VERY IMPORTANT - Put at the end because never finish
    await this.loadDashboard()
  },
  destroyed () {
    if (this.editing) this.cancelEdit()
  },
  methods: {
    updateDmTilesToDataCatalogAndLakehouse () {
      // If home_layout includes 'dm' -> replace it by data-catalog & lakehouse manager using default template
      if (this.project?.display_settings?.home_layout?.find(l => l.i === 'dm')) {
        const newLayout = getDefaultTemplateForServices(this.project?.services)
        this.project.update('display_settings.home_layout', _cloneDeep(newLayout))
      }
    },
    categoryName (category) {
      return this.$i18n.keyExists(`services.title.${category}`) ? this.$t(`services.title.${category}`) : category
    },
    addService () {
      this.$analytics.track('Open service addition funnel', { from: 'project home' })
      const queryString = qs.stringify({
        project_id: this.projectId
      })
      window.open(`${window.location.origin}/#/home/${this.project.organization_id}/services?${queryString}`, '_self')
    },
    async initLayoutKpi () {
      this.$store.commit('SET_APPLICATIONS_LOADING', true)
      try {
        if (this.projectHasService('am') && ['M', 'L'].includes(this.getServiceSize('am'))) {
          this.$store.dispatch('LOAD_AM_RESOURCES')
          if (this.canAmQueryHistory) this.$store.dispatch('LOAD_QUERY_HISTORY')
          if (this.canAmDashboardRead) this.$store.dispatch('LOAD_DASHBOARDS')
        }
        if (this.projectHasService('dm') && ['M', 'L'].includes(this.getServiceSize('dm'))) {
          if (this.canDmLogicalRead) this.$store.dispatch('LOAD_LOGICAL_OBJECTS')
          if (this.canDmDatabaseRead) this.$store.dispatch('LOAD_DATABASES')
          this.$store.dispatch('LOAD_RULES')
        }
        if (this.projectHasService('mlm') && ['M', 'L'].includes(this.getServiceSize('mlm')) && this.canMlNotebookRead) this.$store.dispatch('LOAD_NOTEBOOKS')
        if (this.projectHasService('gab') && ['M', 'L'].includes(this.getServiceSize('gab')) && this.canAabAssistantRead) this.$store.dispatch('LOAD_ASSISTANTS')
        if (this.projectHasService('gab') && ['M', 'L'].includes(this.getServiceSize('gab')) && this.canAabKnowledgeBasesRead) this.$store.dispatch('LOAD_KNOWLEDGE_BASES')
        if (this.projectHasService('control-center') && ['M', 'L'].includes(this.getServiceSize('control-center')) && this.canCcAlertRead) this.$store.dispatch('LOAD_ACTIVE_ALERTS')
        if (this.projectHasService('iam') && ['M', 'L'].includes(this.getServiceSize('iam')) && this.canIamMetricRead && this.canIamUserRead) this.$store.dispatch('LOAD_USERS_SESSIONS')
      } catch (err) {
        // Do nothing
      }

      this.project.services.forEach(async service => {
        if (['appservice', 'dpe', 'dm', 'am', 'iam', 'control-center', 'mlm', 'gab'].includes(service)) {
          const metas = await this.$api.STORE.metas('project', 'modules', service, 'latest')
          this.servicesDependencies[service] = Object.keys(metas?.options?.dependencies || {})
          if (metas?.options?.required) this.servicesRequired.push(service)
        }
      })
    },
    async initLayout (loadKpi = true) {
      if (loadKpi) await this.initLayoutKpi()
      const config = await Config()
      const services = this.project.services.filter(s => {
        if (s === 'mlm' && config.DATA_PLATFORM_ACCESS) return false
        if (s === 'gab' && !config.DATA_PLATFORM_ACCESS) return false
        return true
      })
      const layout = {
        tiles: _cloneDeep(this.project?.display_settings?.home_layout),
        services,
        colNum: this.colNum,
        rowHeight: this.rowHeight,
        minWidth: this.minWidth,
        margin: this.margin
      }

      this.layout = new Layout(layout)
    },
    layoutUpdated () {
      if (this.editing) return
      if (this.layout.hasChanges) {
        if (this.confirmProcess) {
          const sizes = {
            small: 0,
            medium: 0,
            large: 0
          }

          this.layoutTiles.forEach(tile => {
            if (tile.type === 'service') {
              if (tile.width === 2 && tile.height === 2) sizes.large++
              if (tile.width === 1 && tile.height === 2) sizes.medium++
              if (tile.width === 1 && tile.height === 1) sizes.small++
            }
          })

          this.$analytics.track('Edit layout of project home', {
            final_nb_small_cards: sizes.small,
            final_nb_medium_cards: sizes.medium,
            final_nb_large_cards: sizes.large
          })
          this.confirmProcess = false
        }
        const cleanLayout = this.layout.tiles.filter(t => t.type !== 'empty-spot')
        this.project.update('display_settings.home_layout', _cloneDeep(cleanLayout))
        this.layout.updateOriginalTiles()
      }
    },
    zIndex (tile) {
      if (tile.type === 'empty-spot') return 0
      if (tile.type === 'category') return 1
      if (tile.resizing || tile.moving || this.activeTiles.includes(tile.i)) return 999
      if (tile.resizing || tile.moving || this.hoveredTiles.includes(tile.i)) return 990

      return this.tiles.length - tile.y + (4 - tile.x)
    },
    tileHovered (tile) {
      if (!this.hoveredTiles.includes(tile.i)) this.hoveredTiles.push(tile.i)
    },
    tileNotHovered (tile) {
      if (this.hoveredTiles.includes(tile.i)) {
        this.hoveredTiles = this.hoveredTiles.filter(i => i !== tile.i)
      }
    },
    activeTile (t) {
      if (!this.activeTiles.includes(t.i)) this.activeTiles.push(t.i)
    },
    deactiveTile (t) {
      if (this.activeTiles.includes(t.i)) {
        this.activeTiles = this.activeTiles.filter(tile => tile !== t.i)
      }
    },
    deleteTile (service) {
      this.layout.deleteTile({ i: service })
      this.layoutUpdated()
    },
    resizeService (tileId, newW, newH) {
      this.layout.resizeTile(tileId, newW, newH)
    },
    hexToRGBA (hexCode, opacity = 1) {
      let hex = hexCode.replace('#', '')

      if (hex.length === 3) {
        hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
      }

      const r = parseInt(hex.substring(0, 2), 16)
      const g = parseInt(hex.substring(2, 4), 16)
      const b = parseInt(hex.substring(4, 6), 16)

      /* Backward compatibility for whole number based opacity values. */
      if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100
      }

      return `rgba(${r},${g},${b},${opacity})`
    },
    async loadDashboard () {
      if (!this.project) return this.$router.push('/home')

      // Open create api modal when there is a query new_appservice === 'api'
      if (this.$route.query?.new_appservice === 'api') {
        this.create('api')
      }

      const trackFrom = window.localStorage.getItem('tracking-from')
      if (trackFrom) {
        if (trackFrom.includes('{')) {
          const trackObj = JSON.parse(trackFrom)
          this.$analytics.track('Open project', { from: trackObj.from })
          if (trackObj.from === 'header') {
            this.$analytics.track('Switch project from header', {
              organization_id: trackObj.fromOrganizationId,
              project_id: trackObj.fromProjectId,
              destination_organization_id: this.project.organization_id,
              destination_project_id: this.projectId
            })
          }
        } else {
          this.$analytics.track('Open project', { from: trackFrom })
        }
        window.localStorage.removeItem('tracking-from')
      }

      // Different event because product need it to analyse in mixpanel
      const trackFromObj = window.localStorage.getItem('tracking-from-object')
      if (trackFromObj) {
        const details = JSON.parse(trackFromObj)
        this.$analytics.track('Switch project from sidebar', {
          organization_id: details.fromOrganizationId,
          project_id: details.fromProjectId,
          destination_organization_id: this.project.organization_id,
          destination_project_id: this.projectId
        })
        window.localStorage.removeItem('tracking-from-object')
      }

      this.loading = true
      this.session = null
      this.applications = null
      this.$store.commit('SET_APPLICATIONS', [])
      this.loadingApps = true
      this.errors = {
        app: null,
        session: null
      }
      // Connect to the IAM, to refresh or generate a session inside
      try {
        const { token } = await this.$api.KING.users.me()
        this.session = await this.$api.IAM.reply(token)
        this.$store.dispatch('LOAD_ALL_ACLS', this.project?.services)
        this.loading = false
        this.$store.commit('SET_CARD_INFO_LOADING', false)
        await this.initLayout()
        await this.loadAcls()
        if (this.projectHasService('appservice')) await this.loadApps()
      } catch (err) {
        await this.initLayout(false)
        this.errors.session = err
        console.error(err)
      } finally {
        this.loading = false
        this.loadingApps = false
      }
    },
    createSecondStep (type, tpl) {
      const context = this
      this.$modal.show(AppServiceCreateModal, {
        template: tpl.id,
        applications: context.applications,
        type,
        onCreate (app) {
          this.applications.push(app)
          this.$store.commit('SET_APPLICATIONS', this.applications)
          this.$router.push(`/${app.type}/${context.projectId}/${app.app_name}`)
        }
      }, {
        width: 980,
        height: 'auto'
      })
    },
    create (type) {
      const context = this
      this.$modal.show(Store, {
        name: this.$t(`appservice.store.add.${type}`),
        type: `${type}.templates`,
        items: {
          import: {
            name: this.$t('appservice.store.import.name'),
            description: this.$t('appservice.store.import.description'),
            long_description: this.$t('appservice.store.import.long_description'),
            category: [
              'custom'
            ],
            owner: 'forepaas',
            image: require('@/core/components/AppService/assets/store_custom.png')

          }
        },
        onSelect (tpl) {
          context.createSecondStep(type, tpl)
          this.$emit('close')
        }
      }, {
        width: 980,
        height: 640
      })
    },
    openApp (app) {
      window.open(`https://${this.project.url || this.project.url}/${app.app_name}/`)
    },
    editApp (app) {
      this.$router.push(`/${app.type}/${this.project.dataplant_id || this.project.id}/${app.app_name}`)
    },
    async loadAcls () {
      await Promise.all(Object.keys(this.hasAccess).map(async srv => {
        this.hasAccess[srv] = await this.$api.IAM.can(srv)
      }))
    },
    async later (ms = 5000) {
      return new Promise(resolve => setTimeout(resolve, ms))
    },
    async loadApps (retry = 0) {
      try {
        this.applications = this.hasAccess.appservice ? await this.$api.APPSERVICE.applications.list(0) : []
        this.$store.commit('SET_APPLICATIONS', this.applications)
        this.errors.app = null
      } catch (err) {
        err.message = _get(err, 'response.data.message') || _get(err, 'response.data.Error.Message[0]') || _get(err, 'response.data.error') || err.message
        err.code = _get(err, 'response.data.error') || _get(err, 'response.data.Error.Code[0]') || err.code || err.message
        err.status = _get(err, 'response.status') || err.status

        if ((err.status === 401 || [err.message, err.code].includes('InvalidAuthentication')) && retry === 0) {
          const { token } = await this.$api.KING.users.me()
          this.session = await this.$api.IAM.reply(token)
          return this.loadApps(1)
        }

        this.applications = []
        this.$store.commit('SET_APPLICATIONS', this.applications)
        this.errors.app = err
        console.error(err)
        this.$store.commit('SET_APPLICATIONS_LOADING', false)
      }
      this.loadingApps = false
      this.$store.commit('SET_APPLICATIONS_LOADING', false)
      // Auto refresh list
      await this.later(60000)
      return this.loadApps()
    },
    serviceHasAccess (service) {
      if (['api', 'app'].includes(service)) return this.hasAccess.appservice
      if (['ml', 'mlm'].includes(service)) return this.hasAccess.ml
      if (['data-catalog'].includes(service)) return this.hasAccess.dm
      if (['lakehouse'].includes(service)) return this.hasAccess.dm || this.hasAccess.datastore
      if (['gab'].includes(service)) return this.hasAccess.gab
      return this.hasAccess[service]
    },
    hasError (service) {
      if (['api', 'app'].includes(service)) return this.errors.app
      if (service === 'iam') return this.errors.session

      return null
    },
    getMode (service) {
      if (['api', 'app'].includes(service)) return 'manual'
      return 'bulk'
    },
    projectHasService (service) {
      if (service === 'appservice') {
        return this.project.services.includes(service) && !Object.keys(this.project.display_settings?.services_wizard || {}).includes('api') && !Object.keys(this.project.display_settings?.services_wizard || {}).includes('app')
      }
      return this.project.services.includes(service) && !Object.keys(this.project.display_settings?.services_wizard || {}).includes(service)
    },
    getServiceSize (service) {
      const tile = this.tiles.find(t => t.i === service)
      if (!tile) return null
      if (tile.width === 2 && tile.height === 2) return 'L'
      if (tile.width === 1 && tile.height === 2) return 'M'
      return 'S'
    },
    setEditingMode (val) {
      this.editing = val
      this.layout.backupTiles()
    },
    confirmEdit () {
      this.editing = false
      this.confirmProcess = true
      this.layoutUpdated()
    },
    cancelEdit () {
      this.layout.restoreTiles()
      this.editing = false
    }
  }
}
</script>

<style lang="less">
.project-dashboard {
  .move-information-container {
    height: 54px;
    background: #E2F7FF;
    border-top: 1px solid #00CCF9;
    border-bottom: 1px solid #00CCF9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #00CCF9;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: -0.02em;
    position: absolute;
    left: 0;
    right: 0;
    z-index: 1000;

    .fp4 {
      color: #00CCF9;
      font-size: 16px;
      margin-right: 5px;
    }
  }
  .project-layout-container {
    padding: 32px 0;

    &.editing {
      padding-top: 86px;
    }

    .services-layout {
      .service-item {
        .service-item-content {
          background: #FFFFFF;
          border: 1px solid #CBD3DB;
          border-radius: 8px;
          height: 100%;
          width: 100%;
          position: absolute;

          &.category {
            border-radius: 0px 8px 8px 8px;
          }

          .category-content {
            .category-name-container {
              position: absolute;
              top: -15px;
              left: -1px;
              border-radius: 5px 5px 0px 0px;
              padding: 0.5px 8px;
              font-weight: 600;
              font-size: 10px;
              line-height: 13px;
              letter-spacing: 0.05em;
              color: #FFFFFF;
              text-transform: uppercase;
              display: flex;
              align-items: center;

              .fp4 {
                font-size: 14px;
                margin-left: 8px;
                color: #fff;
                cursor: pointer;

                &.fp4-burger {
                  cursor: move;
                }
              }
            }
          }
        }

        .service-item-empty-spot {
          border: 1px dashed #CBD3DB;
          border-radius: 8px;
          height: 100%;
        }
      }
      .service-item-placeholder {
        background: #85E9FF !important;
        border-radius: 8px;
      }
    }
  }

  .footer {
    margin: 0 auto;
    .floating-container {
      position: fixed;
      bottom: 16px;
      box-shadow: 0px 0px 25px rgba(52, 65, 78, 0.2);
      border-radius: 8px;
      height: 50px;
      background-color: @white;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;
      z-index: 1001;

      .cancel {
        margin-left: 10px;
      }
    }
  }
}
</style>
