<template lang="pug">
.main-table.dpe
  fpui-table(
    :columns='columns'
    :actions='actions'
    :data='items'
    :loading="loading"
    row-key="_id"
    sort-on="created_at"
    :height="fromLarge ? '290px' : '115px'"
    :pagination="{ disabled: true }"
  )
</template>

<script>
import _orderBy from 'lodash/orderBy'
import moment from 'moment'

export default {
  props: {
    fromLarge: { type: Boolean, default: false }
  },
  data () {
    return {
      items: [],
      loading: false,
      itemLimit: this.fromLarge ? 9 : 3
    }
  },
  computed: {
    actions () {
      return []
    },
    columns () {
      return [
        {
          label: () => {
            const href = `dpe/#/${this.$route.params.projectId}/jobs`
            return `<a class='cell-link' href="${href}">${this.$t('project.home.dpe.last_jobs_executed')}</a>`
          },
          name: 'name',
          target: row => {
            let jobStatus
            switch (row.status.trim()) {
              // Possible return : SUCCESS, FAILED, STOPPED, TIMEOUT, PENDING, PROCESSING, PROVISIONING, QUEUED, SUBMITTED, BUILDING
              case 'SUCCESS':
                jobStatus = "<i class='fp4 fp4-circle-check'></i>"
                break
              case 'PENDING':
              case 'PROCESSING':
              case 'PROVISIONING':
              case 'QUEUED':
              case 'SUBMITTED':
              case 'BUILDING':
                jobStatus = `<img class='loading' src='${require('@/cc/assets/img/ic_spinner.png')}' />`
                break
              case 'STOPPED':
                jobStatus = "<i class='fp4 fp4-stop-circle'></i>"
                break
              case 'FAILED':
              case 'TIMEOUT':
                jobStatus = "<i class='fp4 fp4-circle-xmark failed'></i>"
                break
              default:
                jobStatus = ''
                break
            }

            const href = `dpe/#/${this.$route.params.projectId}/job/${row._id}`

            return `
            <div class="status-name-container">
              ${jobStatus}<a class='cell-link' href="${href}">${row.display_name}</a>
            </div>
            `
          },
          paddingLeft: 24,
          grow: 1.2
        },
        {
          label: this.$t('project.home.dpe.date'),
          name: 'created_at',
          reverse: true,
          target: row => {
            if (!row.lastExecution) return '-'
            return row.lastExecution
          },
          tooltip: (row) => {
            if (!row.executionDate) return '-'
            return moment(row.executionDate).format('DD/MM/YYYY HH:mm')
          },
          grow: 0.6
        }
      ]
    },
    projectId () {
      return this.$route.params?.projectId
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    serviceReady () {
      return !Object.keys(this.project?.display_settings?.services_wizard || {}).includes('dpe')
    },
    canDpeJobRead () {
      return this.$store.getters.ACL('canDpeJobRead')
    }
  },
  watch: {
    serviceReady: {
      handler (val) {
        if (val) this.load()
      }
    },
    '$store.getters.PROJECT_CARD_INFO_LOADING': {
      handler (val) {
        if (!val) this.load()
      }
    }
  },
  async mounted () {
    if (!this.$store.getters.PROJECT_CARD_INFO_LOADING) await this.load()
  },
  methods: {
    async load () {
      if (this.canDpeJobRead && this.serviceReady) {
        this.loading = true

        try {
          const jobs = await this.$api.DPE.jobs.list()
          this.items = _orderBy(jobs, ['created_at'], ['desc']).slice(0, this.itemLimit)
        } catch (err) {
          // Do nothing
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="less">
.main-table.dpe {
  .loading {
    margin-right: 5px;
    animation: rotating 1s infinite;
    -webkit-animation: rotating 1s infinite;
    width: 16px;
    vertical-align: text-bottom;
  }
  .fp4-circle-check {
    margin-right: 5px;
    color: #9EDF10;
    font-size: 16px;
    vertical-align: text-bottom;
  }
  .fp4-circle-xmark.failed {
    position: sticky;
    margin-right: 3px;
    color: #F62172 !important;
    font-size:18px;
    vertical-align: text-bottom;
  }
  .fp4-stop-circle {
    margin-left: 2px;
    margin-right: 5px;
    color: #F62172;
    font-size:14px;
    vertical-align: text-bottom;
  }
}
</style>
