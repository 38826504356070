<template lang="pug">
.kpi.iam(
  v-tooltip="$t('project.home.iam.kpi_tooltip_right')"
)
  fp-loader(v-if="loading" type="chart")
  .value(
    v-if="!loading"
  ) {{ value }}
  .text(
    v-if="!loading"
  ) {{ $t('project.home.iam.logins', [value], isNaN(value) ? 0 : value) }}
</template>

<script>
import moment from 'moment'

export default {
  computed: {
    value () {
      // Value are refreshed in IamMainTable when resize card
      if (!this.canIamMetricRead) return '-'

      let usersSessions = Object.values(this.$store.getters.PROJECT_HOME_USERS_SESSIONS || {})
      usersSessions = usersSessions?.filter(us => moment(us.lastConnected * 1000).isSame(moment(), 'day'))
      return usersSessions?.length ?? '-'
    },
    loading () {
      return this.$store.getters.PROJECT_HOME_USERS_SESSIONS_LOADING
    },
    canIamMetricRead () {
      return this.$store.getters.ACL('canIamMetricRead')
    }
  }
}
</script>

<style lang="less">

</style>
