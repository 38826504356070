<template lang="pug">
.main-table.mlm
  fpui-table(
    :columns='columns'
    :actions='actions'
    :data='items'
    :loading="loading"
    sort-on="last_activity"
    :height="fromLarge ? '143px' : '115px'"
    :pagination="{ disabled: true }"
  )
</template>

<script>
import moment from 'moment'
import _orderBy from 'lodash/orderBy'

export default {
  props: {
    fromLarge: { type: Boolean, default: false }
  },
  data () {
    return {
      itemLimit: this.fromLarge ? 4 : 3
    }
  },
  computed: {
    items () {
      return _orderBy(this.$store.getters.PROJECT_HOME_NOTEBOOKS || [], ['server.last_activity'], ['desc']).slice(0, this.itemLimit)
    },
    loading () {
      return this.$store.getters.PROJECT_CARD_INFO_LOADING || this.$store.getters.PROJECT_HOME_NOTEBOOKS_LOADING
    },
    actions () {
      return []
    },
    columns () {
      return [
        {
          label: () => {
            const href = `ml/#/${this.$route.params.projectId}/notebooks`
            return `<a class='cell-link' href="${href}">${this.$t('project.home.ml.recently_used_notebook')}</a>`
          },
          name: 'name',
          target: row => {
            const href = `ml/#/${this.$route.params.projectId}/notebook/${row._id}`
            return `<a class='cell-link' href="${href}">${row.display_name}</a>`
          },
          paddingLeft: 24,
          grow: 1.2
        },
        {
          label: this.$t('project.home.ml.last_used'),
          name: 'last_activity',
          reverse: true,
          target: (row) => {
            if (!row.server?.last_activity) return '-'
            return moment(row.server?.last_activity).utc(true).fromNow()
          },
          tooltip: (row) => {
            if (!row.server?.last_activity) return '-'
            return moment(row.server?.last_activity).utc(true).format('DD/MM/YYYY HH:mm')
          },
          grow: 0.8
        }
      ]
    },
    canMlNotebookRead () {
      return this.$store.getters.ACL('canMlNotebookRead')
    }
  },
  async mounted () {
    if (!this.loading && this.canMlNotebookRead) {
      try {
        this.$store.dispatch('LOAD_NOTEBOOKS')
      } catch (err) {}
    }
  }
}
</script>

<style lang="less">
</style>
