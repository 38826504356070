<template lang="pug">
  .authentication
    div.background
      img(:src="background")
    .content(:style="{ left: '45%' }")
      .inner-content
        .no-new-account
          .logo
            img(:src="require('@/shared/assets/img/ovhcloud_logo_blue.png')", width="101")
          .oops
            img(:src="require('@/core/assets/img/oops.png')", width="420")
          h2 {{$t('authentication.no_new_account.title')}}
          p {{$t('authentication.no_new_account.message')}}
          .read-more
            p {{$t('authentication.no_new_account.read_more')}}
            a(href='https://corporate.ovhcloud.com/fr/newsroom/news/acquisition-forepaas/' target="_blank") {{ $t('authentication.no_new_account.read_more.here') }}
          fpui-button(
            auto-width
            color="blue-flash"
            noshadow
            noborder
            reverse
            icon-left
            icon="fp4 fp4-arrow-left"
            @click="returnToForepaas"
          ) {{$t('authentication.no_new_account.return')}}

</template>

<script>
import defaultBackgroundImage from '@/shared/assets/img/sign_up_background.png'
import Config from '@/shared/Config'

export default {
  data () {
    return {
      config: {}
    }
  },
  computed: {
    background () {
      return defaultBackgroundImage
    }
  },
  async mounted () {
    this.$store.commit('SET_OPEN_SIDE_BAR', false)
    this.config = await Config()
  },
  methods: {
    returnToForepaas () {
      document.location.href = this.config.FPUI
    }
  }
}
</script>

<style lang="less">
  .authentication {
    position:fixed;
    display: flex;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background-color: white;
    .content {
      background:white;
      overflow: auto;
      overflow: overlay;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      padding:40px 70px;
      h1 {
        margin: 10px 0 5px;
        font-size: 40px;
        line-height:60px;
        font-weight: 700;
        color: #3e4550;
      }
      p {
        margin-top: 20px;
        font-size: 14px;
        line-height: 28px;
        color: #3e4550;
        max-width: 550px;
      }
      .inner-content {
        margin:50px 0;
        max-width:600px;

        .no-new-account {
          .oops {
            margin-bottom: 40px;
          }
          h2 {
            font-family: Source Sans Pro;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: 40px;
            letter-spacing: 0px;
            text-align: left;
            margin-bottom: 10px;
          }
          p {
            margin-top: 10px!important;
            font-family: Source Sans Pro;
            font-size: 16px!important;
            font-style: normal;
            font-weight: 400;
            line-height: 20px!important;
            letter-spacing: 0px;
            text-align: left;
          }
          .read-more {
            display: flex;
            align-items: center;
            margin-top: 10px!important;

            p {
              margin: 0 !important;
            }

            a {
              margin-left: 3px;
              text-decoration: underline;
              font-family: Source Sans Pro;
              font-size: 16px!important;
              font-style: normal;
              font-weight: 400;
              line-height: 20px!important;
              letter-spacing: 0px;
              color: #3E4550;
            }
          }
          .button-container {
            margin-top: 40px;
          }
        }
      }
    }
    .background {
      height: 100%;
      width: 30%;
      position: absolute;
      left: 0;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }
    .background-mask {
      height: 100%;
      width: 55%;
      position: absolute;
      left:0 ;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: -1px;
        bottom: 0;
        width: 190px;
        background-image: url('data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB3aWR0aD0iMTM5cHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9IjAgMCAxMzkgODAwIiB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiPgogICAgPCEtLSBHZW5lcmF0b3I6IFNrZXRjaCA1NS4xICg3ODEzNikgLSBodHRwczovL3NrZXRjaGFwcC5jb20gLS0+CiAgICA8dGl0bGU+bG9naW5fc2hhcGU8L3RpdGxlPgogICAgPGRlc2M+Q3JlYXRlZCB3aXRoIFNrZXRjaC48L2Rlc2M+CiAgICA8ZyBpZD0iREFTSEJPQVJEX0NMSUVOVCIgc3Ryb2tlPSJub25lIiBzdHJva2Utd2lkdGg9IjEiIGZpbGw9Im5vbmUiIGZpbGwtcnVsZT0iZXZlbm9kZCI+CiAgICAgICAgPGcgaWQ9IjA0XzBfZGFzaGJvYXJkX1NLX2xvZ2luLUNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKC02MzIuMDAwMDAwLCAwLjAwMDAwMCkiIGZpbGw9IiNGRkZGRkYiPgogICAgICAgICAgICA8ZyBpZD0ibG9naW5fc2hhcGUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDYzMi4wMDAwMDAsIDAuMDAwMDAwKSI+CiAgICAgICAgICAgICAgICA8cG9seWdvbiBpZD0iUmVjdGFuZ2xlLTkiIG9wYWNpdHk9IjAuOTQxNTQ1NzU5IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg2OS41MDAwMDAsIDQwMC4wMDAwMDApIHNjYWxlKC0xLCAxKSB0cmFuc2xhdGUoLTY5LjUwMDAwMCwgLTQwMC4wMDAwMDApICIgcG9pbnRzPSIyLjU4NDg3Njc2ZS0xMyAwIDEzOSA4MDAgMCA4MDAiPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJSZWN0YW5nbGUtOSIgb3BhY2l0eT0iMC40MzEwMzYwODYiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDY5LjUwMDAwMCwgNDAwLjAwMDAwMCkgc2NhbGUoLTEsIC0xKSB0cmFuc2xhdGUoLTY5LjUwMDAwMCwgLTQwMC4wMDAwMDApICIgcG9pbnRzPSI1LjIxNzk3ODgzZS0xMyAwIDEzOSA4MDAgMCA4MDAiPjwvcG9seWdvbj4KICAgICAgICAgICAgICAgIDxwb2x5Z29uIGlkPSJQYXRoLUNvcHkiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDEwNC4yNTAwMDAsIDQwMC4wMDAwMDApIHNjYWxlKC0xLCAxKSB0cmFuc2xhdGUoLTEwNC4yNTAwMDAsIC00MDAuMDAwMDAwKSAiIHBvaW50cz0iMTM5IDQwMCA2OS41IDgwMCA2OS41IDAiPjwvcG9seWdvbj4KICAgICAgICAgICAgPC9nPgogICAgICAgIDwvZz4KICAgIDwvZz4KPC9zdmc+');
        background-repeat: no-repeat;
        background-size: auto 100%;
        background-position: right;
      }
    }
    .logo {
      max-width:80px;
      max-height:80px;
    }
  }
  @media (max-width: 1279px) {
    .authentication {
      .background {
        width:35%;
      }
      .content {
        left:35%;
      }
    }
  }
  @media (max-width: 600px) {
    .authentication {
      .background {
        display:none;
      }
      .content {
        left:0%;
      }
    }
  }
</style>
