export default (route) => {
  return {
    Overview: {
      url: `organization/${route.params.organizationId}`,
      icon: 'fp4 fp4-overview'
    },
    Users: {
      regexp: /^\/organization\/\w*\/member.*/,
      url: `organization/${route.params.organizationId}/members`,
      icon: 'fp4 fp4-users'
    },
    Dataplants: {
      url: `organization/${route.params.organizationId}/projects`,
      icon: 'fp4 fp4-dataplants'
    },
    Plan: {
      url: `organization/${route.params.organizationId}/plan`,
      icon: 'fp4 fp4-organization-plan'
    },
    Settings: {
      url: `organization/${route.params.organizationId}/settings`,
      icon: 'fp4 fp4-resources'
    }
  }
}
