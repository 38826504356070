<template lang="pug">
  .settings-informations.fp-container
    fp-loader(v-if="loading")
    .content
      .fp-box
        .box-title {{ $t('organization.settings.informations.organization_informations') }}
        .box-line
          fpui-input-text(
            :label="$t('organization.settings.informations.oganization_name')"
            :disabled="!isAdmin"
            :value="organization.name"
            @input="(value) => organization.update('name', value, false)"
          )
        br
        .box-line(v-if="account")
          fpui-input-text(
            :label="$t('organization.settings.informations.account_number')"
            disabled
            :value="account._id"
          )
          br
        .box-line(v-if="account")
          fpui-input-text(
            :label="$t('organization.settings.informations.account_owner')"
            disabled
            :value="`${account.first_name} ${account.last_name}`"
          )
          br
        .box-line
          fpui-input-textarea(
            :label="$t('organization.settings.informations.organization_description')"
            :rows=4
            :disabled="!isAdmin"
            :value="organization.description"
            @input="(value) => organization.update('description', value, false)"
          )
        br
      .fp-box.with-padding
        .box-title {{ $t('organization.settings.informations.authentification') }}
        .box-line
          fpui-input-toggle(
            :label="$t('organization.settings.informations.force_authentification')"
            :value="forceMfa"
            :disabled="!isAdmin"
            @input="updateForceMfa"
          )
        .box-line.join-organization(v-if="organization.subscription && accountDomain")
          fpui-input-toggle(
            v-if="!disabledCanJoinOrga"
            :label="$t('organization.settings.informations.authorize_join_organization', [accountDomain])"
            :value="sameDomainCanJoin"
            :disabled="!isAdmin"
            @input="updateSameDomainCanJoin"
          )
            v-popover(trigger='hover' placement='top')
              i.fp4.fp4-circle-info
              template(slot='popover')
                .tooltip-container
                  .tooltip-body {{ $t('organization.settings.informations.authorize_join_organization.tooltip_content') }}
                  .tooltip-link(@click="openLink()") {{ $t('organization.settings.informations.authorize_join_organization.tooltip_link') }}
          .cant-disable-join(
            v-if="disabledCanJoinOrga"
            @click="cantDisableJoin"
          )
            fpui-input-toggle(
              :label="$t('organization.settings.informations.authorize_join_organization', [accountDomain])"
              :value="sameDomainCanJoin"
              :disabled="true"
              @input="updateSameDomainCanJoin"
            )
              v-popover(trigger='hover' placement='top')
                i.fp4.fp4-circle-info
                template(slot='popover')
                  .tooltip-container
                    .tooltip-body {{ $t('organization.settings.informations.authorize_join_organization.tooltip_content') }}
                    .tooltip-link(@click="openLink()") {{ $t('organization.settings.informations.authorize_join_organization.tooltip_link') }}
      .fp-box.with-padding.subscription(v-if="canCancelSubscription")
        .box-title {{ $t('organization.settings.informations.organization_subscription') }}
        fpui-button.cancel-subscription(
          color='red'
          @click="cancelSubscription"
          :disabled="!isAdmin"
          auto-width
        ) {{ $t(isInTrial ? 'organization.settings.informations.cancel_subscription.modal.button.cancel_trial' : 'organization.settings.informations.cancel_subscription.modal.button.cancel') }}

      .actions-buttons(v-if="isAdmin")
        fpui-button(
          color='green'
          @click="save",
          auto-width
          :disabled="!organization.hasChanges"
        ) {{ $t('button.save_changes') }}

</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
import ConfirmModal from '@/shared/components/modals/Confirm'
import CannotDisableJoinModal from '@/core/components/Organization/Settings/CannotDisableJoinModal.vue'

export default {
  component: {
    CannotDisableJoinModal
  },
  props: {
    organization: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      accountId: ''
    }
  },
  computed: {
    canCancelSubscription () {
      if (!this.organization?.subscription) return false
      if (this.organization?.subscription?.id === 'OFFLINE_SUBSCRIPTION') return false
      if (this.organization.subscription?.status === 'in_trial' && this.organization.subscription?.scheduled_status === 'cancelled') return false

      return ['future', 'in_trial', 'active', 'paused'].includes(this.organization?.subscription?.status)
    },
    isAdmin () {
      return this.organization.users.find(usr => usr.email === this.session.email).role === 'admin'
    },
    session () {
      return this.$store.getters.SESSION
    },
    account () {
      if (!this.organization.customerAccountId) return null
      return this.$store.getters.ACCOUNT_BY_ID(this.organization.customerAccountId)
    },
    accountDomain () {
      return this.organization.join_request_domains?.length ? this.organization.join_request_domains[0] : null
    },
    forceMfa () {
      const forceMfa = this.organization.force_mfa.find(organization => organization.type === 'organization')
      if (!forceMfa) return false
      return forceMfa.active
    },
    isInTrial () {
      if (!this.organization?.subscription) return false
      return this.organization?.subscription?.status === 'in_trial'
    },
    sameDomainCanJoin () {
      return this.organization?.join_request_active
    },
    disabledCanJoinOrga () {
      return this.organization?.join_request_active && (this.organization?.subscription?.plan === 'standard')
    }
  },
  async mounted () {
    this.loading = true
    await this.organization.loadSubscription()
    this.loading = false
  },
  methods: {
    async cancelSubscription () {
      if (await ConfirmModal(this, {
        title: this.$t(this.isInTrial ? 'organization.settings.informations.cancel_subscription.modal_title_trial' : 'organization.settings.informations.cancel_subscription.modal_title'),
        content: this.$t(this.isInTrial ? 'organization.settings.informations.cancel_subscription.modal_content_trial' : 'organization.settings.informations.cancel_subscription.modal_content'),
        contentHtml: true,
        yes: { color: 'red', text: this.$t('proceed') },
        cancel: { text: this.$t('go_back') }
      })) {
        this.loading = true
        try {
          await this.organization.cancelSubscription()
        } catch (err) {
          this.$fpuiMessageBlock.error(err)
        }
        this.loading = false
      }
    },
    updateForceMfa (value) {
      let newForceMfa = []
      if (this.organization.force_mfa.length === 0) newForceMfa.push({ type: 'organization', active: value })
      else {
        newForceMfa = this.organization.force_mfa.map(mfa => {
          const newValue = _cloneDeep(mfa)
          if (newValue.type === 'organization') newValue.active = value
          return newValue
        })
      }
      this.organization.update('force_mfa', newForceMfa, false)
    },
    async save () {
      this.organization.save()
    },
    updateSameDomainCanJoin (value) {
      this.organization.update('join_request_active', value, false)
    },
    cantDisableJoin () {
      this.$modal.show(CannotDisableJoinModal, {
        organizationId: this.organization._id
      }, {
        height: '323px',
        width: '472px'
      })
    },
    openLink () {
      window.open('https://docs.forepaas.io/#/en/product/account-setup/organization-invite-user?id=add-users-to-an-organization', '_blank')
    }
  }
}


</script>

<style lang="less">
  .settings-informations {
    width: 516px;
    .fp-box {
      margin-bottom: 16px;
      &.with-padding {
        padding-bottom: 21px !important;
      }
      &.subscription {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 13px !important;
        .box-title {
          margin: 0;
        }
      }

      .join-organization {
        margin-top: 20px;

        .fpui-input-toggle {
          position: relative;
          .fpui-input-toggle-content {
            align-items: center;
            span {
              max-width: 370px;
            }
            .vue-js-switch {
              height: 22px;
            }
          }
        }
      }
    }
    .actions-buttons {
      display: flex;
      justify-content: center;
    }
  }

  .v-popover {
    position: absolute;
    top: 6px;
    right: 90px;
    i {
      font-size: 16px;
      color: #CBD3DB;
      &:hover {
        color: #00CCF9;
      }
    }
  }
</style>
