<template lang="pug">
    .image-preview(v-if="attachment.content_type === 'image/png'")
      a(:href="attachment.url", target="__blank")
        .image-background
        img(:src="attachment.url")
        .image-name {{attachment.name}}
    .file-preview(v-else)
        a(:href="attachment.url", target="__blank")
          .file-background
          img(:src="filePreviewIcon")
          .file-name {{attachment.name}}
</template>

<script>
export default {
  props: {
    attachment: {
      type: Object,
      default () { return {} }
    }
  },
  computed: {
    filePreviewIcon () { return require('./file-preview.png') }
  }
}
</script>

<style lang="less">
.image-preview {
  position: relative;
  text-align: center;
  color: white;
  .image-background {
    background: linear-gradient(black, transparent);
    position: absolute;
    height: 50px;
    width: 100%
  }
  img {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    height: 100px;
    &:hover {
      box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }
  }
   a {
    color: white;
  }
  .image-name {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.file-preview {
  position: relative;
  text-align: center;
  color: white;
  .file-background {
    background: linear-gradient(black, transparent);
    position: absolute;
    height: 50px;
    width: 100%
  }
  img {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 5px;
    height: 100px;
    &:hover {
      box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
    }
  }
  a {
    color: white;
  }
  .file-name {
    position: absolute;
    top: 4px;
    left: 4px;
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
</style>
