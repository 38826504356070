<template lang="pug">
  .fp-app.no-aside(:class="$route.meta.theme||'v4'")
    fp-splash-error(v-if="globalError" :error="$store.getters.GLOBAL_ERROR")
    fp-loader(v-if="loading && !globalError")
    fp-header(v-if="$store.getters.SESSION && !fullscreen && !globalError")
    fp-sidebar(
      v-if="!!aside && $store.getters.SESSION && !fullscreen && $store.getters.READY('core_organizations') && !globalError"
      overlap
      :style-sidebar="{ top: '60px' }"
    )
    fp-aside(
      :key='organizationId'
      :aside='aside'
      v-if="aside && $store.getters.SESSION && !fullscreen && !globalError"
    )
    main(
      v-if="($store.getters.SESSION || noNewAccount ) && !globalError"
      :style="{ left, top, overflow}",
      :class="{ bordered: $route.meta.bordered }"
    )
      fp-sidebar(
        v-if="!aside && !TeammatesPage && $store.getters.SESSION && !fullscreen && $store.getters.READY('core_organizations')"
        :overlap="displayFromDataplant"
        :style-sidebar="oldServicesStyle"
      )
      router-view
</template>

<script>
import FpHeader from './components/FpHeader.vue'
import _get from 'lodash/get'
import Vue from 'vue'
import Config from '@/shared/Config'
export default {
  components: {
    FpHeader
  },
  data () {
    return {
      loading: false,
      noNewAccount: false,
      config: null,
      windowWidth: 0
    }
  },
  computed: {
    organizationId () {
      return this.$route?.params?.organizationId
    },
    fullscreen () {
      return this.$route.meta.fullscreen || false
    },
    aside () {
      if (!this.$route.meta.aside) return null
      return this.$route.meta.aside(this.$route)
    },
    left () {
      return this.aside ? '124px' : (this.$route.meta.bordered ? '20px' : 0)
    },
    top () {
      return !this.fullscreen ? '60px' : 0
    },
    overflow () {
      return this.displayFromSupport ? 'hidden' : ''
    },
    session () {
      return this.$store.getters.SESSION
    },
    routeDetails () {
      return this.$route.path.split('/')
    },
    displayFromDataplant () {
      return ['features', 'infra', 'api', 'app', 'qb'].includes(this.routeDetails[1])
    },
    displayFromSupport () {
      return ['support'].includes(this.routeDetails[1])
    },
    displayFromOrganization () {
      return ['organization'].includes(this.routeDetails[1])
    },
    displayFromServices () {
      return this.$route.name === 'HQ Home Services'
    },
    globalError () {
      return this.displayFromOrganization && this.$store.getters.GLOBAL_ERROR
    },
    TeammatesPage () {
      return this.$route.name === 'HQ Home Teammates'
    },
    oldServicesStyle () {
      if (['features', 'api', 'app', 'qb'].includes(this.routeDetails[1])) {
        return { top: '60px' }
      }
      return null
    }
  },
  async created () {
    let lang = _get(this.$route, 'query.lang') || window.localStorage.getItem('hq_lang') || 'en'
    try {
      lang = JSON.parse(lang)
    // eslint-disable-next-line no-empty
    } catch (err) {}
    Vue.i18n.set(lang)
    window.localStorage.setItem('hq_lang', lang)
  },
  async mounted () {
    this.config = await Config()

    // Redirect if no new account
    if (this.$route.path.includes('no_new_account')) {
      this.noNewAccount = true
      this.$router.push('/no_new_account')
      return
    }

    // We check the route params to see if a user wants to join an organization
    // If yes, he join it and then we check his session (redirect to login page or home)
    let joinOrga = false
    const { id, organizationId, tokenJoin, email, signup } = this.$route.query
    const orgaId = id || organizationId
    if (orgaId && tokenJoin && email) {
      joinOrga = true
      try {
        await this.$api.ORGANIZATION.organizations.userJoins(orgaId, tokenJoin)
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
        console.error(err)
      }
    }

    // If signup, redirect to signup page
    if (signup) {
      const urlSignup = `${this.config.KING}/oauth/authorize?signup=true&email=${encodeURIComponent(email)}&client_id=hq&response_type=token&redirect_uri=${window.location.origin}`
      await Vue.api.KING.logout()
      document.location.href = urlSignup
      return
    }

    this.loading = true
    await this.$store.dispatch('CHECK_CMP_SESSION')
    this.loading = false

    // If mail in link to join orga is not the email in session, we logout
    if (joinOrga && email !== this.session?.email) this.$store.dispatch('LOGOUT_CMP')
    if (joinOrga) this.$store.commit('SET_ACTIVE_ORGANIZATION', orgaId)

    this.windowWidth = window.innerWidth
    if (this.windowWidth >= 1800 && !this.aside) {
      this.$store.commit('SET_OPEN_SIDE_BAR', true)
    }
  }
}
</script>

<style lang="less">
  main {
    position: absolute;
    right:0;
    bottom: 0;
    display: flex;
    &.bordered {
      right: 20px;
      box-shadow: 0 3px 13px 0 rgb(151 167 183 / 30%);
      background:white;
    }
    .router-wrapper {
      height: 100%;
      width: 100%;
      overflow: auto;
    }
  }
</style>
