<template lang="pug">
.main-table.iam
  fpui-table(
    :columns='columns'
    :actions='actions'
    :data='items'
    :loading="loading"
    sort-on="last_connected_at"
    :height="fromLarge ? '290px' : '115px'"
    :pagination="{ disabled: true }"
  )
</template>

<script>
import moment from 'moment'
import _orderBy from 'lodash/orderBy'

export default {
  props: {
    fromLarge: { type: Boolean, default: false }
  },
  data () {
    return {
      itemLimit: this.fromLarge ? 9 : 3
    }
  },
  computed: {
    items () {
      return _orderBy(Object.values(this.$store.getters.PROJECT_HOME_USERS_SESSIONS || {}), ['end'], ['desc']).slice(0, this.itemLimit)
    },
    loading () {
      return this.$store.getters.PROJECT_CARD_INFO_LOADING || this.$store.getters.PROJECT_HOME_USERS_SESSIONS_LOADING
    },
    actions () {
      return []
    },
    columns () {
      return [
        {
          label: () => {
            const href = `iam/#/${this.$route.params.projectId}/users`
            return `<a class='cell-link' href="${href}">${this.$t('project.home.iam.name')}</a>`
          },
          name: 'name',
          target: row => {
            const href = `iam/#/${this.$route.params.projectId}/user/${row._id}`
            return `<a class='cell-link' href="${href}">${row.user}</a>`
          },
          paddingLeft: 24,
          grow: 1.2
        },
        {
          label: this.$t('project.home.iam.last_connected_at'),
          name: 'last_connected_at',
          reverse: true,
          target: (row) => {
            if (!row.end) return '-'
            return moment(row.end * 1000).fromNow()
          },
          tooltip: (row) => {
            if (!row.end) return '-'
            return moment(row.end * 1000).format('DD/MM/YYYY HH:mm')
          },
          grow: 0.8
        }
      ]
    },
    canIamMetricRead () {
      return this.$store.getters.ACL('canIamMetricRead')
    },
    canIamUserRead () {
      return this.$store.getters.ACL('canIamUserRead')
    }
  },
  async mounted () {
    if (!this.loading && this.canIamMetricRead && this.canIamUserRead) {
      try {
        this.$store.dispatch('LOAD_USERS_SESSIONS')
      } catch (err) {}
    }
  }
}
</script>

<style lang="less">
</style>
