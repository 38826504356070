<template lang="pug">
  .dataplant-block.new#cy-create-dataplant(
    :class="{ disabled: !$acl(organizationId).can('core', 'dataplants', 'create') }"
    @click="createDataplant()"
  )
    .new-dataplant-block-content
      .add-icon
        i.fp4.fp4-circle-plus
      label {{ $t('home.dataplant.label.new_dataplant')}}
</template>

<script>
import DataplantCreationFullscreen from '@/shared/components/project/DataplantCreationFullscreen/DataplantCreationFullscreen.vue'
import CannotDoInTrialSubscription from '@/core/components/CannotDoInTrialSubscription.vue'

export default {
  components: {
    DataplantCreationFullscreen,
    CannotDoInTrialSubscription
  },
  props: {
    organizationId: { type: String, required: true }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization?.subscription || null
    },
    dataplants () {
      return this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId)
    },
    hasValidPayment () {
      return this.subscription?.payment_valid || !(this.subscription?.scheduled_status === 'cancelled' && this.subscription?.status === 'in_trial')
    }
  },
  methods: {
    async createDataplant () {
      let subscription = this.subscription
      if (!this.subscription) {
        subscription = await this.organization.loadSubscription()
      }
      if ((['active', 'non_renewing'].includes(subscription?.status)) || (subscription?.status === 'in_trial' && subscription?.payment_valid && subscription?.scheduled_status !== 'cancelled')) {
        if (!this.$acl(this.organizationId).can('core', 'dataplants', 'create')) return
        this.$analytics.track('Open new project funnel', { from: 'organization home', channel: 'new project' })
        this.$modal.show(DataplantCreationFullscreen, {
          organizationId: this.organizationId
        }, {
          height: '100%',
          width: '100%',
          adaptative: true,
          classes: []
        })
      } else {
        this.$modal.show(CannotDoInTrialSubscription, {
          organizationId: this.organizationId
        }, {
          height: '323px',
          width: '472px'
        })
      }
    }
  }
}
</script>

<style lang="less">
  .dataplant-block {
    &.new {
      cursor: pointer;
      z-index: 1;
      background: transparent;
      box-shadow: none;
      border: 1px dashed rgba(151, 167, 183, 0.32);
      .new-dataplant-block-content {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .add-icon {
          box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .26);
          width: 35px;
          height: 35px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          i {
            font-size: 35px;
            color: #00CCF9
          }
        }
        label {
          text-transform: uppercase;
          font-size: 13px;
          color: @text-color;
          font-weight: 600;
          letter-spacing: 1.5px;
          margin-top: 12px;
        }
      }
    }
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
</style>
