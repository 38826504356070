<template lang="pug">
  .organization-plan-billing
    .fp-row
      .fp-container
        .fp-column
          .fp-box
            .box-title {{ $t('organization.plan.billing.balance.title') }}
            .box-line
              p {{ $t('organization.plan.billing.balance.desc') }}
              .flex-line
                .invoiced-on
                  .label {{ $t('organization.plan.billing.invoiced_on') }}
                  .value(v-if="subscription.current_term_end") {{ subscription.current_term_end*1000 | date('utc','locale',$t('date.formatter.short')) }}
                  .value(v-else) -
                .estimated-amount
                  .label {{ $t('organization.plan.billing.estimated_amount') }}
                  .value(v-if="!isCustom") {{ estimatedAmount | price(subscription.currency_code, 2) }}
                  .value(v-else) -
              hr
              .flex-line
                .current-plan
                  .label {{ $t('organization.plan.billing.current_plan') }}
                  .value
                    img(
                      v-if="subscription && subscription.plan"
                      :src="require(`@/core/assets/img/plan/${subscription.plan}.png`)"
                    )
                    span {{subscription.plan || 'Custom' | Capitalize }}
                .subscription-id
                  .label {{ $t('organization.plan.billing.subscription_id') }}
                  .value {{ subscription.cb_subscription_id || '-' }}

        .fp-column
          .fp-box
            .box-title {{ $t('organization.plan.billing.ondemand.title') }}
            .box-line
              billing-consumption(:organization-id="organizationId")

    .fp-row(v-if="!isCustom")
      .fp-container
        .fp-column
          .fp-box
            .box-title {{ $t('organization.plan.billing.summary.title') }}
            .box-line
              billing-details(:organization-id="organizationId",ref="details")
</template>

<script>
import _sum from 'lodash/sum'
import BillingDetails from './BillingDetails.vue'
import BillingConsumption from './BillingConsumption.vue'
export default {
  components: {
    BillingDetails, BillingConsumption
  },
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  computed: {
    charges () {
      return this.organization?.consumptions?.charges || []
    },
    estimatedAmount () {
      const subscriptionMain = this.subscription?.plan_unit_price
      const addOns = this.subscription?.addons ? this.subscription?.addons.map(a => a.amount) : []
      const subscription = _sum([subscriptionMain].concat(addOns))
      const other = _sum(this.charges.map(c => c.addon_quantity * this.getChargeUnitPrice(c)))
      return subscription + other
    },
    isCustom () {
      return this.subscription?.plan === 'custom'
    },
    account () {
      if (!this.organization.customerAccountId) return null
      return this.$store.getters.ACCOUNT_BY_ID(this.organization.customerAccountId)
    },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization?.subscription
    }
  },
  methods: {
    getChargeUnitPrice (charge) {
      if (charge.addon_unit_price !== undefined) return charge.addon_unit_price
      return this.$store.getters.ADDON_BY_ID(charge.addon_id)?.price || 0
    }
  }
}
</script>

<style lang="less">
.organization-plan-billing {
  hr {
    margin: 5px 0;
  }
  p {
    font-weight: 400;
    color: #97A7B7;
    font-size: 12px;
  }
  .flex-line {
    display: flex;
    padding: 20px 0;
    > div {
      width: 50%;
    }
  }
  .value {
    font-size: 14px;
    font-weight: bold;
  }
  .current-plan {
    .value {
      img,span {
        display: inline-block;
        vertical-align: middle;
      }
      img {
        height: 15px;
        width: 15px;
        margin-right: 10px;
      }
    }
  }
}
</style>
