<template lang="pug">
  .payment-radio-group
    .payment-container(v-for="payment in payments")
      input(
        type="radio"
        :checked="payment.primary"
        @click="payment.primary ? '' : $emit('setPrimary', payment)"
        :value="payment.id"
      )
      img(v-if="payment.card.brand" :src="require(`@/core/assets/img/cards/${payment.card.brand}.png`)")
      img(v-if="payment.bank_account" src="@/core/assets/img/cards/sepa.png")
      .info(v-if="payment.card")
        .name {{payment.card.brand}} {{payment.card.masked_number}}
        .expire {{ `${$t('accounts.billing.card.expiration')}: ${payment.card.expiry_month}/${payment.card.expiry_year}`}}
      .info(v-if="payment.bank_account")
        .name .......{{ payment.bank_account.last4 }}
        .expire {{ payment.bank_account.name_on_account }}
      .info.primary(v-if="payment.primary")
        span {{ $t('accounts.billing.card.primary') }}
      .action(v-if="!payment.primary")
        i.fp4.fp4-trash-can(v-if="payment.card" @click="removePayment(payment)")
</template>
<script>
import ConfirmModal from '@/shared/components/modals/Confirm'

export default {
  props: {
    payments: { type: Array, required: true }
  },
  methods: {
    async removePayment (payment) {
      const confirmResult = await ConfirmModal(this, {
        contentHtml: true,
        content: `<span style="font-size:14px">${this.$t('treeview.remove.modal.head')} <span style="font-weight:600; text-transform: capitalize" > ${payment.card.brand}: ${payment.card.masked_number}</span>${this.$t('treeview.remove.modal.question_mark')}<br />${this.$t('treeview.remove.modal.tail')} </span><br>`,
        title: this.$t('accounts.billing.card.delete'),
        yes: {
          text: this.$t('fpui-modal-confirm.remove'),
          color: 'red'
        }
      })
      if (!confirmResult) return false
      this.$emit('removePayment', payment)
    }
  }
}
</script>
<style lang="less">
  .payment-radio-group {
    display: flex;
    flex-direction: column;
    .payment-container {
      display: flex;
      align-items: center;
      margin  : 10px 0 36px;
      img {
        height      : 40px;
        margin-right: 15px;
      }
      .info {
        flex-grow : 2;
        margin-top: 0px;
        .name {
          color         : @text-color;
          font-size     : 16px;
          line-height   : 20px;
          text-transform: capitalize;
        }
        .expire {
          color    : @grey;
          font-size: 14px;
        }
      }
      .primary {
        flex-grow : 1;
        margin-right   : 30px;
        border         : 1px solid @blue;
        height         : 20px;
        border-radius  : 3px;
        background     : rgba(0, 204, 249, 0.3);
        display        : flex;
        align-content  : center;
        justify-content: center;
        span {
          color      : @blue;
          font-weight: 600;
        }
      }
      .action {
        opacity   : 0;
        cursor: pointer;
        i {
          font-size: 20px;
          color: @grey;
        }
      }
      &:hover {
        .action {
          opacity: 1;
          &:hover {
            i {
              color: @red;
            }
          }
        }
      }
    }
  }
</style>
