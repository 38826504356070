<template lang="pug">
.second-table.gab
  fpui-table(
    :columns='columns'
    :actions='actions'
    :data='items'
    :loading="loading"
    sort-on="name"
    :height="fromLarge ? '143px' : '115px'"
    :empty="{ text: canGabKbRead ? $t('project.home.gab.no_kb') : $t('dashboard.access.tooltip') }"
    :pagination="{ disabled: true }"
  )
</template>

<script>
import CellStatus from '@/aab/components/Cells/CellStatus'

export default {
  props: {
    fromLarge: { type: Boolean, default: false }
  },
  computed: {
    items () {
      return this.$store.getters.PROJECT_HOME_KNOWLEDGE_BASES || []
    },
    loading () {
      return this.$store.getters.PROJECT_HOME_KNOWLEDGE_BASES_LOADING
    },
    actions () {
      return []
    },
    columns () {
      return [
        {
          label: this.$t('project.home.gab.kb'),
          name: 'name',
          target: row => {
            const href = `/aab/#/${this.$route.params.projectId}/kb/${row.id}`
            return `<a class='cell-link' href="${href}">${row.display_name}</a>`
          },
          grow: 2.5
        }, {
          label: this.$t('project.home.gab.assistant.status'),
          component: CellStatus,
          target: (row) => ({ value: row, fromDashboard: true })
        }
      ]
    },
    canGabKbRead () {
      return this.$store.getters.ACL('canGabKbRead')
    }
  }
}
</script>
