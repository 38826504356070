import DataCatalogLeftButton from './DataCatalogLeftButton.vue'
import DataCatalogRightButton from './DataCatalogRightButton.vue'
import DataCatalogMainTable from './DataCatalogMainTable.vue'
import DataCatalogSecondTable from './DataCatalogSecondTable.vue'
import DataCatalogLeftKpi from './DataCatalogLeftKpi.vue'
import DataCatalogRightKpi from './DataCatalogRightKpi.vue'

export default {
  DataCatalogLeftButton,
  DataCatalogRightButton,
  DataCatalogMainTable,
  DataCatalogSecondTable,
  DataCatalogLeftKpi,
  DataCatalogRightKpi
}

export {
  DataCatalogLeftButton,
  DataCatalogRightButton,
  DataCatalogMainTable,
  DataCatalogSecondTable,
  DataCatalogLeftKpi,
  DataCatalogRightKpi
}
