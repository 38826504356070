<template lang="pug">
  .domain-modal
    .fpui-modal
      fpui-modal-header(
        :title="$t('organization.settings.infrastructure.domains.new_domain')"
        @close='$emit("close")'
        closeTopButton
      )
      fpui-modal-body.no-padding
        .domain-name
          .domain-name-label
            label {{ $t('organization.settings.infrastructure.domain_name') }}
            //- fpui-helper(:title="'Domain name helper'" :text="'Domain name helper'")
          fpui-input-text(
              :placeholder="$t('organization.settings.infrastructure.domain.placeholder')"
              v-model="domainName"
          )
        //- br
        //- .domain-icon
        //-   .domain-icon-label
        //-     label {{ $t('organization.settings.infrastructure.domain_icon') }}
        //-     fpui-helper(:title="'Domain icon helper'" :text="'Domain icon helper'")

      fpui-modal-footer(:buttons="buttons()")
</template>

<script>

export default {
  props: {
    onConfirm: { type: Function, default: () => null }
  },
  data () {
    return {
      domainName: ''
    }
  },
  methods: {
    buttons () {
      return [{
        label: this.$t('confirm'),
        color: 'green',
        disabled: !this.domainName,
        handler: () => {
          this.onConfirm(this.domainName)
          this.$emit('close')
        }
      }]
    }
  }
}
</script>

<style lang="less">
  .domain-modal {
    .fpui-modal {
      .domain-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .domain-name-label {
          font-size: 14px;
        }
        .fpui-input-text {
          width: 251px;
        }
      }
    }
  }
</style>
