<template lang="pug">
  .certificate-modal
    .fpui-modal
      fpui-modal-header(
        :title="$t('organization.settings.infrastructure.certificate.new_certificate')"
        @close='$emit("close")'
        closeTopButton
      )
      fpui-modal-body.no-padding
        .certificate-name
          fpui-input-text(
            :label="$t('organization.settings.infrastructure.certificate.certificate_name')"
            :placeholder="$t('organization.settings.infrastructure.certificate.certificate_name')"
            :value="certificate.name"
            @input="value => certificate.update('name', value, false)"
          )
          br
        .certificate-domain-name
          //- .title {{ $t('organization.settings.infrastructure.certificate.domain_name') }}
          //- fpui-helper(:text="$t('edit_auth_provider.force_reauth.tooltip')")

          fpui-input-text(
            :label="$t('organization.settings.infrastructure.certificate.domain_name')"
            :disabled="isEditing !== undefined"
            :placeholder="$t('organization.settings.infrastructure.certificate.domain_name')"
            :value="certificate.domain"
            @input="value => certificate.update('domain', value, false)"
          )
          br
        .certificate-cert(v-if='!isEditing')
          fpui-input-textarea(
            :label="$t('organization.settings.infrastructure.certificate.server_certificate')"
            :rows=4
            :placeholder="$t('organization.settings.infrastructure.certificate.server_certificate')"
            :value="certificate.cert"
            @input="value => certificate.update('cert', value, false)"
          )
          .load-new(@click="triggerImportCert")
            i.fp4.fp4-up-from-line
            .button {{ $t('organization.settings.infrastructure.certificate.server_certificate.load_new') }}
            input(
              type="file"
              @change="fileAdded($event.target.files, 'cert')"
              ref="importCert"
            )
          br
        .certificate-key(v-if='!isEditing')
          fpui-input-textarea(
            :label="$t('organization.settings.infrastructure.certificate.key')"
            :rows=4
            :placeholder="$t('organization.settings.infrastructure.certificate.key')"
            :value="certificate.key"
            @input="value => certificate.update('key', value, false)"
          )
          .load-new(@click="triggerImportKey")
            i.fp4.fp4-up-from-line
            .button {{ $t('organization.settings.infrastructure.certificate.key.load_new') }}
            input(
              type="file"
              @change="fileAdded($event.target.files, 'key')"
              ref="importKey"
            )
      fpui-modal-footer(:buttons="buttons()")
</template>

<script>

export default {
  props: {
    certificate: { type: Object, default: () => ({}) },
    onConfirm: { type: Function, default: () => null }
  },
  data () {
    return {
      confirm: false
    }
  },
  computed: {
    isEditing () {
      return this.certificate?._id
    }
  },
  mounted () {
    this.certificate.backup()
  },
  destroyed () {
    if (!this.confirm) this.certificate.restore()
  },
  methods: {
    hasChanges () {
      if (this.certificate.hasChanges) return true
      return false
    },
    isValid () {
      if (this.isEditing) return this.certificate.name
      return this.certificate.name && this.certificate.domain && this.certificate.cert && this.certificate.key
    },
    buttons () {
      return [{
        label: this.$t('confirm'),
        color: 'green',
        disabled: this.hasChanges() && !this.isValid(),
        handler: () => {
          this.confirm = true
          this.onConfirm(this.certificate)
          this.$emit('close')
        }
      }]
    },
    fileAdded (file, key) {
      const reader = new FileReader()
      const _this = this
      reader.addEventListener('loadend', function (event) {
        _this.certificate.update(key, event.target.result, false)
      })
      reader.readAsText(file[0])
    },
    triggerImportCert () {
      this.$refs.importCert.click()
    },
    triggerImportKey () {
      this.$refs.importKey.click()
    }
  }
}
</script>

<style lang="less">
  .certificate-modal {
    .fpui-modal {
      .fpui-helper {
        display: flex;
        flex-grow: 1;
      }

      .certificate-cert, .certificate-key {
        position: relative;
        .load-new {
          display: flex;
          position: absolute;
          top: -3px;
          right: 0;
          font-size: 12px;
          color: #B2BECA;
          cursor: pointer;
          z-index: 1;
          .button {
            padding-left: 4px;
            font-size: 12px;
          }
          i {
            font-size: 16px;
            color: #CBD3DB;
          }
          input[type="file"] {
            display: none;
          }
        }
        .fpui-input-label-container {
          cursor: unset;
        }
        input[type="file"] {
          position: absolute;
          top: -2px;
          width: 90px;
          height: 15px;
          right: 0;
          bottom: 0;
          opacity: 0;
          cursor: pointer;
          z-index: 1;
          &.disabled {
            display: none;
          }
        }
      }
    }
  }
</style>
