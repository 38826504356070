<template lang="pug">
  iframe.iframe-service(
    v-if="src"
    :src="src"
  )
</template>

<script>
import qs from 'qs'
export default {
  props: {
    dataplantId: {
      type: String,
      required: true
    },
    service: {
      type: String,
      required: true
    },
    query: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      src: null
    }
  },
  computed: {
    lang () { return this.$i18n.locale() },
    dataplant () {
      return this.$store.getters.DATAPLANT_BY_ID(this.dataplantId)
    },
    queryString () {
      const query = this.query || {}
      query.lang = this.lang
      return '?' + qs.stringify(this.query)
    },
    baseUrl () {
      return process.env[`VUE_APP_${this.service.toUpperCase()}_UI`] ? process.env[`VUE_APP_${this.service.toUpperCase()}_UI`] : `https://${this.dataplant.url}/${this.service}/public`
    },
    path () {
      return this.$route.query.path || '/'
    }
  },
  mounted () {
    this.src = `${this.baseUrl}/#${this.path}${this.queryString}`
    window.addEventListener('message', this.onMessage, false)

    if (this.$store.getters.OPEN_SIDE_BAR) this.$store.commit('SET_OPEN_SIDE_BAR', false)
  },
  destroyed () {
    window.removeEventListener('message', this.onMessage, false)
  },
  methods: {
    onMessage ({ data }) {
      const id = data?.id
      if (id === this.service && data.op) {
        switch (data.op) {
          case 'REDIRECT':
            this.$router.push(data.value)
            break
          case 'ROUTE_CHANGE':
            this.$router.replace({ path: this.$route.path, query: { path: data.value } }).catch(() => {})
            break
          case 'CLICK':
            this.$store.commit('CLICK_FROM_IFRAME')
        }
      }
    }
  }
}
</script>

<style lang="less">
.dataplants {
  text-align: center;
}
</style>
