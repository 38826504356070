<template lang="pug">
  .orga-cell-name.cell-tags.td.fp-tree-cell
    img(style='vertical-align: middle;height: 31px; width: 31px; border-radius: 3px; margin-right: 13px; object-fit: contain;' :src="imageProfile")
    span {{ fullName }}
</template>

<script>
import neutralImg from '@/core/assets/img/users/neutral@2x.png'
import man1Img from '@/core/assets/img/users/man_1@2x.png'
import man2Img from '@/core/assets/img/users/man_2@2x.png'
import woman1Img from '@/core/assets/img/users/woman_1@2x.png'
import woman2Img from '@/core/assets/img/users/woman_2@2x.png'

export default {
  props: {
    value: { type: Object, default: () => {} }
  },
  computed: {
    fullName () {
      let fullname = ''
      const usr = this.value.object.user
      if (usr?.firstname || usr?.lastname) {
        fullname = [usr?.firstname, usr?.lastname].filter(e => e).join(' ')
      } else {
        fullname = this.value.object.email
      }
      return fullname
    },
    imageProfile () {
      const manImages = [man1Img, man2Img]
      const womanImages = [woman1Img, woman2Img]
      const usr = this.value.object.user
      if (usr) {
        if (usr.configuration.logo) {
          return usr.configuration.logo
        }
        if (usr.civility === 1) {
          return manImages[usr.lastname.length % 2]
        }
        if (usr.civility === 2) {
          return womanImages[usr.lastname.length % 2]
        }
      }
      return neutralImg
    }
  }
}
</script>

<style lang="less">
.orga-cell-name {
  line-height: 45px;
}
</style>
