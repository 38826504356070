<template lang="pug">
  .error-modal
    fpui-modal-header(:title="$t('dashboard.error.service_unavailable')")
    fpui-modal-body
      .modal-container
        .modal-body
          .error-tooltip-container
            .desc {{ $t('dashboard.error.service_unavailable.desc') }}
            .stack-container
              .stack {{ error.stack }}
              .copy-container(
                v-clipboard:copy="error.stack"
                v-clipboard:success="clipboardSuccessHandler"
              )
                i.fp4.fp4-copy
                span {{ $t('dashboard.error.service_unavailable.copy') }}

    fpui-modal-footer
      .footer
        fpui-button.cancel(
          @click="$emit('close')"
          color="white"
        ) {{ $t('close') }}
</template>

<script>
export default {
  props: {
    error: { type: Object, required: true }
  },
  methods: {
    clipboardSuccessHandler ({ value, event }) {
      this.$fpuiMessageBlock.pop('success', this.$t('edit_powerbi.config.copy_button.success'))
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.error-modal {
  font-size: 14px;
  .modal-body {
    padding: 10px 20px;
    .error-tooltip-container {
      height: 100%;
      width: 100%;
      font-size: 13px;
      user-select: text;
      cursor: initial;
      .desc {
        color: @text-color;
        text-align: left;
        margin-bottom: 20px;
      }
      .stack-container {
        height: 100%;
        background:#3e434f;
        color: white;
        border-radius: 5px;
        overflow-y: auto;
        position: relative;
        ::-webkit-scrollbar {
          display: none;
        }
        .stack {
          font-family: Roboto;
          padding: 20px 15px;
          text-align: left;
          white-space: pre-wrap;
          height: 85%;
        }
        .copy-container {
          display: none;
          position: absolute;
          top: 2px;
          right: 6px;
          align-items: center;
          justify-content: center;
          color: #fff;
          cursor: pointer;

          &:hover {
            opacity: 0.8;
          }
        }

        &:hover {
          .copy-container {
            display: flex;
          }
        }
      }
    }
  }
  .modal-footer {
    padding-left: 0;
    .left-footer {
      width: 100%;
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      .button-container {
        display: flex;
        justify-content: flex-end;
      }
    }
    .footer-actions {
      display: none;
    }
  }
}

</style>
