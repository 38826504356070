import ApiLeftButton from './ApiLeftButton.vue'
import ApiMainTable from './ApiMainTable.vue'
import ApiLeftKpi from './ApiLeftKpi.vue'
import ApiRightKpi from './ApiRightKpi.vue'

export default {
  ApiLeftButton,
  ApiMainTable,
  ApiLeftKpi,
  ApiRightKpi
}

export {
  ApiLeftButton,
  ApiMainTable,
  ApiLeftKpi,
  ApiRightKpi
}
