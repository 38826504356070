<template lang="pug">
.left-button.am
  a(
    :href="link"
    @click="goTo"
  )
    fpui-button(
      color="white"
      :disabled="disabled"
      v-tooltip="{ content: !canAmQueryWrite ? $t('dashboard.access.tooltip') : $t('project.home.am.button_tooltip_left'), delay: 0 }"
    ) {{ $t('project.home.am.new_query') }}
</template>

<script>
export default {
  props: {
    editing: { type: Boolean, required: true }
  },
  computed: {
    projectId () {
      return this.$route.params?.projectId
    },
    link () {
      return `/am/#/${this.projectId}/query`
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    serviceReady () {
      return !Object.keys(this.project?.display_settings?.services_wizard || {}).includes('am')
    },
    canAmQueryWrite () {
      return this.$store.getters.ACL('canAmQueryWrite')
    },
    disabled () {
      return !this.canAmQueryWrite || !this.serviceReady || this.editing
    }
  },
  methods: {
    goTo (e) {
      if (this.disabled) {
        e.preventDefault()
      } else {
        this.$analytics.track('Click on project home card’s shortcut CTA', {
          service: 'Analytics Manager',
          action: 'new query'
        })
      }
    }
  }
}
</script>

<style lang="less">

</style>
