<template lang="pug">
.second-table.dm
  fpui-table(
    :columns='columns'
    :actions='actions'
    :data='items'
    :loading="loading"
    :force-loader="true"
    sort-on="creation_date"
    :height="'143px'"
    :pagination="{ disabled: true }"
  )
</template>

<script>
import _orderBy from 'lodash/orderBy'
import moment from 'moment'

export default {
  props: {
    fromLarge: { type: Boolean, default: false }
  },
  data () {
    return {
      items: [],
      loading: false,
      itemLimit: 4
    }
  },
  computed: {
    actions () {
      return []
    },
    columns () {
      const href = `dm/#/${this.$route.params.projectId}/buckets`
      return [
        {
          label: () => { return `<a class='cell-link' href="${href}">${this.$t('project.home.dm.recently_used_buckets')}</a>` },
          name: 'name',
          target: row => {
            return `<a class='cell-link' href="${href}/${row.name}">${row.name}</a>`
          },
          paddingLeft: 24,
          grow: 1.2
        },
        {
          label: this.$t('project.home.dm.created_at_bucket'),
          name: 'creation_date',
          reverse: true,
          target: row => {
            if (!row.creation_date) return '-'
            return moment(row.creation_date).fromNow()
          },
          tooltip: (row) => {
            if (!row.creation_date) return '-'
            return moment(row.creation_date).format('DD/MM/YYYY HH:mm')
          },
          grow: 0.8
        }
      ]
    },
    projectId () {
      return this.$route.params?.projectId
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    serviceReady () {
      return !Object.keys(this.project?.display_settings?.services_wizard || {}).includes('dm')
    },
    canDatastoreBucketRead () {
      return this.$store.getters.ACL('canDatastoreBucketRead')
    }
  },
  watch: {
    serviceReady: {
      handler (val) {
        if (val) this.load()
      }
    },
    '$store.getters.PROJECT_CARD_INFO_LOADING': {
      handler (val) {
        if (!val) this.load()
      }
    }
  },
  async mounted () {
    if (!this.$store.getters.PROJECT_CARD_INFO_LOADING) await this.load()
  },
  methods: {
    async load () {
      if (this.canDatastoreBucketRead && this.serviceReady) {
        this.loading = true

        try {
          const buckets = await this.$api.DS.buckets.list()
          this.items = _orderBy(buckets, ['creation_date'], ['desc']).slice(0, this.itemLimit)
        } catch (err) {
          // Do nothing
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="less">
</style>
