<template lang="pug">
  .organization-plan-billing-details
    .line-details
      .line-title(@click="expanded.subscription = !expanded.subscription")
        .content
          span.bubble.bubble-blue
          span {{ 'organization.plan.billing.subscription_cost' | translate }}
        .value {{ total.subscription | price(subscription.currency_code,2) }}
          i.fp4(:class="{'fp4-chevron-down':!expanded.subscription,'fp4-chevron-up':expanded.subscription}")
      .lines-expand(v-if="expanded.subscription")
        .line-expand.plan.odd
          .name(v-if="subscription.plan")
            img(:src="require(`@/core/assets/img/plan/${subscription.plan}.png`)")
            span {{subscription.plan | Capitalize }}
          .qty 1
          .unit-price {{subscription.plan_unit_price | price(subscription.currency_code,2) }}
          .amount {{subscription.plan_unit_price | price(subscription.currency_code,2) }}
        .line-expand.addon(v-for="(addon,index) in subscription.addons").even
          .name {{ $store.getters.ADDON_BY_ID(addon.id) && $store.getters.ADDON_BY_ID(addon.id).name }}
          .qty {{ addon.quantity }}
          .unit-price {{ addon.unit_price | price(subscription.currency_code,2) }}
          .amount {{ addon.amount | price(subscription.currency_code,2) }}
    .line-details(v-if="fpuCharges.length")
      .line-title(@click="expanded.fpu = !expanded.fpu")
        .content
          span.bubble.bubble-blue-flash
          span {{ 'organization.plan.billing.add_compute_cost' | translate }}
        .value {{ total.fpu | price(subscription.currency_code,2) }}
          i.fp4(:class="{'fp4-chevron-down':!expanded.fpu,'fp4-chevron-up':expanded.fpu}")
      .lines-expand(v-if="expanded.fpu")
        .line-expand(
          v-for="(charge,index) in fpuCharges"
          :key="index"
          :class="{odd:index%2===0,even:index%2===1}"
        )
          .name {{ $store.getters.ADDON_BY_ID(charge.addon_id) && $store.getters.ADDON_BY_ID(charge.addon_id).name }}
            span(v-if="charge.prevision") &nbsp;{{ $t('organization.plan.billing.predicted') }}
            span(v-if="isIncludedCharge(charge)") &nbsp;{{ 'organization.plan.billing.included' | translate }}
          .qty {{charge.addon_quantity}} {{ 'organization.plan.billing.fpu-hours' | translate }}
          .unit-price {{ getChargeUnitPrice(charge) | price(subscription.currency_code,2) }}
          .amount {{ charge.addon_quantity * getChargeUnitPrice(charge) | price(subscription.currency_code,2)}}
    .line-details(v-if="dataplantCharges.length")
      .line-title(@click="expanded.dataplant = !expanded.dataplant")
        .content
          span.bubble.bubble-green
          span {{ 'organization.plan.billing.add_dataplant_cost' | translate }}
        .value {{ total.dataplant | price(subscription.currency_code,2) }}
          i.fp4(:class="{'fp4-chevron-down':!expanded.dataplant,'fp4-chevron-up':expanded.dataplant}")
      .lines-expand(v-if="expanded.dataplant")
        .line-expand(
          v-for="(charge,index) in dataplantCharges"
          :key="index"
          :class="{odd:index%2===0,even:index%2===1}"
        )
          .name {{ $store.getters.ADDON_BY_ID(charge.addon_id) && $store.getters.ADDON_BY_ID(charge.addon_id).name }}
            span(v-if="charge.prevision") &nbsp;{{ $t('organization.plan.billing.predicted') }}
            span(v-if="isIncludedCharge(charge)") &nbsp;{{ 'organization.plan.billing.included' | translate }}
          .qty {{charge.addon_quantity}} {{ 'organization.plan.billing.dataplant-hours' | translate }}
          .unit-price {{ getChargeUnitPrice(charge) | price(subscription.currency_code,2) }}
          .amount {{ charge.addon_quantity * getChargeUnitPrice(charge) | price(subscription.currency_code,2)}}
    hr

    .line-details
      .line-title
        .content
          span.total {{ $t('organization.plan.billing.estimated_amount') }}&nbsp;
        .value {{ total.total | price(subscription.currency_code,2) }}
    br

</template>

<script>
import _sum from 'lodash/sum'
export default {
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      expanded: {
        subscription: false,
        fpu: false,
        dataplant: false
      }
    }
  },
  computed: {
    charges () {
      return this.organization?.consumptions?.charges || []
    },
    fpuCharges () {
      return this.charges.filter(c => c.addon_id.split('-')[0] === 'fpu')
    },
    dataplantCharges () {
      return this.charges.filter(c => c.addon_id.split('-')[0] === 'dataplant')
    },
    total () {
      const subscriptionMain = this.subscription?.plan_unit_price
      const addOns = this.subscription?.addons ? this.subscription?.addons.map(a => a.amount) : []
      const subscription = _sum([subscriptionMain].concat(addOns))
      const fpu = _sum(this.fpuCharges.map(c => c.addon_quantity * this.getChargeUnitPrice(c)))
      const dataplant = _sum(this.dataplantCharges.map(c => c.addon_quantity * this.getChargeUnitPrice(c)))
      return {
        total: subscription + dataplant + fpu,
        subscription,
        dataplant,
        fpu
      }
    },
    isCustom () {
      return this.subscription?.plan === 'custom'
    },
    account () {
      if (!this.organization.customerAccountId) return null
      return this.$store.getters.ACCOUNT_BY_ID(this.organization.customerAccountId)
    },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization.subscription
    }
  },
  methods: {
    isIncludedCharge (charge) {
      return charge.addon_unit_price === 0
    },
    getChargeUnitPrice (charge) {
      if (charge.addon_unit_price !== undefined) return charge.addon_unit_price
      return this.$store.getters.ADDON_BY_ID(charge.addon_id)?.price || 0
    }
  }
}
</script>

<style lang="less">
.organization-plan-billing-details {
  .line-details {
    margin: 20px 0;
    .line-title {
      cursor: pointer;
      font-size: 14px;
      font-weight: 600;
      line-height: 28px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .value {
        font-size: 18px;
        font-weight: bold;
        line-height: 23px;
        i {
          margin-left: 5px;
        }
      }
    }
    .lines-expand {
      .plan .name {
        img,span {
          display: inline-block;
          vertical-align: middle;
        }
        img {
          height: 15px;
          width: 15px;
          margin-right: 10px;
        }
      }
      .line-expand {
        display: flex;
        align-items: center;
        font-size: 12px;
        line-height:42px;
        padding: 0 23px;
        &.even {
          background-color: #FAFBFC;
        }
        .name {
          flex: 2;
        }
        .qty,.unit-price,.amount {
          text-align: right;
          flex: 1;
        }
      }
    }
  }
}
</style>
