<template lang="pug">
  .cannot-do-in-trial-subscription
    fpui-modal
      fpui-modal-body
        i.fp4.fp4-xmark(
          @click='$emit("close")'
        )
        .content
          .title {{ $t('home.dataplant.cannot_do_in_trial_title') }}
          .description {{ $t(text) }}
          fpui-button(
            color="blue-flash"
            @click="updateSubscription"
          ) {{ $t('home.dataplant.cannot_do_in_trial_get_a_plan') }}
</template>

<script>
import SubscriptionNew from '@/core/components/Subscription/New.vue'

export default {
  props: {
    organizationId: { type: String, required: true },
    text: { type: String, default: 'home.dataplant.cannot_do_in_trial_description' }
  },
  methods: {
    updateSubscription () {
      this.$emit('close')
      this.$modal.show(SubscriptionNew, {
        organizationId: this.organizationId,
        trial: true
      }, {
        height: 'auto',
        width: 900
      })
    }
  }
}
</script>

<style lang="less">
  .cannot-do-in-trial-subscription {
    position: relative;

    .modal-body {
      padding-left: 0;
      padding-right: 0;
    }

    .fp4-xmark {
      position: absolute;
      font-size: 20px;
      color: #CBD3DB;
      right: 20px;
      top: 15px;
      cursor: pointer;
    }

    .content {
      height: 283px;
      background-image:url("~@/core/assets/img/rocket_create_dataplant_unable@2x.png");
      background-repeat: no-repeat;
      background-size: contain;
      display: flex;
      flex-direction: column;
      align-items: center;

      .title {
        color: #3E4550;
        font-size: 20px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 25px;
        text-align: center;
        margin-top: 129px;
        margin-bottom: 8px;
      }
      .description {
        color: #3E4550;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        text-align: center;
        margin-bottom: 39px;
        padding-left: 20px;
        padding-right: 20px;
      }
    }
  }
</style>
