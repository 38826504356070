<template lang="pug">
  .orga-cell-status.cell-tags.td.fp-tree-cell
    i.fp4(:class="status")

</template>

<script>

export default {
  props: {
    value: { type: Object, default: () => {} },
    type: { type: String, default: 'tags' }
  },
  computed: {
    status () {
      return this.value.object.accepted ? 'fp4-circle-check' : 'fp4-hourglass'
    }
  }
}
</script>

<style lang="less">
.orga-cell-status{
  i.fp4-hourglass{
    line-height: 45px;
    font-size: 25px;
    color: @yellow;
  }
  i.fp4-circle-check{
    line-height: 45px;
    font-size: 25px;
    color: @green;
  }
}
</style>
