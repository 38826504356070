<template lang="pug">
.quick-start
  .fp-box.no-bg
    .box-title.with-link
      .title {{ $t('core.onboarding.quick_start.title') }}
      a.box-title-link.underline-hover(
        :href="servicesLink"
      )
        span {{ $t('core.onboarding.quick_start.browse_all') }}
        i.fp4.fp4-angle-right
    .boxes-container
      .box(
        v-for="link in links"
        :disabled="!canCreateProject"
        :class="{ disabled: !canCreateProject }"
        v-tooltip="{ content: disabled ? disabledMessage : '', delay: 0 }"
        @click="createProject(link.service)"
      )
        .information
          .title {{ link.title }}
          .subtitle {{ link.subtitle }}
        .arrow
          i.fp4.fp4-angle-right
</template>

<script>
import QuickProjectCreation from '@/shared/components/app/QuickProjectCreation'
import SubscriptionNew from '@/core/components/Subscription/New'

export default {
  props: {
    organizationId: { type: String, default: '' },
    canCreateProject: { type: Boolean, default: true }
  },
  computed: {
    links () {
      return [
        {
          service: 'dm',
          title: this.$t('core.onboarding.quick_dm_tilte'),
          subtitle: this.$t('core.onboarding.quick_dm_subtilte')
        }, {
          service: 'dpe',
          title: this.$t('core.onboarding.quick_dpe_tilte'),
          subtitle: this.$t('core.onboarding.quick_dpe_subtilte')
        }, {
          service: 'mlm',
          title: this.$t('core.onboarding.quick_mlm_tilte'),
          subtitle: this.$t('core.onboarding.quick_mlm_subtilte')
        }
      ]
    },
    servicesLink () {
      return `/#/home/${this.organizationId}/services`
    },
    organization () {
      if (this.organizationId === '') return false
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization?.subscription || null
    },
    subscriptionStatus () {
      if (this.organization.bypass_billing) return 'active'
      if (!this.subscription) return null
      return this.subscription?.status
    },
    disabled () {
      return !this.canCreateProject
    },
    disabledMessage () {
      return this.$t('core.onboarding.no_permission_to_create')
    }
  },
  methods: {
    async createProject (module) {
      if (!this.subscription) await this.organization.loadSubscription()
      if (
        (['active', 'non_renewing'].includes(this.subscriptionStatus)) ||
        (this.subscription?.status === 'in_trial' && this.subscription?.payment_valid && this.subscription?.scheduled_status !== 'cancelled')
      ) {
        if (!this.$acl(this.organizationId).can('core', 'dataplants', 'create')) return
        this.$analytics.track('Open new project funnel', {
          from: 'welcome page',
          channel: 'new single service'
        })
        this.$modal.show(QuickProjectCreation, {
          organizationId: this.organizationId,
          module: ['api', 'app'].includes(module) ? 'appservice' : module,
          afterConfirm: () => {
            this.$router.push(`home/${this.organizationId}`)
          }
        }, {
          height: 'auto',
          width: '600px',
          adaptative: true,
          classes: []
        })
      } else {
        this.updateTrialToRealPlan()
      }
    },
    async updateTrialToRealPlan () {
      this.$modal.show(SubscriptionNew, {
        organizationId: this.organization._id,
        trial: true
      }, {
        height: 'auto',
        width: 900
      })
    }
  }
}
</script>

<style lang="less">
.quick-start {
  .fp-box {
    > .boxes-container {
      display: flex;
      justify-content: space-between;
      .box {
        padding: 11px 12px;
        box-shadow: 0px 2px 4px rgba(52, 65, 78, 0.05);
        border: 1px solid #E4E7EC;
        border-radius: 5px;
        background: @white;
        width: 33%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        transition: all 150ms;
        &:hover:not(.disabled) {
          background-color: #eff8fb;
          border: 1px solid #00ccf9;
        }
        &:not(:last-child) {
          margin-right: 10px;
        }
        > .information {
          > .title {
            color: @text-color;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
          }
          > .subtitle {
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: @grey;
          }
        }
        > .arrow {
          padding-left: 10px;
          font-size: 18px;
          color: #97A7B7;
        }
        &.disabled {
          background: #E9ECF0;
          border: 1px solid #E4E7EC;
          color: #97A7B7;
          cursor: not-allowed;
          .title {
            color: #97A7B7;
          }
        }
      }
    }
  }
}
</style>
