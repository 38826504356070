const CHARGEBEE = {
  local: {
    EUR: {
      SITE: 'forepaas-test',
      APIKEY: 'test_SYbSeXom2SMkCEn4w840K46AQbDAJzpm'
    },
    USD: {
      SITE: 'forepaas-na-test',
      APIKEY: 'test_oeTDK5Al5hjrV1cuvyz6SOeXSXfuPOoDl'
    }
  },
  development: {
    EUR: {
      SITE: 'forepaas-test',
      APIKEY: 'test_SYbSeXom2SMkCEn4w840K46AQbDAJzpm'
    },
    USD: {
      SITE: 'forepaas-na-test',
      APIKEY: 'test_oeTDK5Al5hjrV1cuvyz6SOeXSXfuPOoDl'
    }
  },
  production: {
    EUR: {
      SITE: 'forepaas',
      APIKEY: 'live_IVWt7pcdA7wNw79EsdiJFaexKv2QJOXI3'
    },
    USD: {
      SITE: 'forepaas-na',
      APIKEY: 'live_NKrucsLxI89hpXF8QpAPs70jdOT5R96I'
    }
  }
}


export default {
  APP_ID: 'hq',
  CMP: true,
  CHARGEBEE: CHARGEBEE[process.env.VUE_APP_ENV || 'production']
}
