import GabLeftButton from './GabLeftButton.vue'
import GabRightButton from './GabRightButton.vue'
import GabMainTable from './GabMainTable.vue'
import GabSecondTable from './GabSecondTable.vue'
import GabLeftKpi from './GabLeftKpi.vue'
import GabRightKpi from './GabRightKpi.vue'

export default {
  GabLeftButton,
  GabRightButton,
  GabMainTable,
  GabSecondTable,
  GabLeftKpi,
  GabRightKpi
}

export {
  GabLeftButton,
  GabRightButton,
  GabMainTable,
  GabSecondTable,
  GabLeftKpi,
  GabRightKpi
}
