<template lang="pug">
.main-table.dm
  fpui-table(
    :columns='columns'
    :actions='actions'
    :data='items'
    :loading="loading"
    :force-loader="true"
    sort-on="created_at"
    :height="fromLarge ? '143px' : '115px'"
    :pagination="{ disabled: true }"
  )
</template>

<script>
import _orderBy from 'lodash/orderBy'
import moment from 'moment'

export default {
  props: {
    fromLarge: { type: Boolean, default: false }
  },
  data () {
    return {
      itemLimit: this.fromLarge ? 4 : 3
    }
  },
  computed: {
    actions () {
      return []
    },
    columns () {
      return [
        {
          label: () => {
            const href = `data-catalog/#/${this.$route.params.projectId}/sources`
            return `<a class='cell-link' href="${href}">${this.$t('project.home.data-catalog.recently_created_sources')}</a>`
          },
          name: 'name',
          target: row => {
            const href = `data-catalog/#/${this.$route.params.projectId}/source/${row._id}`
            return `<a class='cell-link' href="${href}?focusTable=${row.name}">${row.display_name}</a>`
          },
          paddingLeft: 24,
          grow: 1.2
        },
        {
          label: this.$t('project.home.data-catalog.created_at'),
          name: 'created_at',
          reverse: true,
          target: row => {
            if (!row.created_at) return '-'
            return moment(row.created_at).locale(this.$i18n.locale()).fromNow()
          },
          tooltip: (row) => {
            if (!row.created_at) return '-'
            return moment(row.created_at).locale(this.$i18n.locale()).format('DD/MM/YYYY HH:mm')
          },
          grow: 0.8
        }
      ]
    },
    loading () {
      return this.$store.getters.PROJECT_CARD_INFO_LOADING || this.$store.getters.PROJECT_HOME_DATABASES_LOADING
    },
    items () {
      return _orderBy(this.$store.getters.PROJECT_HOME_DATABASES || [], ['created_at'], ['desc']).slice(0, this.itemLimit)
    }
  },
  mounted () {
    if (!this.loading) {
      try {
        this.$store.dispatch('LOAD_DATABASES')
      } catch (err) {}
    }
  }
}
</script>

<style lang="less">
</style>
