<template lang="pug">
  .committeds-modal
    fpui-modal
      fpui-modal-body
        fp-loader(v-if="loading")
        .header
          h4 {{ 'committeds.new-modal-title' | translate }}
          p(v-html="$t('committeds.new-modal-subtitle')")
          //- If var is put in v-html -> use $sanitize()

        committeds(:cancel="cancel",@complete="confirm",v-model="committeds")
</template>
<script>
import Committeds from '@/core/components/Subscription/Committeds.vue'
export default {
  components: { Committeds },
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      committeds: [],
      loading: false
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    }
  },
  methods: {
    cancel () {
      this.$emit('close')
    },
    async confirm () {
      this.loading = true
      try {
        const body = this.committeds.map(committed => ({
          plan: `${committed.resource_type}-${committed.provider}`,
          billing_period_unit: committed.billing_period_unit,
          term_commitment: committed.term_commitment,
          quantity: committed.quantity
        }))
        await this.organization.createCommitteds(body)
        this.$emit('close')
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    }
  }
}
</script>
<style lang="less">
 .committeds-modal {
  .fpui-modal .modal-body {
    background:url('~@/core/components/Subscription/assets/modal_background.png');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
  }

  .header {
    text-align: center;
    h4 {
      font-size: 32px;
      margin: 10px 0;
      font-weight: bold;
      line-height: 40px;
    }
    p {
      font-size: 14px;
      line-height: 20px;
    }
  }
  .fpui-steps-footer {
    .actions {
      justify-content: space-between;
    }
  }
  .stepper {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
  }
 }
</style>

