<template lang="pug">
  .home
</template>

<script>
export default {
  components: {
  },
  computed: {
    organizations () {
      return this.$store.getters.ORGANIZATIONS
    },
    currentUser () {
      return this.$store.getters.SESSION
    }
  },
  watch: {
    organizations () {
      this.setOrganizationIfEmpty()
    }
  },
  mounted () {
    this.setOrganizationIfEmpty()
  },
  methods: {
    setOrganizationIfEmpty () {
      const org = this.$store.getters.ACTIVE_ORGANIZATION(this.$route.params)
      if (org) this.$router.push(`/organization/${org._id}`)
      else this.$router.push('/home/new')
    }
  }
}
</script>

<style lang="less">

</style>
