<template lang="pug">
.left-button.iam
  a(
    :href="link"
    @click="goTo"
  )
    fpui-button(
      color="white"
      :disabled="disabled"
      v-tooltip="{ content: !canIamRoleWrite ? $t('dashboard.access.tooltip') : $t('project.home.iam.button_tooltip_right'), delay: 0 }"
    ) {{ $t('project.home.iam.grant_permission') }}
</template>

<script>
export default {
  props: {
    editing: { type: Boolean, required: true }
  },
  computed: {
    projectId () {
      return this.$route.params?.projectId
    },
    link () {
      return `/iam/#/${this.projectId}/role`
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    serviceReady () {
      return !Object.keys(this.project?.display_settings?.services_wizard || {}).includes('iam')
    },
    canIamRoleWrite () {
      return this.$store.getters.ACL('canIamRoleWrite')
    },
    disabled () {
      return !this.canIamRoleWrite || !this.serviceReady || this.editing
    }
  },
  methods: {
    goTo (e) {
      if (this.disabled) {
        e.preventDefault()
      } else {
        this.$analytics.track('Click on project home card’s shortcut CTA', {
          service: 'Identity Access manager',
          action: 'grant permission'
        })
      }
    }
  }
}
</script>

<style lang="less">

</style>
