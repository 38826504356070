<template lang="pug">
.services-list-page
  .services-list-container
    fp-title(
      :border="true"
      :parent="$t('services.all_services')"
    )
    .content
      .list-of-services
        service(
          :organization-id="organizationId"
          :filtered-service-list="servicesList"
          :project-id="projectId"
        )
      .vertical-tabs
        .links
          router-link(
            v-for="service in servicesList"
            :key='service.category'
            :to="getLink(service.category)"
            @click.native="scrollToId(service.category)"
          )
            span {{ $t(`services.title.${service.category}`) }}
      .content-services-footer
        span(v-html="$sanitize($t('services.footer.support', [supportLink]))")
</template>

<script>
import Service from './Service.vue'
import ServicesList from '@/core/helpers/services'
import Config from '@/shared/Config'
import ovhSupportUrl from '@/shared/store/constants/supportUrl'
import qs from 'qs'

export default {
  components: {
    Service
  },
  props: {
    organizationId: { type: String, default: '' }
  },
  data () {
    return {
      servicesList: [],
      config: null
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    openSidebar () {
      return this.$store.getters.OPEN_SIDE_BAR
    },
    hash () {
      return this.$route?.hash
    },
    supportLink () {
      return this.config?.DATA_PLATFORM_ACCESS ? ovhSupportUrl : `#/support/${this.organizationId}`
    },
    projectId () {
      return this.$route.query?.project_id
    }
  },
  async mounted () {
    this.config = await Config()
    this.$store.commit('SET_OPEN_SIDE_BAR', true)
    const filteredServicesList = [...ServicesList]
    // Do not Display 'Admin. Tools' section if this page comes from 'ADD MORE SERVICES' button (having project_id in the query params)
    // Show it only this page comes from 'Browse all services' button in Home
    if (this.projectId) filteredServicesList.pop()

    const endToEndProject = {
      category: 'end_to_end',
      color: '#3E4550',
      services: [{
        id: 'end-to-end',
        icon: 'fp4-dataplants',
        displayName: this.$t('services.display_name.end_to_end'),
        smallDescription: this.$t('services.small_description.end_to_end'),
        longDescription: '',
        documentation: 'https://docs.forepaas.io/#/en/product/data-manager/index'
      }]
    }
    // Do not Display "end to end project" section if this page comes from 'ADD MORE SERVICES' button (having project_id in the query params)
    // Show it only this page comes from 'Browse all services' button in Home
    this.servicesList = !this.projectId ? [endToEndProject, ...filteredServicesList] : filteredServicesList
    this.$analytics.page('All Services List Page View')
  },
  methods: {
    redirectTo (documentation) {
      window.location.href = documentation
    },
    scrollToId (id) {
      document.getElementById(id).scrollIntoView()
    },
    getLink (category) {
      return this.projectId ? `?${qs.stringify({ project_id: this.projectId })}#${category}` : `#${category}`
    }
  }
}
</script>

<style lang="less">
.services-list-page{
  display: flex;
  height: 100%;
  width: 100%;

  .services-list-container {
    width: 100%;
    background-color: rgba(151,167,183,0.05);

    .title-bordered{
      position: sticky;
      top: 0px;
    }

    .content {
      display: grid;
      grid-template-columns: 1fr auto;
      height: calc(100% - 87px);
      overflow: auto;
      background-color: #FFFFFF;
    }

    .list-of-services{
      display: grid;
      gap: 10px;
      padding-left: 24px;
    }

    .vertical-tabs{
      width: 225px;
      height: 284px;
      top: 20px;
      position: sticky;
      padding: 0px 27px 0px 32px;
      gap: 4px;


      .links {
        display: grid;
        grid-auto-rows: auto; // Six rows that each take the same space/fraction
        grid-gap: 4px;
      }

      .links a {
        display: flex;
        align-items: center;
        justify-content: left;
        text-decoration: none;
        color: #97A7B7;
        height: 44px;

        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        &:hover, &.router-link-active {
          color: #00CCF9;
          background-color: #EBFBFF;
          border-radius: 6px;
        }
      }

      .links a span{
        margin: 10px 0px 10px 14px; // Add 14px left, 10px of space to the top and bottom of the text, the order is clockwise
        font-size: 14px; // Set font size to 14px
      }
    }

    .content-services-footer {
      grid-column: span 2;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      align-items: center;
      background-color: #F6F9FC;
      border-top: 1px solid #E4E7EC;
      color: #748294;
      font-size: 16px;
      padding: 20px 0;

      a {
        color: inherit;
        text-decoration: underline;
        font-size: inherit;
      }
    }
  }
}
</style>
