<template lang="pug">
  .appservice-create-step
    fpui-input-text(
      :placeholder="$t(`appservice.create.${type}.display_name.placeholder`)"
      :label="$t(`appservice.create.${type}.display_name.label`)"
      :value="value.display_name"
      @input="(v)=>value.update('display_name',v,false)"
      autofocus
    )
    fpui-input-text.version-name(
      :placeholder="$t('appservice.create.version.placeholder')"
      :label="$t('appservice.create.version.label')"
      :value="value.version"
      @input="(v)=>value.update('version',v,false)"
    )
    fpui-input-textarea.description(
      :placeholder="$t('appservice.create.description.placeholder')"
      :label="$t('appservice.create.description.label')"
      :value="value.description"
      @input="(v)=>value.update('description',v,false)"
    )
    fpui-input-radio(
      :label="$t('appservice.create.build.label')"
      :value="value.options.build"
      @input="(v)=>value.update('options.build',v,false)"
      :options="[{ value: true, label: 'radio.yes' },{ value: false, label: 'radio.no' }]"
    )
    fpui-input-radio(
      v-if="value.options.build"
      :label="$t('appservice.create.deploy.label')"
      :value="value.options.deploy"
      @input="(v)=>value.update('options.deploy',v,false)"
      :options="[{ value: true, label: 'radio.yes' },{ value: false, label: 'radio.no' }]"
    )
    select-api(
      v-if="value.options.deploy && value.type==='app'"
      :value="value.deploy_options.api"
      @input="(v)=>value.update('deploy_options.api',v,false)"
      :apis="applications.filter(app=>app.type==='api')"
    )

</template>

<script>
import SelectApi from './SelectApi.vue'
export default {
  components: { SelectApi },
  props: {
    type: {
      required: true,
      type: String
    },
    value: {
      required: true,
      type: Object
    },
    applications: {
      required: true,
      type: Array
    }
  }
}
</script>

<style lang="less">
.appservice-create-step {
  .version-name {
    margin-top: 14px;
  }
  .description {
    margin-top: 14px;
  }
  .fpui-input-image-content {
    .img-container {
      height: 200px;
      width: 100%;
      max-width: 100%;
      .img-wrapper {
        margin: 0 auto;
      }
    }
  }
}
</style>
