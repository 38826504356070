<template lang="pug">
.widgets-list
  fpui-dashboarding-layout-lite(
    v-if="item"
    ref="dashboard-layout-lite"
    responsive
    :item="item"
    :col-num="24"
    :min-w="3"
    :editing="true"
    :min-h="230"
    :active-tiles="activeTiles"
    component="tile"
    @layout-updated="layoutUpdated"
  )
    template(
      v-slot:tile="{ tile }"
    )
      .widget-container
        .widget-header
          widgets-options(
            :tile="tile"
            @delete-tile="deleteTile"
            @active-tile="activeTile"
            @deactive-tile="deactiveTile"
          )

        .widget-chart
          consumption-chart(
            v-if="tile.i === 'consumption-storage'"
            :loading="loadingConsumption"
            type="storage"
            :organization-id="organizationId"
          )
          consumption-chart(
            v-if="tile.i === 'consumption-dataplant'"
            :loading="loadingConsumption"
            type="dataplant"
            :organization-id="organizationId"
          )
          consumption-chart(
            v-if="tile.i === 'consumption-fpu'"
            :loading="loadingConsumption"
            type="fpu"
            :organization-id="organizationId"
          )
          consumption-chart(
            v-if="tile.i === 'consumption-fpug'"
            :loading="loadingConsumption"
            type="fpug"
            :organization-id="organizationId"
          )

        .widget-name
          span.name {{ $t(`widget.${tile.title}.name`) }}
</template>

<script>
import ConsumptionChart from '@/core/components/Organization/Overview/ConsumptionChart.vue'
import WidgetsOptions from './WidgetsOptions.vue'

export default {
  components: {
    ConsumptionChart,
    WidgetsOptions
  },
  props: {
    widgets: { type: Array, default: () => [] },
    organizationId: { type: String, required: true }
  },
  data () {
    return {
      loadingConsumption: false,
      activeTiles: []
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    item () {
      return {
        tiles: this.widgets
      }
    },
    currentUser () {
      return this.$store.getters.SESSION
    }
  },
  async mounted () {
    this.loadingConsumption = true
    await this.loadOrganizationData()
    this.loadingConsumption = false
  },
  methods: {
    async loadOrganizationData () {
      this.loading = true
      try {
        await this.organization.loadSubscription()
        await this.organization.loadConsumptions()
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    },
    layoutUpdated (layout) {
      this.currentUser.update(`preferences.home_page_preferences.${this.organizationId}.widgets.list`, layout)
    },
    addTile (tile, type) {
      if (this.$refs?.['dashboard-layout-lite']) this.$refs['dashboard-layout-lite'].addTile(tile, type)
    },
    deleteTile (tile) {
      this.$analytics.track('Remove widget on organization home', { widget: tile.i })
      if (this.$refs?.['dashboard-layout-lite']) this.$refs['dashboard-layout-lite'].deleteTile(tile)
    },
    activeTile (t) {
      if (!this.activeTiles.includes(t.i)) this.activeTiles.push(t.i)
    },
    deactiveTile (t) {
      if (this.activeTiles.includes(t.i)) {
        this.activeTiles = this.activeTiles.filter(tile => tile !== t.i)
      }
    }
  }
}
</script>

<style lang="less">
.widgets-list {
  margin-right: -10px;
  margin-left: -10px;
  .widget-container {
    height: 100%;
    background: #FFFFFF;
    border: 1px solid #E9ECF0;
    border-radius: 5px;
    padding: 10px;

    .widget-header {
      position: absolute;
      top: 7px;
      right: 15px;
      height: 20px;
      width: calc(~"100% - 15px");
      align-items: center;
      justify-content: flex-end;
      z-index: 1000;
      display: flex;
    }

    .widget-chart {
      height: calc(~"100% - 40px");
      position: relative;
      overflow: hidden;

      .fp-loader {
        top: 10px;
      }

      .organization-consumption-chart {
        height: 100%;
      }
    }

    .widget-name {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 9px;
      position: relative;
      height: 40px;

      .name {
        font-weight: 600;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: -0.01em;
      }
    }

    &:hover {
      .widget-header {
        .widget-options-container {
          margin-left: 10px;
          .fp4-ellipsis {
            display: block;
          }
        }
      }
    }
  }
}
</style>
