<template lang="pug">
.main-table.api
  fpui-table(
    :columns='columns'
    :actions='actions'
    :data='api'
    :pagination="{ disabled: true }"
    :height="fromLarge ? '290px' : '115px'"
  )
</template>

<script>
export default {
  props: {
    fromLarge: { type: Boolean, default: false }
  },
  computed: {
    applications () {
      return this.$store.getters.PROJECT_HOME_APPLICATIONS || []
    },
    api () {
      return this.applications?.filter(app => app.type === 'api')
    },
    actions () {
      return [
        {
          id: 'view',
          icon: 'fp4 fp4-eye',
          tooltip: this.$t('project.home.api.view'),
          if: row => row.isDeployed,
          click: row => {
            this.$emit('open-app', row)
          }
        },
        {
          id: 'edit',
          icon: 'fp4 fp4-pencil',
          tooltip: this.$t('project.home.api.edit'),
          click: row => {
            this.$emit('edit-app', row)
          }
        }
      ]
    },
    columns () {
      return [
        {
          name: 'name',
          label: this.$t('project.home.api.name'),
          target: row => {
            const href = `#/${row.type}/${this.$route.params.projectId}/${row.app_name}`
            return `<a class='cell-link' href="${href}">${row.display_name}</a>`
          },
          sortable: true,
          paddingLeft: 24,
          grow: 1.3
        },
        {
          name: 'name',
          label: this.$t('project.home.api.deployed'),
          target: row => {
            if (row.isDeployed) return "<i class='fp4 fp4-circle-check-small'></i>"
            return ''
          },
          grow: 0.7
        }
      ]
    }
  }
}
</script>

<style lang="less">
.api {
  .fpui-table {
    .fp4-circle-check-small {
      color: #9EDF10;
      font-size: 16px;
    }
  }
}
</style>
