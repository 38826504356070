<template lang="pug">
  .delete-service-modal
    fp-loader(v-if="loading")
    fpui-modal-header(:title="$t('dashboard.options.remove_service.service', [service])")
    fpui-modal-body
      .modal-container
        .modal-body
          .attention {{ $t('dashboard.options.remove_service_beware' )}}
          .insert {{ $t('import.modal.insert_remove' )}}
          fpui-input-text.import-text(
            v-model="importText"
            autofocus
          )
    fpui-modal-footer
      .footer
        fpui-button.cancel(
          @click="$emit('close')"
          color="white"
        ) {{ 'no' | translate }}
        fpui-button.confirm(
          @click="onConfirm"
          color="red"
          :disabled='!hasConfirmedText'
        ) {{ 'confirm' | translate }}
</template>

<script>
export default {
  props: {
    service: { type: String, default: '' },
    onSelect: { type: Function, required: true }
  },
  data () {
    return {
      loading: false,
      importText: ''
    }
  },
  computed: {
    hasConfirmedText () {
      return ['remove', 'supprimer'].includes(this.importText.toLowerCase())
    }
  },
  methods: {
    async onConfirm () {
      if (!this.hasConfirmedText) return
      this.loading = true
      try {
        await this.onSelect()
        this.$fpuiMessageBlock.success(this.$t('dashboard.options.remove_service_success'))
        this.$emit('close')
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";

.delete-service-modal {
  font-size: 14px;
  .modal-body {
    .attention {
      color: @red;
    }
    .insert {
      padding-top: 15px;
      text-align: center;
    }
    .import-text {
      display: flex;
      justify-content: center;
      padding-top: 15px;
      .fpui-input-text-content {
        min-width: 50%;
      }
    }
  }
  .modal-footer {
    padding-left: 0;
    .left-footer {
      width: 100%;
    }
    .footer {
      display: flex;
      justify-content: flex-end;
      .button-container {
        display: flex;
        justify-content: flex-end;
      }
      .confirm {
        padding-right: 10px;
        margin-left: 20px;
      }
    }
    .footer-actions {
      display: none;
    }
  }
}

</style>
