<template lang="pug">
.change-owner-modal
  fpui-modal
    fpui-modal-header(
      :title="$t('accounts.change_owner.header')"
      @close='$emit("close")'
      closeTopButton
    )
    fpui-modal-body
      fpui-input-select(
        :options="accountOptions",
        :clearable="false",
        :label="$t('accounts.change_owner.title')"
        v-model="owner"
      )
    fpui-modal-footer(:buttons="buttons")
</template>
<script>
import _uniq from 'lodash/uniq'
import _flatten from 'lodash/flatten'

export default {
  props: {
    account: { type: Object, required: true },
    onSubmit: { type: Function, required: true }
  },
  data () {
    return {
      owner: {},
      currentOwnerEmail: ''
    }
  },
  computed: {
    buttons () {
      return [{
        label: this.$t('cancel'),
        type: 'close',
        handler: () => {
          this.$emit('close')
        }
      }, {
        label: this.$t('confirm'),
        color: 'blue-flash',
        disabled: this.owner.email === this.currentOwnerEmail,
        async handler () {
          await this.account.changeOwner({ email: this.owner.email })
          await this.$store.dispatch('LOAD_ACCOUNTS')
          await this.onSubmit()
          this.$emit('close')
        }
      }]
    },
    organizations () {
      return this.$store.getters.ORGANIZATIONS
    },
    owners () {
      if (!this.organizations.length) return []
      return _uniq(_flatten(this.organizations.map(orga => orga.users && orga.users.map(usr => {
        const picture = null
        return {
          email: usr.email,
          picture,
          name: usr.email
        }
      }))), false, (i) => i.email).filter(Boolean).filter(user => user?.email !== this.account.email)
    },
    accountOptions () {
      if (!this.owners.length) return []
      return this.owners.map(o => {
        return {
          image: o.picture || require('@/core/assets/img/users/man_1@2x.png'),
          label: o.name,
          value: o
        }
      })
    }
  },
  mounted () {
    this.owner = this.owners.length ? this.owners[0] : []
    this.currentOwnerEmail = this.owner.email
  }
}
</script>
<style lang="less">

</style>
