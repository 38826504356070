<template lang='pug'>
.fp-header(
  v-if="$store.getters.READY('core_organizations')"
  :class="{ home: !fromProfile && !fromSupport && !fromOrganization && !fromAccount }"
)
  .left
    i.fp4.fp4-burger(
      v-if="displayDataplantSelector && !fromTeammates"
      @click="openSidebar"
    )
    organization-project-selector(
      v-if="organization && displayDataplantSelector"
      type="organization"
      :from-home="fromHome"
      :from-support="fromSupport"
      :from-services="fromServices"
      :from-organization="fromOrganization"
      :hover-from-parent="hoverSelector"
      @hover="hover"
    )
    organization-project-selector(
      v-if="organization && displayDataplantSelector && fromDataplant"
      type="dataplant"
      :from-dataplant="!displayFromDataplant"
      :hover-from-parent="hoverSelector"
      @hover="hover"
    )
    service-selector(
      v-if="displayFromDataplant"
      type="service"
      :service="getService"
      :hover-from-parent="hoverSelector"
      @hover="hover"
    )

    .profile-title(v-if="fromProfile")
      .logo.logo-organization
          a.home-link(:href="homeLink")
            img.ovh-logo(src="@/shared/assets/img/ovhcloud_logo_simplified_blue.png")
      .name-container
        span.name {{ $t('header.title_profile') }}
    .account-title(v-if="fromAccount")
      .logo.logo-organization
        a.home-link(:href="homeLink")
          img.ovh-logo(src="@/shared/assets/img/ovhcloud_logo_simplified_blue.png")
      .name-container
        span.name {{ $t('header.title_accounts') }}

  .right
    .actions
      button.button.button-lang
        .current {{ lang }}
        ul.dropdown
          li.lang(:class="{active:isActiveLang(l)}" @click="setLang(l)" v-for="l in locales") {{ l | translate }}
      button.button.button-profile
        i.fp4.fp4-gear
        ul.dropdown
          li.organization.organization-name(v-if="joinableOrganization")
            a.no-flex(:href="`#/organization/${joinableOrganization.id}`")
              .organization-container
                .organization-name {{ organization.name }}
                .dataplants-length(v-if="dataplantsByOrganizationId && dataplantsByOrganizationId.length") {{ dataplantsByOrganizationId.length }} {{ $t('header.project', [dataplantsByOrganizationId.length], dataplantsByOrganizationId.length) }}
                .dataplants-length(v-else) {{ $t('header.your_current_organization') }}
              .flex
                span {{ $t('header.my_organization') }}
          li.organization(v-if="joinableOrganization")
            a(:href="`#/organization/${joinableOrganization.id}/members`")
              span {{ $t('header.my_organization_members') }}
          li.organization(v-if="joinableOrganization")
            a(:href="`#/organization/${joinableOrganization.id}/member`")
              span {{ $t('header.my_organization_member') }}
          li.organization(v-if="joinableOrganization")
            a(:href="`#/organization/${joinableOrganization.id}/plan`")
              span {{ $t('header.my_organization_billing') }}
          li.organization(v-if="joinableOrganization")
            a(:href="`cc/#/${joinableOrganization.id}`")
              span {{ $t('header.control_center') }}
      button.button.button-help
        i.fp4.fp4-circle-info-small
        ul.dropdown
          li.docs
            a(:href="links.docs" target="blank" v-if="links.docs")
              i.fp4.fp4-book
              span {{ $t('header.docs') }}
          li.status(v-if="!isDataPlatform")
            a(:href="links.status" target="blank" v-if="links.status")
              i.fp4.fp4-heart-pulse
              span {{ $t('header.status') }}
          li.changelogs
            a(href="https://docs.forepaas.io/#/en/changelog" target="blank")
              i.fp4.fp4-arrows-rotate
              span {{ $t('header.changelog') }}
          li.roadmap(v-if="!isDataPlatform")
            a(href="#/features" target="blank")
              i.fp4.fp4-rocket
              span {{ $t('header.roadmap') }}
          li.support-link(v-if="joinableOrganization")
            a(:href="supportLink" target="blank")
              i.fp4.fp4-life-ring
              span {{ $t('header.support') }}
      button.button.button-user
        img.profile-picture(:src="picture")
        ul.dropdown
          li.profile-container
            a(:href="'#/user'")
              .profile-info(v-if="picture")
                img.profile-picture(:src="picture")
                span {{ user.firstname }} {{ user.lastname }}
              .profile-info(v-else)
                i.fp4.fp4-circle-user
                span {{ $t('header.my_profile') }}
          li.account
            a(href="#/accounts")
              span {{ $t('header.my_account') }}
          li.logout
            span(@click="logout") {{ $t('header.logout') }}
</template>

<script>
import moment from 'moment'
import Config from '@/shared/Config'
import OrganizationProjectSelector from '@/shared/components/app/Header/OrganizationProjectSelector.vue'
import ServiceSelector from '@/shared/components/app/Header/ServiceSelector.vue'
import SubscriptionStatus from './Subscription/Status.vue'
import ovhSupportUrl from '@/shared/store/constants/supportUrl'

export default {
  components: {
    OrganizationProjectSelector,
    SubscriptionStatus,
    ServiceSelector
  },
  props: {},
  data () {
    return {
      config: null,
      pageWithoutLogo: [
        { name: 'organization', title: 'header.title_organization', icon: 'fp4-organization-header-circle' },
        { name: 'changelog', title: 'header.title_changelog', icon: 'fp4-list' },
        { name: 'user', title: 'header.title_profile', icon: 'fp4-user-circles' },
        { name: 'accounts', title: 'header.title_accounts', icon: 'fp4-money-card-circle' },
        { name: 'support', title: 'header.title_support', icon: 'fp4-life-ring' },
        { name: 'services', title: 'header.title_services', icon: 'fp4-life-ring' },
        { name: 'infra', title: 'header.title_infra', image: require('@/core/components/Dataplant/assets/cc_dark_no_background.svg') },
        { name: 'qb', title: 'header.title_qb', image: require('@/core/components/Dataplant/assets/qb_dark_no_background.svg') },
        { name: 'app', title: 'header.title_app', image: require('@/core/components/Dataplant/assets/app_dark_no_background.svg') },
        { name: 'api', title: 'header.title_api', image: require('@/core/components/Dataplant/assets/api_dark_no_background.svg') }
      ],
      picture: require('@/core/assets/img/default_avatar.png'),
      hoverSelector: null
    }
  },
  computed: {
    isDataPlatform () {
      return this.config?.DATA_PLATFORM_ACCESS
    },
    user () {
      return this.$store.getters.SESSION
    },
    homeLink () {
      return this.organization ? `#/home/${this.organization._id}` : '#/home'
    },
    homeDataplantLink () {
      return this.dataplant ? `#/project/${this.dataplant.id}` : ''
    },
    locales () { return this.$i18n.locales() },
    lang () { return this.$i18n.locale().toUpperCase() },
    links () {
      return {
        docs: this.config?.DOCS,
        status: this.config?.STATUS
      }
    },
    dataplant () {
      const id = this.$route.params.dataplantId || this.$route.params.projectId
      if (id) return this.$store.getters.DATAPLANT_BY_ID(id)
      return null
    },
    dataplantName () {
      return this.dataplant?.name
    },
    organization () {
      return this.$store.getters.ACTIVE_ORGANIZATION(this.$route.params)
    },
    dataplantsByOrganizationId () {
      return this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organization._id) || []
    },
    joinableOrganization () {
      return this.requestToJoinPending ? this.$store.getters.ORGANIZATIONS.find(orga => !orga.pending_request) : this.organization
    },
    routeDetails () {
      return this.$route.path.split('/')
    },
    requestToJoinPending () {
      return this.organization?.pending_request
    },
    pageWithoutLogoDetails () {
      if (this.routeDetails.length < 2) return null
      if (this.$route.name === 'HQ Home Services') return this.pageWithoutLogo.find(p => p.name === 'services')
      return this.pageWithoutLogo.find(p => p.name === this.routeDetails[1])
    },
    displayDataplantSelector () {
      return !this.pageWithoutLogoDetails || this.fromOrganization || this.fromSupport || this.fromServices || this.displayFromDataplant
    },
    fromOrganization () {
      return this.pageWithoutLogoDetails?.name === 'organization'
    },
    fromServices () {
      return this.pageWithoutLogoDetails?.name === 'services'
    },
    fromHome () {
      return !!this.$route.params.organizationId
    },
    fromDataplant () {
      return !!this.$route.params.dataplantId || !!this.$route.params.projectId
    },
    fromSupport () {
      return this.pageWithoutLogoDetails?.name === 'support'
    },
    fromInfra () {
      return this.pageWithoutLogoDetails?.name === 'infra'
    },
    fromApi () {
      return this.pageWithoutLogoDetails?.name === 'api'
    },
    fromApp () {
      return this.pageWithoutLogoDetails?.name === 'app'
    },
    fromOldQB () {
      return this.pageWithoutLogoDetails?.name === 'qb'
    },
    fromProfile () {
      return this.pageWithoutLogoDetails?.name === 'user'
    },
    fromAccount () {
      return this.pageWithoutLogoDetails?.name === 'accounts'
    },
    fromTeammates () {
      return this.$route.name === 'HQ Home Teammates'
    },
    fromOrganizationHome () {
      return this.$route.name === 'HQ Home Organization'
    },
    displayFromDataplant () {
      return this.fromInfra || this.fromApi || this.fromApp || this.fromOldQB
    },
    getService () {
      if (!this.displayFromDataplant) return null
      return this.pageWithoutLogoDetails?.name
    },
    supportLink () {
      return this.config?.DATA_PLATFORM_ACCESS ? ovhSupportUrl : `#/support/${this.joinableOrganization._id}`
    }
  },
  watch: {
    organization: {
      handler (val, oldVal) {
        // We do not do it first time because it is done in the router
        if (oldVal && val && oldVal?.id !== val?.id) this.refreshDataplantByOrganizationId(val?.id)
      }
    }
  },
  created () {
    if (this.user.configuration?.logo) this.picture = this.user.configuration?.logo
  },
  async mounted () {
    moment.locale(this.lang)
    this.config = await Config()
  },
  methods: {
    setLang (lang) {
      this.$i18n.set(lang)
      moment.locale(lang)
      window.localStorage.setItem('hq_lang', lang)
    },
    isActiveLang (lang) {
      return lang === this.lang.toLowerCase()
    },
    refreshDataplantByOrganizationId (id) {
      if (this.fromOrganizationHome) return // Do not need to do it here because it is done on this page each 30 seconds

      try {
        this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', {
          organizationId: id,
          ignoreCache: true
        })
      } catch (err) {
        console.error(err)
      }
    },
    logout () {
      this.$store.commit('SET_OPEN_SIDE_BAR', false)
      this.$store.dispatch('LOGOUT_CMP')
    },
    openSidebar () {
      if (!this.$store.getters.OPEN_SIDE_BAR) {
        this.$analytics.track('Open main sidebar')
      } else {
        this.$analytics.track('Close main sidebar')
      }
      this.$store.commit('SET_OPEN_SIDE_BAR', !this.$store.getters.OPEN_SIDE_BAR)
    },
    hover (type) {
      this.hoverSelector = type
    }
  }
}
</script>

<style lang='less'>
// Style in src/shared/styles/header.less
</style>
