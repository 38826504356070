<template lang="pug">
.teammates
  fp-loader(v-if="loading")
  .content
    header
      h4 {{ $t('onboarding.welcome.title', [username]) }}
      span.description {{ $t('onboarding.welcome.description') }}

    .blocks
      .block.block-teammates.active
        .text
          h4 {{ $t('organization.teammates.found.title') }}
          .description(v-html="$sanitize($t('organization.teammates.found.description', [currentUserDomain]))")
        .organizations
          .organization(v-for="organization in teammatesOrganizations")
            .informations
              .organization-name {{ organization.name }}
              .organization-members {{ users(organization) }}
            .join
              fpui-button(
                v-if="!hasRequested(organization)"
                color="blue-flash"
                @click="requestToJoin(organization)"
              ) {{ $t('organization.teammates.request_to_join') }}
              .pending(v-else)
                i.fp4.fp4-circle-check
                span {{ $t('organization.teammates.request_sent') }}
        .continue
          fpui-button.not-join(
            v-if="!hasRequestedAtLeastOne.length"
            color="white"
            @click="next()"
            auto-width
          ) {{ $t('organization.teammates.not_join') }}
          fpui-button.done(
            v-else
            color="green"
            @click="next()"
            auto-width
          ) {{ $t('organization.teammates.done') }}
</template>

<script>
export default {
  data () {
    return {
      loading: true
    }
  },
  computed: {
    username () {
      return this.currentUser.firstname || this.currentUser.lastname || ''
    },
    organizations () {
      return this.$store.getters.ORGANIZATIONS
    },
    currentUser () {
      return this.$store.getters.SESSION
    },
    currentUserDomain () {
      const rgxp = /@(?<domain>.*)/
      const matches = rgxp.exec(this.currentUser.email)
      return matches.groups.domain
    },
    teammatesOrganizations () {
      return this.$store.getters.AVAILABLE_ORGANIZATIONS
    },
    hasRequestedAtLeastOne () {
      return this.teammatesOrganizations.filter(organization => this.hasRequested(organization))
    }
  },
  async mounted () {
    if (!this.$store.getters.AVAILABLE_ORGANIZATIONS.length) await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
    this.loading = false

    // Set organization active if at least one is requested
    // if (this.hasRequestedAtLeastOne.length) this.$store.commit('SET_ACTIVE_ORGANIZATION', this.hasRequestedAtLeastOne[0]._id)
  },
  methods: {
    next () {
      this.$router.push({ path: '/home', query: { bypassTeammates: true } })
    },
    users (organization) {
      const users = organization?.accounts || organization?.users || []
      const usersCount = users.length
      if (usersCount === 0) {
        return this.$t('organization.teammates.organization_members.single', ['Anonymous'])
      } else if (usersCount === 1) {
        const usr = users[0].user
        return this.$t('organization.teammates.organization_members.single', [`${usr.firstname} ${usr.lastname}`])
      } else {
        const firstUser = users[0].user
        if (usersCount > 2) return this.$t('organization.teammates.organization_members.count', [`${firstUser?.firstname} ${firstUser?.lastname}`, usersCount - 1])
        else return this.$t('organization.teammates.organization_members.count_single', [`${firstUser?.firstname} ${firstUser?.lastname}`])
      }
    },
    async requestToJoin (organization) {
      this.loading = true
      try {
        const req = await organization.addRequest()
        this.$fpuiMessageBlock.pop('success', req.message)

        this.$analytics.track('Request access to an organization')
      } catch (err) {
        this.$fpuiMessageBlock.pop('error', err.message)
      } finally {
        await this.$store.dispatch('LOAD_AVAILABLE_ORGANIZATIONS')
        this.loading = false

        await this.$store.dispatch('LOAD_ORGANIZATIONS', true)
        this.$store.commit('SET_ACTIVE_ORGANIZATION', organization._id)
      }
    },
    hasRequested (organization) {
      if (organization.pending_requests) {
        return organization.pending_requests.find((request) => request.account === this.currentUser._id || request.user === this.currentUser._id)
      }
      return false
    }
  }
}
</script>

<style lang="less">
.teammates {
    display: flex;
    justify-content: center;
    // height: 100%;
    width: 100%;
    text-align: initial;
    .content {
      box-shadow: unset;
      background: transparent;
      margin-top: 54px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;

      header {
        width: 526px;
        margin-bottom: 46px;
        text-align: center;
        h4 {
          font-size: 32px !important;
          font-weight: bold !important;
          letter-spacing: 0;
          line-height: 40px;
          color: #3E4550;
          margin-bottom: 21px;
        }
        span.description {
          font-size: 14px;
          line-height: 22px;
        }
      }

      .blocks {
        width: 1200px;
        margin-bottom: 121px;
        .block {
          background-color: white;
          padding: 41px 500px 40px 65px;
          box-shadow: 0 6px 12px 0 rgba(52, 65, 78, 0.2);
          border-radius: 5px;
          position: relative;
          margin-bottom: 20px;
          &.active {
            box-shadow: inset 0 2px 0 0 #9EDF10, 0 6px 12px 0 rgba(52,65,78,0.2);

            &.scaling {
              box-shadow: inset 0 2px 0 0 @blue_flash, 0 6px 12px 0 rgba(52, 65, 78, 0.2);
            }
          }
          .block-content {
            p.description {
              margin: 15px 0 19px 0;
              font-size: 14px;
              line-height: 22px;
              a {
                text-decoration: revert;
                font-size: 14px;
              }
            }
            .cta-button {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-content: center;
              align-items: center;
              .fpui-button {
                height: 38px;
                width: 230px;
              }
              .subtitle {
                padding-left: 15px;
                color: #B2BECA;
                font-size: 14px;
              }
            }
          }

          &.block-teammates {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 100%;
            width: 100%;
            align-content: center;
            align-items: center;
            padding: 0 65px 20px 65px;
            background-image: url('./assets/organization@2x.png');
            background-position: 50% 50px;
            background-repeat: no-repeat;
            background-size: 563.333333333px 221.866666667px;
            > .text {
              text-align: center;
              h4 {
                font-size: 32px !important;
                font-weight: bold !important;
                letter-spacing: 0;
                line-height: 40px;
                color: #3E4550;
                margin-bottom: 21px;
                margin-top: 255px !important;
              }
              .description {
                color: #3E4550;
                font-size: 14px;
                letter-spacing: 0;
                line-height: 23px;
                .domain {
                  color: @blue_flash;
                  font-weight: 600;
                }
              }
            }
            > .organizations {
              margin-top: 32px;
              > .organization {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 416px;
                height: 74px;
                box-shadow: 0 4px 20px 0 rgba(52, 65, 78, 0.08);
                border-radius: 7px;
                background-color: #FFF;
                padding: 16px 30px 19px 24px;
                &:not(:last-child) {
                  margin-bottom: 8px;
                }
                > .informations {
                  > .organization-name {
                    color: #3E4550;
                    font-size: 16px;
                    letter-spacing: 0;
                    line-height: 20px;
                  }
                  > .organization-members {
                    color: #B2BECA;
                    font-size: 14px;
                    letter-spacing: 0;
                    line-height: 18px;
                  }
                }
                > .join {
                  width: 124px;
                  .fpui-button {
                    height: 25px;
                    .btn-content {
                      font-size: 11px;
                      letter-spacing: 0;
                      line-height: 14px;
                    }
                  }
                  > .pending {
                    display: flex;
                    align-content: center;
                    i {
                      font-size: 16px;
                      color: #9EDF10;
                    }
                    span {
                      margin-left: 5px;
                      color: #97A7B7;
                      font-style: italic;
                      line-height: 15px;
                    }
                  }
                }
              }
            }
            > .continue {
              margin-top: 30px;
            }
          }
        }
      }
    }
  }
</style>
