<template lang="pug">
.kpi.dm(
  v-tooltip="$t('project.home.data-catalog.kpi_tooltip_right')"
)
  fp-loader(v-if="loading" type="chart")
  .value(
    v-if="!loading"
  ) {{ value }}
  .text(
    v-if="!loading"
  ) {{ $t('project.home.data-catalog.blueprint_rules') }}
</template>

<script>
export default {
  computed: {
    value () {
      return this.$store.getters.PROJECT_HOME_RULES?.length ?? '-'
    },
    loading () {
      return this.$store.getters.PROJECT_HOME_RULES_LOADING
    }
  },
  mounted () {
    if (!this.loading) {
      try {
        this.$store.dispatch('LOAD_RULES')
      } catch (err) {}
    }
  }
}
</script>

<style lang="less">

</style>
