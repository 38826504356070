<template lang="pug">
  .settings
    fp-title(
      :parent="$t('organization.settings')"
    )
      .actions-buttons
        fpui-button.delete-organization(
          v-if='isLastAdmin'
          color='red'
          icon="fp4 fp4-trash-can"
          icon-left
          @click="deleteOrganization"
          :disabled="isActiveSubscription"
          v-tooltip="isActiveSubscription ? $t('organization.settings.button.delete_organization.tooltip_disabled') : ''"
          auto-width
          noshadow
          reverse
        ) {{ $t('organization.settings.button.delete_organization') }}
        fpui-button.leave-organization(
          v-if='!isLastAdmin'
          color='red'
          icon="fp4 fp4-up-from-line"
          icon-left
          @click="leaveOrganization"
          auto-width
          noshadow
          reverse
        ) {{ $t('organization.settings.button.leave_organization') }}
    fpui-tabs.settings-tabs(
      :default-active="tabActive"
    )
      fpui-tab(:header="$t('organization.settings.informations')")
        informations(
          :organization="organization"
          v-if="organization"
        )
      fpui-tab(:header="$t('organization.settings.infrastructure')")
        infrastructure(
          :organization="organization"
          v-if="organization"
        )

</template>

<script>
import Informations from './Informations'
import Infrastructure from './Infrastructure/Infrastructure'
import OrganizationDeletionModal from './OrganizationDeletionModal'
import ConfirmModal from '@/shared/components/modals/Confirm'

export default {
  components: {
    Informations,
    Infrastructure
  },
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      tabActive: null
    }
  },
  computed: {
    isActiveSubscription () {
      if (!this.organization?.subscription) return false
      if (this.organization?.subscription?.id === 'OFFLINE_SUBSCRIPTION') return false

      return ['future', 'in_trial', 'active', 'paused', 'non_renewing'].includes(this.organization?.subscription?.status)
    },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    users () {
      return this.organization.accounts || this.organization.users || []
    },
    session () {
      return this.$store.getters.SESSION
    },
    isAdmin () {
      return this.organization.users.find(usr => usr.email === this.session.email).role === 'admin'
    },
    isLastAdmin () {
      if (!this.isAdmin) return false
      const adminList = this.users.filter(ad => ad.role === 'admin')
      return adminList.length === 1
    }
  },
  created () {
    if (this.$route.query?.tabActive === 'infrastructure') {
      this.tabActive = 1
    }
  },
  mounted () {
    this.$store.commit('SET_OPEN_SIDE_BAR', false)
  },
  methods: {
    async leaveOrganization (callback) {
      const confirmResult = await ConfirmModal(this, {
        contentHtml: true,
        title: this.$t('organization.settings.delete_organization.title', [this.organization.name]),
        yes: {
          text: this.$t('fpui-modal-confirm.yes'),
          color: 'red'
        }
      },
      {
        height: 'auto',
        width: 550
      })
      if (!confirmResult) return
      try {
        await this.organization.removeUser(this.session.email)
        this.$store.commit('REMOVE_ORGANIZATION', this.organization._id)
        this.$router.push('/home')
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
    },
    deleteOrganization () {
      if (!this.isAdmin || this.isActiveSubscription) return
      this.$modal.show(OrganizationDeletionModal, {
        organization: this.organization
      }, {
        height: 'auto',
        width: '500px'
      })
    }
  }
}


</script>

<style lang="less">
  .settings {
    height: 100%;
    background-color: white;
    overflow: hidden;
    .leave-organization {
      .fpui-button {
        i {
          transform: rotate(90deg);
        }
      }
    }
    .fpui-tabs {
      height: calc(~"100% - 84px");
      .fpui-tabs-contents {
        height: calc(~"100% - 29px");
        background: @background-color;
        box-shadow: none;
        overflow: auto;
      }
    }
  }
</style>
