<template lang="pug">
.second-table.control-center(
  v-if="activeAlerts.length < 8"
)
  fpui-table(
    :columns='columns'
    :actions='actions'
    :data='items'
    :loading="loading"
    :height="!activeAlerts.length ? '196px' : `${290 - (activeAlerts.length + 1) * 30}px`"
    :pagination="{ disabled: true }"
  )
</template>

<script>
import Config from '@/shared/Config'

export default {
  props: {
    fromLarge: { type: Boolean, default: false }
  },
  data () {
    return {
      config: null
    }
  },
  computed: {
    activeAlerts () {
      return this.$store.getters.PROJECT_HOME_ACTIVE_ALERTS?.filter(a => a.status === 'active') || []
    },
    loading () {
      return this.$store.getters.PROJECT_HOME_ACTIVE_ALERTS_LOADING
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.$route.params.projectId)
    },
    items () {
      if (!this.canCcMonitoringRead) return []

      const panels = []

      // List of monitoring pannels in the table of the monitoring tab in the sidetab
      if (this.project.services.includes('dpe')) panels.push({ id: 'dpe', name: 'Data Processing Engine (Infrastructure Monitoring)' })
      if (this.project.services.includes('am')) panels.push({ id: 'am', name: 'Analytics Manager (Infrastructure Monitoring)' })
      if (this.project.services.includes('mlm')) panels.push({ id: 'ml', name: 'Machine Learning Manager (Infrastructure Monitoring)' })
      if (this.project.services.includes('appservice')) {
        panels.push({ id: 'api', name: 'API Manager (Infrastructure Monitoring)' })
        panels.push({ id: 'app', name: 'APP Manager (Infrastructure Monitoring)' })
      }
      panels.push({ id: 'dataplant', name: 'Project Overview' })
      panels.push({ id: 'storageEngine', name: 'Storage Engines Overview' })

      return panels
    },
    actions () {
      return []
    },
    columns () {
      const columns = [
        {
          name: 'name',
          label: () => {
            const href = `cc/#/${this.config?.ORGANIZATION_ID}/${this.config?.PROJECT_ID}/monitoring_panels`
            return `<a class='cell-link' href="${href}">${this.$t('project.home.cc.monitoring_panels')}</a>`
          },
          target: row => {
            let href = ''
            if (row.id === 'storageEngine') {
              href = `cc/#/${this.config?.ORGANIZATION_ID}/monitoring_panel/${row.id}?shortcut=last6Hours`
            }
            href = `cc/#/${this.config?.ORGANIZATION_ID}/${this.config?.PROJECT_ID}/monitoring_panel/${row.id}?shortcut=last6Hours`
            return `<a class='cell-link' href="${href}">${row.name}</a>`
          },
          paddingLeft: 24
        }
      ]

      return columns
    },
    canCcMonitoringRead () {
      return this.$store.getters.ACL('canCcMonitoringRead')
    }
  },
  async mounted () {
    this.config = await Config()
  }
}
</script>

<style lang="less">
.second-table.control-center {
  margin-top: 0 !important;
  margin-bottom: 8px;
  height: auto !important;
}
</style>
