<template lang="pug">
.kpi.gab(
  v-tooltip="$t('project.home.gab.kpi_tooltip_left')"
)
  fp-loader(v-if="loading" type="chart")
  .value(
    v-if="!loading"
  ) {{ value }}
  .text(
    v-if="!loading"
  ) {{ $t('project.home.gab.assistants', [value], isNaN(value) ? 0 : value) }}
</template>

<script>
export default {
  computed: {
    value () {
      return this.$store.getters.PROJECT_HOME_ASSISTANTS?.length ?? '-'
    },
    loading () {
      return this.$store.getters.PROJECT_HOME_ASSISTANTS_LOADING
    }
  }
}
</script>

<style lang="less">

</style>
