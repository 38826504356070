<template lang="pug">
  .subscriptions
    .subscription-entity(v-if="computedSubscriptions.length" v-for="subscription in computedSubscriptions")
      .img-wrapper(:style="{'background': subscription.color}")
        i.fp4.fp4-organization
      .info
        .name {{ subscription.organization_name }}
        .date {{ subscription.createdAt }}
        fpui-button.link(
          color="white",
          @click="managePlan(subscription)",
          auto-width
        ) {{ $t('accounts.subscriptions.manage_plan') }}

    .subscription-entity(v-if="!computedSubscriptions.length")
      .img-wrapper(:style="{'background': 'black'}")
        i.fp4.fp4-organization
      .info {{ $t('accounts.subscriptions.no_subscriptions') }}
</template>
<script>
import moment from 'moment'

export default {
  props: {
    accountId: { type: String, default: () => { '' } }
  },
  data () {
    return {
      subscriptions: []
    }
  },
  computed: {
    computedSubscriptions () {
      const result = this.subscriptions.filter(sub => !sub.deleted_at && sub.type === 'Plan')
      result.forEach(sub => {
        sub.color = this.getRandomColor()
        sub.createdAt = moment(sub.createdAt).format(this.$t('date.formatter.short'))
      })
      return result
    }
  },
  async mounted () {
    try {
      const account = this.$store.getters.ACCOUNT_BY_ID(this.accountId)
      this.subscriptions = await account.getSubscriptions()
    } catch (err) {
      this.$fpuiMessageBlock.error(err)
    }
  },
  methods: {
    managePlan (subscription) {
      this.$router.push(`/organization/${subscription.organization_id}/plan`)
    },
    getRandomColor () {
      const letters = '0123456789ABCDEF'
      let color = '#'
      for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)]
      }
      return color
    }
  }
}
</script>
<style lang="less">
  .subscriptions {
    display: flex;
    justify-content: center;
    max-width: 1228px;
    flex-wrap: wrap;
    overflow: auto;
    .subscription-entity {
      margin: 0;
      margin-right: 51px;
      margin-bottom: 30px;
      padding: 0;
      width: 321px;
      height: 105px;
      overflow: hidden;
      box-shadow: 0 2px 4px 0 rgba(52,65,78,0.1);
      font-weight: 400;
      border-radius: 5px;
      background-color:@white;
      display: flex;
      .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 105px;
        height: 100%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        position: relative;
        i {
          color: @white;
          font-size: 80px;
        }
      }
      .info {
        padding-right: 0;
        padding-left: 18px;
        position: relative;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-content: center;
        justify-content: center;
        .name {
          font-size: 16px;
          line-height: 20px;
          color: @text-color;
          text-transform: capitalize;
        }
        .date {
          font-size: 14px;
          color: @grey;
        }
        .link {
          margin-top: 10px;
        }
      }
    }
  }
</style>
