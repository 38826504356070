import LakehouseLeftButton from './LakehouseLeftButton.vue'
import LakehouseRightButton from './LakehouseRightButton.vue'
import LakehouseMainTable from './LakehouseMainTable.vue'
import LakehouseSecondTable from './LakehouseSecondTable.vue'
import LakehouseLeftKpi from './LakehouseLeftKpi.vue'
import LakehouseRightKpi from './LakehouseRightKpi.vue'

export default {
  LakehouseLeftButton,
  LakehouseRightButton,
  LakehouseMainTable,
  LakehouseSecondTable,
  LakehouseLeftKpi,
  LakehouseRightKpi
}

export {
  LakehouseLeftButton,
  LakehouseRightButton,
  LakehouseMainTable,
  LakehouseSecondTable,
  LakehouseLeftKpi,
  LakehouseRightKpi
}
