<template lang="pug">
div(
  ref="item"
  class="vue-grid-layout"
  :style="mergedStyle"
)
  slot
  dashboard-item.service-item-placeholder(
    v-show="isDragging"
    class="vue-grid-placeholder"
    :x="placeholder.x"
    :y="placeholder.y"
    :w="placeholder.w"
    :h="placeholder.h"
    :i="placeholder.i"
  )
</template>

<script>
import Vue from 'vue'

import DashboardItem from './DashboardItem.vue'
import { addWindowEventListener, removeWindowEventListener } from './helpers/DOM'
const elementResizeDetectorMaker = require('element-resize-detector')

export default {
  name: 'DashboardLayout',
  components: {
    DashboardItem
  },
  provide () {
    return {
      eventBus: null,
      layout: this
    }
  },
  props: {
    colNum: { type: Number, default: 12 },
    rowHeight: { type: Number, default: 150 },
    maxRows: { type: Number, default: Infinity },
    margin: { type: Array, default: () => [10, 10] },
    isDraggable: { type: Boolean, default: true },
    isResizable: { type: Boolean, default: true },
    isMirrored: { type: Boolean, default: false },
    isBounded: { type: Boolean, default: false },
    useCssTransforms: { type: Boolean, default: true },
    horizontalCompact: { type: Boolean, default: true },
    restoreOnDrag: { type: Boolean, default: false },
    layout: { type: Object, required: true },
    responsive: { type: Boolean, default: false },
    responsiveLayouts: { type: Object, default: () => ({}) },
    transformScale: { type: Number, default: 1 },
    breakpoints: { type: Object, default: function () { return { lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 } } },
    cols: { type: Object, default: function () { return { lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 } } },
    preventCollision: { type: Boolean, default: false },
    useStyleCursor: { type: Boolean, default: true },
    minW: { type: Number, required: false, default: 1 },
    minH: { type: Number, required: false, default: 1 },
    editing: { type: Boolean, default: false },
    lite: { type: Boolean, default: false }
  },
  data: function () {
    return {
      width: null,
      lastLayoutLength: 0,
      isDragging: false,
      placeholder: {
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        i: -1
      },
      lastBreakpoint: null // store last active breakpoint
    }
  },
  computed: {
    mergedStyle () {
      return {
        height: this.editing ? `${this.layout.layoutHeight + 4 * this.rowHeight + 60}px` : `${this.layout.layoutHeight + 60}px`,
        width: '100%',
        'min-width': `${4 * 280 + 5 * 32}px`,
        'max-width': `${4 * 460 + 5 * 32}px`,
        margin: 'auto'
      }
    }
  },
  watch: {
    width: function (newval, oldval) {
      const self = this
      this.$nextTick(function () {
        // this.$broadcast("updateWidth", this.width);
        this.eventBus.$emit('updateWidth', this.width)
        if (oldval === null) {
          /*
            If oldval == null is when the width has never been
            set before. That only occurs when mouting is
            finished, and onWindowResize has been called and
            this.width has been changed the first time after it
            got set to null in the constructor. It is now time
            to issue layout-ready events as the GridItems have
            their sizes configured properly.

            The reason for emitting the layout-ready events on
            the next tick is to allow for the newly-emitted
            updateWidth event (above) to have reached the
            children GridItem-s and had their effect, so we're
            sure that they have the final size before we emit
            layout-ready (for this GridLayout) and
            item-layout-ready (for the GridItem-s).

            This way any client event handlers can reliably
            invistigate stable sizes of GridItem-s.
          */
          this.$nextTick(() => {
            this.$emit('layout-ready', self.layout.tiles)
          })
        }
      })
    },
    colNum: function (val) {
      this.eventBus.$emit('setColNum', val)
    },
    rowHeight: function () {
      this.eventBus.$emit('setRowHeight', this.rowHeight)
    },
    isDraggable: function () {
      this.eventBus.$emit('setDraggable', this.isDraggable)
    },
    isResizable: function () {
      this.eventBus.$emit('setResizable', this.isResizable)
    },
    isBounded: function () {
      this.eventBus.$emit('setBounded', this.isBounded)
    },
    transformScale: function () {
      this.eventBus.$emit('setTransformScale', this.transformScale)
    },
    responsive () {
      if (!this.responsive) {
        this.$emit('update:layout', this.originalTiles)
        this.eventBus.$emit('setColNum', this.colNum)
      }
      this.onWindowResize()
    },
    maxRows: function () {
      this.eventBus.$emit('setMaxRows', this.maxRows)
    }
  },
  created () {
    const self = this

    // Accessible refernces of functions for removing in beforeDestroy
    self.resizeEventHandler = function (eventType, i, x, y, h, w) {
      self.resizeEvent(eventType, i, x, y, h, w)
    }

    self.dragEventHandler = function (eventType, i, x, y, h, w) {
      self.dragEvent(eventType, i, x, y, h, w)
    }

    self._provided.eventBus = new Vue()
    self.eventBus = self._provided.eventBus
    self.eventBus.$on('resizeEvent', self.resizeEventHandler)
    self.eventBus.$on('dragEvent', self.dragEventHandler)
    self.$emit('layout-created', self.layout.tiles)
  },
  beforeDestroy: function () {
    // Remove listeners
    this.eventBus.$off('resizeEvent', this.resizeEventHandler)
    this.eventBus.$off('dragEvent', this.dragEventHandler)
    this.eventBus.$destroy()
    removeWindowEventListener('resize', this.onWindowResize)
    if (this.erd) {
      this.erd.uninstall(this.$refs.item)
    }
  },
  beforeMount: function () {
    this.$emit('layout-before-mount', this.layout.tiles)
  },
  mounted: function () {
    this.$emit('layout-mounted', this.layout.tiles)
    this.$nextTick(function () {
      const self = this
      this.$nextTick(function () {
        // self.initResponsiveFeatures()
        self.onWindowResize()

        addWindowEventListener('resize', self.onWindowResize)

        self.$nextTick(function () {
          this.erd = elementResizeDetectorMaker({
            strategy: 'scroll', // <- For ultra performance.
            // See https://github.com/wnr/element-resize-detector/issues/110 about callOnAdd.
            callOnAdd: false
          })
          this.erd.listenTo(self.$refs.item, function () {
            self.onWindowResize()
          })
        })
      })
    })
  },
  methods: {
    onWindowResize: function () {
      if (this.$refs !== null && this.$refs.item !== null && this.$refs.item !== undefined) {
        if (this.width !== this.$refs.item.offsetWidth) {
          this.width = this.$refs.item.offsetWidth
        }
      }
    },
    dragEvent: function (eventName, id, x, y, h, w) {
      let tile = this.layout.getLayoutTile(id)
      // getLayoutTile sometimes returns null object
      if (tile === undefined || tile === null) {
        tile = { x: 0, y: 0 }
      }

      if (eventName === 'dragstart') {
        // Si besoin
        if (tile.type === 'category') this.layout.hideTileType('service')
      }

      if (eventName === 'dragmove' || eventName === 'dragstart') {
        this.placeholder.i = id
        this.placeholder.x = tile.x
        this.placeholder.y = tile.y
        this.placeholder.w = tile.width
        this.placeholder.h = tile.height
        tile.moving = true
        this.$nextTick(function () {
          this.isDragging = true
        })
        this.eventBus.$emit('updateWidth', this.width)
      } else {
        this.$nextTick(function () {
          this.isDragging = false
        })
      }

      // Move the element to the dragged location.
      this.layout.moveElement(tile, x, y, true, false, tile.type)

      // Restore position of tile if space on top of them
      if (tile.type === 'category') this.layout.compact(this.layout.originalTilesObject, ['category'])
      if (tile.type === 'service') this.layout.compact(this.layout.originalTilesObject, ['service', 'category'], tile, tile)

      // needed because vue can't detect changes on array element properties
      this.eventBus.$emit('compact')
      if (eventName === 'dragend') {
        if (tile.type === 'service' && this.layout.tileIsInAnotherCategory(tile)) {
          this.layout.restoreTiles()
        }
        if (tile.type === 'category') this.layout.displayTileType('service')
        this.layout.updateTile(tile.i, 'height', tile.height)
        delete tile.moving
        if (this.layout.hasChanges) {
          this.layout.updateOriginalTiles()
          this.$emit('layout-updated')
        }
      }
    }
  }
}
</script>

<style lang="less">
  .vue-grid-layout {
    position: relative;
    transition: height 200ms ease;
  }
</style>
