<template lang="pug">
.contact
  .fp-container(style="max-width:700px")
    .fp-box
      .box-title {{ $t('accounts.owner') }}
      .label {{ $t('accounts.account_owner') }}
      .account-owner-container
        img(:src="picture")
        fpui-input-text(v-model="fullName", disabled)
        fpui-button(color="white", @click="change", auto-width) {{ $t('accounts.change') }}
      .box-line(v-if="account.future_owner_id")
        fpui-input-email(
          :label="$t('accounts.future_account_owner')",
          disabled,
          :value="account.future_owner_id.email"
        )
      .box-line
        fpui-input-email(
          :label="$t('accounts.email')",
          disabled,
          :value="account.email"
        )
      .box-line
        fpui-input-text(
          :label="$t('accounts.company')",
          :value="account.company",
          @input="(value) => update('company', value)"
        )
      .box-line
        .label {{ $t('accounts.phone') }}
        fpui-input-phone(
          :value="account.phone",
          @input="(value) => update('phone', value)",
          :preferedCountries="['fr', 'us']"
        )
      .box-line
        fpui-input-text(
          :label="$t('accounts.line1')",
          :value="account.line1",
          @input="(value) => update('line1', value)"
        )
      .box-line
        fpui-input-text(
          :label="$t('accounts.line2')",
          :value="account.line2",
          @input="(value) => update('line2', value)"
        )
      .box-line
        .line3-container
          fpui-input-text(
            :label="$t('accounts.zip')",
            :value="account.zip",
            @input="(value) => update('zip', value)"
          )
          fpui-input-text(
            :label="$t('accounts.city')",
            :value="account.city",
            @input="(value) => update('city', value)"
          )
      .box-line
        fpui-input-country(
          :label="$t('accounts.country')",
          :value="account.country",
          @input="(value) => update('country', value)",
        )
      .box-line
        fpui-input-state(
          :country="account.country"
          :label="$t('accounts.state')",
          :value="account.state",
          @input="(value) => update('state', value)",
        )
</template>
<script>
import ChangeOwnerModal from './ChangeOwnerModal'
export default {
  props: {
    account: { type: Object, required: true },
    contactChanged: { type: Boolean, default: () => {} }
  },
  data () {
    return {}
  },
  computed: {
    fullName () {
      return this.account.first_name + ' ' + this.account.last_name
    },
    picture () {
      return this.account.picture || require('@/core/assets/img/man_1@2x.png')
    }
  },
  methods: {
    update (key, value) {
      if (Object.keys(this.account).length > 0) {
        this.account.update(key, value, false)
        this.$emit('update:contactChanged', true)
      }
    },
    change () {
      this.$modal.show(ChangeOwnerModal, {
        account: this.account,
        onSubmit: () => this.$fpuiMessageBlock.pop('success', this.$t('accounts.future_account_owner.confirm'))
      }, {
        height: 'auto',
        width: 538
      })
    }
  }
}
</script>
<style lang="less">
.contact {
  width: 100%;
  .fp-box {
    padding-bottom: 20px;
  }
  .box-line {
    margin-top: 15px;
  }
  .account-owner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 30px;
    }
    .fpui-input-text {
      flex:1;
      margin: 0 5px;
    }
  }
  .fpui-input-phone {
    width: 100%;
    padding: 0;
  }
  .line3-container {
    display: flex;
    justify-content: space-between;
    margin: 0 -5px;
    > .fpui-input-text {
      flex: 1;
      margin: 0 5px;
    }
  }
}
</style>
