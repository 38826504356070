<template lang="pug">
.kpi.iam(
  v-tooltip="$t('project.home.iam.kpi_tooltip_left')"
)
  fp-loader(v-if="loading" type="chart")
  .value(
    v-if="!loading"
  ) {{ value }}
  .text(
    v-if="!loading"
  ) {{ $t('project.home.iam.users', [value], isNaN(value) ? 0 : value) }}
</template>

<script>
export default {
  data () {
    return {
      value: '-',
      loading: false
    }
  },
  computed: {
    projectId () {
      return this.$route.params?.projectId
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    serviceReady () {
      return !Object.keys(this.project?.display_settings?.services_wizard || {}).includes('iam')
    },
    canIamUserRead () {
      return this.$store.getters.ACL('canIamUserRead')
    }
  },
  watch: {
    serviceReady: {
      handler (val) {
        if (val) this.load()
      }
    },
    '$store.getters.PROJECT_CARD_INFO_LOADING': {
      handler (val) {
        if (!val) this.load()
      }
    }
  },
  async mounted () {
    if (!this.$store.getters.PROJECT_CARD_INFO_LOADING) await this.load()
  },
  methods: {
    async load () {
      if (this.canIamUserRead && this.serviceReady) {
        this.loading = true

        try {
          let users = []
          let finish = false
          users = await this.$api.IAM.users.list({
            onProgress: (items) => {
              users = items
              if (!finish) {
                finish = true
              }
            }
          })

          this.value = users?.length ?? '-'
        } catch (err) {
          this.value = '-'
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="less">

</style>
