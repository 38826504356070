<template lang="pug">
  .ticket-editor
    fp-title(
      v-if="item"
      :value="item.subject"
      :disableEdit="true"
      :parent="$t('tickets.header')"
      @parent="$router.push(`/support/${organizationId}`)"
      :border="true"
    )
      .actions-buttons.header-right(:key="item.__v")
        div(v-if="item.id")
          .status
            fp-loading(v-if="item.saving")
            span.left {{'tickets.closed' | translate }}
            span.content
              fpui-input-toggle(
                v-model="status"
                :width="40"
                :colors="{checked: '#FFCD2F', unchecked: 'rgba(151,167,183,0.5)'}"
              )
            span.right {{'tickets.open' | translate }}
          .description
            .contacts
              .new.contact(@click="addContact")
                i.fp4.fp4-plus
              .icon(v-for="contact in item.contacts",v-tooltip="!contact.name?contact.email:`${contact.name} (${contact.email})`")
                fpui-canvas-icon(
                  :name="contact.name||'@'"
                  fontSize="15px"
                  :size="28"
                  type="circle"
                  :logo="getPictureEmail(contact.email)"
                  :letterNumber=2
                  :background-color="colors(contact.email)"
                  color="#ffffff"
                )
            .text
              span.dataplant(v-if="item.meta.dataplant_name") {{item.meta.dataplant_name}} |&nbsp;
              span.replies {{ messages.length }} {{'tickets.replies'|translate}} |&nbsp;
              span.created_at {{ 'tickets.opened' | translate }} {{ item.duration }}

    fp-loader(v-if="loading")
    .fp-page(v-if="item && !loading")
      multipane(layout="horizontal")
        .content-block(:class="{'created':item.id}",v-sticky)
          .fp-container(v-if="item.id")
            .fp-column
              .messages
                .message.left
                  .icon(v-tooltip="!item.author.name?item.author.email:`${item.author.name} (${item.author.email})`")
                    fpui-canvas-icon(
                      :name="item.author.name||'@'"
                      fontSize="15px"
                      :size="56"
                      :logo="getPictureEmail(item.author.email)"
                      :letterNumber=2
                      :background-color="colors(item.author.email)"
                      color="#ffffff"
                    )
                  .content
                    .description {{item.author.name}} | {{item.created_at*1000 | date('utc','locale',$t('date.formatter')) }}
                    .subject {{item.subject}}
                    .inner-content(v-html="item.body")
                    .divider(v-if="item.attachments && item.attachments.length>0") Attachments
                    .attachments
                      attachment.attachment(v-for="(attachment, index) in item.attachments" :attachment="attachment" :key="`attachment_body_${index}`")
                .message(v-for="(message,$index) in messages",:class="{'left': true,'right': false}")
                  .icon
                    fpui-canvas-icon(
                      :name="message.author.name||'@'"
                      :size="56"
                      fontSize="15px"
                      :logo="getPictureEmail(message.author.email)"
                      :letterNumber=2
                      :background-color="colors(message.author.email)"
                      color="#ffffff"
                      v-tooltip="!message.author.name?message.author.email:`${message.author.name} (${message.author.email})`"
                    )
                    .admin(v-if="message.author.admin")
                  .content
                    .description {{message.author.name}} | {{message.created_at*1000 | date('utc','locale',$t('date.formatter')) }}
                    .inner-content(v-html="message.body")
                    .divider(v-if="message.attachments && message.attachments.length>0") Attachments
                    .attachments
                      attachment.attachment(v-for="(attachment, index) in message.attachments" :attachment="attachment" :key="`attachment_${$index}_${index}`")
        multipane-resizer
        .bottom-content(:class="{'create':!item.id}")
          .create-block(v-if="!item.id")
            .line
              fpui-input-text.subject(
                :value="item.subject"
                :placeholder="$t('tickets.subject.placeholder')"
                @input="item.update('subject', $event, false)"
                :label="$t('tickets.subject')"
              )
              fpui-input-select.dataplant(
                :label="$t('tickets.dataplant')"
                v-model="dataplant"
                :placeholder="$t('tickets.dataplant.placeholder')"
                :options="dataplants"
              )
            .line
              fpui-input-tags(
                :label="$t('tickets.contacts')"
                :placeholder="$t('tickets.contacts.placeholder')"
                :autocomplete="organizationsEmails.map(item=>({text:item}))"
                :value="item.emails"
                @input="item.update('contacts', $event, false)"
                :validation="emailsValidations"
              )
          .message-block(:class="{'created':item.id}")
            fpui-input-textarea(
              :disabled="item.saving"
              :placeholder="$t('tickets.body.placeholder')"
              @keydown="onKeyDown"
              :label="$t('tickets.body')"
              v-model="message"
            )

            .dropzone-content
              .dropzone-box(
                :class="{'attachments-here':attachments.length}"
                v-dropzone="dropzoneOptions"
              ) {{ 'tickets.dropfilehere' | translate }}
                span.extensions {{ 'tickets.dropfilehere.extensions.allowed' | translate([mappedAllowedExtensions]) }}
                input(
                  type="file"
                  @change="onDrop($event.target.files)"
                  multiple
                )
              .attachments(v-if="attachments.length")
                .attachment-content
                  .attachment(v-for="(attachment, $index) in attachments")
                    .name
                      i.fpui-attachment
                      span.name {{attachment.name}}
                    span.size {{attachment.size | bytes }}
                    i.fp4.fp4-trash-can(@click="attachments.splice($index,1)")

            .pull-right.buttons
              fp-loading(v-if="item.saving")
              fpui-button(
                :disabled="!message||item.saving||(!item.id&&!item.subject)"
                :color="item.id?'blue-flash':'green'"
                @click="createOrReply"
              ) {{ item.id?'button.send':'button.create' | translate }}
</template>

<script>
import emailValidator from 'email-validator'
import AddContactModal from './AddContactModal.vue'
import Attachment from './Attachment.vue'
import { Multipane, MultipaneResizer } from '@/shared/components/multipane'
import Config from '@/shared/Config'

export default {
  components: { Multipane, MultipaneResizer, Attachment },
  directives: {
    sticky: {
      bind (el) {
        let sticky = true
        el.addEventListener('scroll', (event) => {
          sticky = el.scrollTop > el.scrollHeight - el.offsetHeight - 10
        })
        const observer = new MutationObserver(function scrollToBottom () {
          if (sticky) {
            el.scrollTop = el.scrollHeight - el.offsetHeight
          }
        })
        observer.observe(el, { childList: true, subtree: true })
        process.nextTick(_ => {
          el.scrollTop = el.scrollHeight - el.offsetHeight
        })
      }
    }
  },
  props: {
    ticketId: {
      type: String,
      default: null
    },
    organizationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      config: {},
      loading: true,
      message: '',
      dataplant: null,
      newItem: this.$api.SUPPORT.new(this.$route.query),
      colorsSet: {},
      attachments: []
    }
  },

  computed: {
    allowedExtensions () {
      return [
        'json', 'js', 'jsx', 'pptx', 'py', 'xlsx', 'zip',
        'pdf', 'png', 'jpg', 'gif', 'txt', 'mp4', 'mov',
        'sql', 'csv', 'xls', 'jpeg'
      ]
    },
    mappedAllowedExtensions () {
      return this.allowedExtensions.map((ext, idx) => {
        if (idx !== 0) return ` .${ext}`
        return `.${ext}`
      })
    },
    item () {
      return this.ticketId ? this.$store.getters.SUPPORT_TICKET_BY_ID(this.ticketId) : this.newItem
    },
    dropzoneOptions () {
      return {
        onDrop: this.onDrop
      }
    },
    status: {
      get () { return this.item.state === 'open' },
      set (value) {
        this.$store.dispatch('UNWATCH_TICKET', this.item)
        const state = value ? 'open' : 'closed'
        this.item.update('state', state, true)
        if (value) this.$store.dispatch('WATCH_TICKET', this.item)
      }
    },
    dataplants () {
      const dataplants = this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId)
      if (!dataplants) return []
      return dataplants.map(dp => ({
        label: dp.name,
        value: dp.dataplant_id
      }))
    },
    emailsValidations () {
      return [{
        classes: 'email-validation-rule',
        rule: ({ text }) => !emailValidator.validate(text)
      }]
    },
    messages () {
      return this.item?.messages || []
    },
    organizationsEmails () {
      const emails = this.$store.getters.ORGANIZATIONS_EMAILS || []
      return emails
    }
  },
  async mounted () {
    this.config = await Config()
    if (!this.$store.getters.SUPPORT_TICKETS.length) {
      this.loading = true
      await this.$store.dispatch('LOAD_TICKETS')
    }
    if (!this.item?.id && this.$route.path !== `/support/${this.organizationId}/ticket`) {
      this.$router.push(`/support/${this.organizationId}`)
    }
    if (this.item?.id) {
      await this.item.load()
      if (this.item.state === 'open') this.$store.dispatch('WATCH_TICKET', this.item)
    }
    this.loading = false
  },
  destroyed () {
    this.$store.dispatch('UNWATCH_TICKET', this.item)
  },
  methods: {
    async onDrop (files) {
      this.attachments = this.attachments.concat(await Promise.all(Object.values(files).map(async file => {
        const ext = file.name.split('.').pop()
        if (!this.allowedExtensions.includes(ext)) {
          this.$fpuiMessageBlock.error(new Error(`Extension not allowed : ${ext}`))
          return false
        }
        const reader = new FileReader()
        reader.readAsDataURL(file)
        return new Promise((resolve, reject) => {
          reader.onload = (evt) => {
            resolve({
              name: file.name,
              size: file.size,
              content: evt.target.result
            })
          }
        })
      }))).filter(at => at)
      if (this.attachments.length > 5) {
        this.attachments = this.attachments.splice(0, 5)
        this.$fpuiMessageBlock.error(new Error(this.$t('tickets.error.max_files')))
      }
    },
    onKeyDown ($event) {
      if ($event.key === 'Enter' && ($event.ctrlKey || $event.metaKey) && this.message) {
        this.createOrReply()
      }
    },
    colors (email) {
      const colors = ['#3E4550', '#0089C0', '#F62172', '#9EDF10']
      this.colorsSet[email] = this.colorsSet[email] || colors[Object.keys(this.colorsSet).length % colors.length]
      return this.colorsSet[email]
    },
    addContact () {
      const _this = this
      this.$modal.show(AddContactModal, {
        async onAdd (emails) {
          try {
            this.loading = true
            await _this.item.addContacts(emails)
            this.$emit('close')
          } catch (err) {
            console.error(err)
            this.$fpuiMessageBlock.error(err)
            this.loading = false
          }
        },
        emailsValidations: this.emailsValidations
      }, {
        width: 980,
        height: 230
      })
    },
    getPictureEmail (email) {
      return `${this.config.KING}/v1/files/users/${email}/picture.jpeg`
    },
    async createOrReply () {
      this.$store.dispatch('UNWATCH_TICKET', this.item)
      try {
        if (this.item?.id) {
          this.item.__saving = true
          await this.item.reply(this.message, this.attachments)
          this.item.__saving = false
          this.message = ''
          this.attachments = []
        } else {
          this.item.body = this.message
          this.item.attachments = this.attachments
          this.item.update('meta.organization_id', this.organizationId, false)
          const organization = this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
          if (organization) {
            this.item.update('meta.organization_name', organization.name, false)
          }
          if (this.dataplant) {
            this.item.update('meta.dataplant_id', this.dataplant, false)
            const dataplant = this.$store.getters.DATAPLANT_BY_ID(this.dataplant)
            if (dataplant) {
              this.item.update('meta.dataplant_name', dataplant.name, false)
              this.item.update('meta.dataplant_cluster_name', dataplant.cluster_name, false)
              this.item.update('meta.dataplant_provider_name', dataplant.provider_name, false)
            }
          }

          await this.item.create()
          this.message = ''
          this.attachments = []
          this.$store.commit('CREATE_TICKET', this.item)
          this.$router.push(`/support/${this.organizationId}/ticket/${this.item?.id}`)
        }
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
        this.item.__saving = false
      }
      if (this.item.state === 'open') this.$store.dispatch('WATCH_TICKET', this.item)
    }
  }
}
</script>

<style lang="less">
@import "~@/shared/styles/_variables.less";
.ticket-editor {
  min-height: 600px;
  background-color: white;
  overflow:hidden;
  height: 100%;
  .fp-page {
    height: calc(~"100% - 87px");
    overflow: hidden;

    .dropzone-content{
      margin-top: 5px;
      .extensions {
        display: block;
        font-size: 10px;
      }
      .attachments {
        color: #97A7B7;
        position: relative;
        .attachment-content {
          font-size: 12px;
          display: flex;
          flex-direction: column;
          margin-top: 5px;
          font-size: 15px;
          max-height: 100px;
          overflow: auto;
          i.fp4-trash-can {
            color: #97A7B7;
            &:hover {
              color: @red;
            }
            cursor: pointer;
            font-size: 18px;
          }
          .attachment {
            display: flex;
            flex-direction: row;
            flex-wrap: nowrap;
            justify-content: space-between;
            align-content: stretch;
            align-items: center;
            padding: 5px 5px 7px 0;
            &:hover {
              background-color: @blueLight;
              color: @black;
            }
            .name {
              display: inline-flex;
              flex: 1.5;
              align-items: center;
              span.name {
                padding-left: 5px;
              }
              i.fpui-attachment {
                display: block;
                width: 15px;
                height: 15px;
                background-image:url("~@/core/components/Support/attachment.png");
                background-size: 15px 15px;
              }
            }
            .size {
              padding-right: 20px;
            }
          }
        }
      }

      .dropzone-box {
        position: relative;
        text-align: center;
        border: 1px dashed #97A7B7;
        color: #97A7B7;
        padding: 10px;
        border-radius: 5px;
        cursor:pointer;
        &.attachments-here {
          width: 100%;
        }
        width: 100%;
        &.dragover {
          border-color: #000;
        }
        input {
          cursor:pointer;
          position: absolute;
          left:0;
          width: 100%;
          right:0;
          bottom:0;
          top:0;
          z-index:2;
          opacity: 0;
        }
      }

    }
    .multipane {
      height: 100%;

      .multipane-resizer {
        margin: 0;
        top: 0; /* reset default styling */
        height: 14px;
        background: white;
        position: relative;
        border-top: 1px solid rgba(151, 167, 183, 0.21);
        box-shadow: inset 0px 0px 9px -7px rgba(0, 0, 0, 0.05);
        transition: box-shadow 150ms;
        &:before {
          content: "";
          border-color: rgba(151, 167, 183, 0.5);
          border-style: solid;
          border-width: 1px 0 1px 0;
          height: 5px;
          width: 51px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        &:hover {
          box-shadow: 0px -9px 9px -3px rgb(0 0 0 / 5%);
        }
      }

    }
  }
  .content-block {
    overflow: auto;
  }
  .bottom-content {
    flex-grow:1;
    display: flex;
    flex-direction: column;
    padding: 10px 37px 0;
    overflow-y: auto;
    min-height: 350px;
    .message-block {
      .fpui-textarea {
        .fpui-input-content {
          height: 250px;
          textarea {
            height: 100%!important;
          }
        }
      }
      .buttons {
        margin-top: 12px;
        display: flex;
        align-items: center;
        .fp-loading {
          margin-right: 5px;
        }
      }

      &.created {
        .fpui-textarea {
          .fpui-input-content {
            height: 200px;
          }
        }
      }
    }
  }
  .create-block {
    .line {
      display: flex;
      margin: 10px -5px;
      > div {
        flex-grow: 1;
        margin: 0 5px;
      }
    }
  }
  .header-right {
    top: 10px!important;
    right: 45px!important;
    .description {
      display: flex;
      .text {
        line-height: 28px;
        color: #97A7B7;
        font-size: 12px;
      }
      .contacts {
        margin-right: 19px;
        display: flex;
        flex-direction: row-reverse;
        .icon {
          margin-right: -4px;
          position: relative;
        }
        .contact.new {
          margin-left: 8px;
          border: 1px solid #CBD3DB;
          border-radius: 100%;
          line-height: 28px;
          text-align: center;
          height: 28px;
          width:28px;
          font-size: 22px;
          color:#CBD3DB;
          cursor:pointer;
        }
      }
    }
    .status {
      justify-content: flex-end;
      display:flex;
      line-height: 33px;
      align-items: center;
      .left,.right {
        text-transform: uppercase;
        font-size: 12px;
      }
      .left {
        margin-right: 10px;
        color:#97A7B7;
      }
      .right {
        margin-left: 10px;
        font-weight: 600;
      }
    }
  }
  .message {
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid #e6eEf5;
    .subject {
      margin-top: 5px;
      font-size: 16px;
      font-weight: 600;
    }
    .description {
      text-transform: uppercase;
      margin-bottom: 3px;
      color: #97A7B7;
      font-size: 12px;
      line-height: 15px;
    }
    .content {
      color: #3E4550;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 24px;
    }
    .icon {
      position: relative;
      .admin {
        height: 22px;
        width: 22px;
        background: white;
        background-size: 70%;
        background-repeat: no-repeat;
        background-position: center;
        background-image:url("~@/core/assets/img/logo.png");
        border-radius: 100%;
        position: absolute;
        top: 38px;
        left: -1px;
      }
    }
    &.left {
      flex-direction: row;
      .icon {
        margin-right: 22px;
      }
    }
    &.right {
      flex-direction: row-reverse;
      .icon {
        margin-left: 22px;
      }
      .description {
        text-align: right;
      }
      .content {
        text-align: right;
      }
    }
    .divider {
      display: flex;
      align-items: center;
      margin: 1em -1em;
      color: #97A7B7;
      text-transform: uppercase;
      font-size: 12px;
      &:before,
      &:after {
        content: "";
        height: 1px;
        margin: 0 1em;
        background: #E6EEF5;
      }
      &:before {
        flex: 1
      }
      &:after {
        flex: 49;
      }
    }
    .attachments {
      display: flex;
      .attachment {
        margin: 3px
      }
    }
  }
}
</style>
