<template lang="pug">
.fpui-modal.support-add-contact-modal
  fp-loader(v-if="loading")
  div(v-if="!loading")
    fpui-modal-header(:title="$t('tickets.contacts')" @close='$emit("close")' closeTopButton)
    fpui-modal-body
      fpui-input-tags(
        :label="$t('tickets.contacts.emails')"
        v-model="contacts"
        :validation="emailsValidations",
        :autocomplete="organizationsEmails.map(item=>({text:item}))"
      )
    .modal-footer
      fpui-button.confirm(
        :disabled="!contacts.length || loading",
        @click="onAdd(contacts)"
        color="green"
      ) {{'add'|translate}}
</template>

<script>
export default {
  props: {
    emailsValidations: {
      type: Array,
      required: true
    },
    onAdd: { type: Function, default: () => null }
  },
  data () {
    return {
      loading: false,
      contacts: []
    }
  },
  computed: {
    organizationsEmails () {
      const emails = this.$store.getters.ORGANIZATIONS_EMAILS || []
      return emails
    }
  }
}
</script>
<style lang="less">
.fpui-modal.support-add-contact-modal {
  .modal-footer {
    flex-direction: row-reverse;
  }
  .modal-body {
    height: 110px;
  }
}
</style>
