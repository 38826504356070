import MlmLeftButton from './MlmLeftButton.vue'
import MlmRightButton from './MlmRightButton.vue'
import MlmMainTable from './MlmMainTable.vue'
import MlmSecondTable from './MlmSecondTable.vue'
import MlmLeftKpi from './MlmLeftKpi.vue'
import MlmRightKpi from './MlmRightKpi.vue'

export default {
  MlmLeftButton,
  MlmRightButton,
  MlmMainTable,
  MlmSecondTable,
  MlmLeftKpi,
  MlmRightKpi
}

export {
  MlmLeftButton,
  MlmRightButton,
  MlmMainTable,
  MlmSecondTable,
  MlmLeftKpi,
  MlmRightKpi
}
