import ControlCenterLeftButton from './ControlCenterLeftButton.vue'
import ControlCenterRightButton from './ControlCenterRightButton.vue'
import ControlCenterMainTable from './ControlCenterMainTable.vue'
import ControlCenterSecondTable from './ControlCenterSecondTable.vue'
import ControlCenterLeftKpi from './ControlCenterLeftKpi.vue'
import ControlCenterRightKpi from './ControlCenterRightKpi.vue'

export default {
  ControlCenterLeftButton,
  ControlCenterRightButton,
  ControlCenterMainTable,
  ControlCenterSecondTable,
  ControlCenterLeftKpi,
  ControlCenterRightKpi
}

export {
  ControlCenterLeftButton,
  ControlCenterRightButton,
  ControlCenterMainTable,
  ControlCenterSecondTable,
  ControlCenterLeftKpi,
  ControlCenterRightKpi
}
