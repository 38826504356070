// NoNewAccount
import NoNewAccount from '@/core/components/NoNewAccount/NoNewAccount.vue'
// Accounts
import Accounts from '@/core/components/Account/Accounts.vue'
import ProjectDashboard from '@/core/components/Dataplant/Dashboard/Dashboard.vue'
// Dataplant
import DataplantDeletionConfirmation from '@/core/components/Dataplant/DataplantDeletion/Confirmation.vue'
// Features
import Features from '@/core/components/Features.vue'
import HomeNoOrganization from '@/core/components/Home/NoOrganization.vue'
// Home
import HomeOrganization from '@/core/components/Home/Organization.vue'
import HomeRedirect from '@/core/components/Home/Redirect.vue'
import Teammates from '@/core/components/Home/Teammates.vue'
// IframeService
import IframeService from '@/core/components/IframeService.vue'
import OrganizationDataplants from '@/core/components/Organization/Dataplants'
import OrganizationUserEditor from '@/core/components/Organization/Members/Editor'
import OrganizationMembers from '@/core/components/Organization/Members/Members'
import OrganizationOverview from '@/core/components/Organization/Overview'
import OrganizationPlan from '@/core/components/Organization/Plan'
// Organization
import OrganizationRedirect from '@/core/components/Organization/Redirect.vue'
import OrganizationSettings from '@/core/components/Organization/Settings'
// Profile
import Profile from '@/core/components/Profile/Profile.vue'
// Support
import SupportOrganization from '@/core/components/Support/Organization.vue'
import SupportRedirect from '@/core/components/Support/Redirect.vue'
import SupportTicket from '@/core/components/Support/Ticket.vue'
import { setProjectBaseUrl } from '@/shared/Config'
import RouteWrapper from '@/shared/RouteWrapper'
import Vue from 'vue'
import VueRouter from 'vue-router'
import OrganizationAside from './OrganizationAside'
// New Pages
import Services from '@/core/components/Home/Services/Services.vue'
import IframeCallback from '@/auth/components/IframeCallback.vue'

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => {
    if (err.name !== 'NavigationDuplicated') {
      throw err
    }
  })
}

const onDataplantIdReady = function () {
  const dataplant = this.$store.getters.DATAPLANT_BY_ID(this.$route.params.projectId || this.$route.params.dataplantId)
  if (dataplant) {
    // Set current dataplant urls
    setProjectBaseUrl(dataplant)
  }
}

const isReadyDataplantId = function () {
  // TODO: Remove old dataplantId
  const dataplant = this.$store.getters.DATAPLANT_BY_ID(this.$route.params.projectId || this.$route.params.dataplantId)
  return !!dataplant
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/iframe-callback',
    name: 'IframeCallback',
    component: IframeCallback,
    meta: {
    }
  },
  {
    path: '/home',
    name: 'HQ Home',
    component: HomeRedirect,
    meta: {
      intercom: true,
      ready: ['core_organizations', 'core_accounts']
    }
  },
  {
    path: '/home/teammates',
    name: 'HQ Home Teammates',
    component: Teammates,
    meta: {
      intercom: true,
      ready: ['core_organizations', 'core_accounts']
    }
  },
  {
    path: '/home/new',
    name: 'HQ Home New',
    component: HomeNoOrganization,
    meta: {
      intercom: true,
      ready: ['core_organizations', 'core_accounts']
    }
  },
  {
    path: '/home/:organizationId',
    name: 'HQ Home Organization',
    component: HomeOrganization,
    props: (route) => ({ organizationId: route.params.organizationId }),
    meta: {
      ready: ['core_organizations', 'core_accounts'],
      intercom: true
    }
  },
  {
    path: '/home/:organizationId/services',
    name: 'HQ Home Services',
    component: Services,
    props: (route) => ({ organizationId: route.params.organizationId }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.$route.params.organizationId })
      },
      ready: ['core_organizations', 'core_accounts']
    }
  },
  {
    path: '/features',
    name: 'Features',
    component: Features,
    meta: {
      title: 'Features - Data Platform',
      ready: ['core_organizations']
    }
  },
  {
    path: '/support',
    name: 'Support',
    component: SupportRedirect,
    meta: {
      bordered: true,
      title: 'Support - Data Platform',
      ready: ['core_organizations']
    }
  },
  {
    path: '/support/:organizationId',
    name: 'Support Organization',
    component: SupportOrganization,
    props: (route) => ({ organizationId: route.params.organizationId }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.$route.params.organizationId })
      },
      ready: ['core_organizations'],
      title: (route, parent) => `Support - ${parent(route)}`,
      bordered: true
    }
  },
  {
    path: '/support/:organizationId/ticket',
    name: 'Support Create ticket',
    component: SupportTicket,
    props: (route) => ({ organizationId: route.params.organizationId }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.$route.params.organizationId })
      },
      ready: ['core_organizations'],
      title: (route, parent) => `Support - ${parent(route)}`,
      bordered: true
    }
  },
  {
    path: '/support/:organizationId/ticket/:ticketId',
    name: 'Support Edit ticket',
    component: SupportTicket,
    props: (route) => ({ organizationId: route.params.organizationId, ticketId: route.params.ticketId }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.$route.params.organizationId })
      },
      ready: ['core_organizations'],
      title: (route, parent) => {
        const pad = (str, max) => {
          str = str.toString()
          return str.length < max ? pad('0' + str, max) : str
        }
        return `${pad(route.params.ticketId % 10000, 5)} - Support - ${parent(route)}`
      },
      bordered: true
    }
  },
  {
    path: '/project/:projectId',
    name: 'Project',
    component: ProjectDashboard,
    props: (route) => ({ projectId: route.params.projectId }),
    meta: {
      async onMounted () {
        try {
          await this.$store.dispatch('REFRESH_DATAPLANT_BY_DATAPLANT_ID_CASCADE', { dataplantId: this.$route.params.projectId })
        } catch (err) {
          console.error(err.stack)
          if (err.status === 404) {
            Vue.$router.push('/')
          }
        }
      },
      isReady: isReadyDataplantId,
      onReady: onDataplantIdReady,
      intercom: true,
      ready: []
    }
  },
  {
    path: '/project/:dataplantId/remove',
    name: 'Dataplant Remove',
    component: DataplantDeletionConfirmation,
    props: (route) => ({ dataplantId: route.params.dataplantId, token: route.query.tokenRemove, email: route.query.email }),
    meta: {
      async onMounted () {
        try {
          await this.$store.dispatch('REFRESH_DATAPLANT_BY_DATAPLANT_ID_CASCADE', { dataplantId: this.$route.params.dataplantId })
        } catch (err) {
          console.error(err.stack)
          if (err.status === 404) {
            Vue.$router.push('/')
          }
        }
      },
      onReady: onDataplantIdReady,
      ready: ['core_organizations']
    }
  },
  {
    path: '/organizations',
    name: 'Organization Redirect',
    component: OrganizationRedirect,
    meta: {
      ready: ['core_organizations'],
      bordered: true
    }
  },
  {
    path: '/organization/:organizationId',
    name: 'Organization Overview',
    component: OrganizationOverview,
    props: (route) => ({ organizationId: route.params.organizationId }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.$route.params.organizationId })
      },
      ready: ['core_organizations'],
      aside: OrganizationAside,
      bordered: true
    }
  },
  {
    path: '/organization/:organizationId/members',
    name: 'Organization Members',
    component: OrganizationMembers,
    props: (route) => ({ organizationId: route.params.organizationId }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.$route.params.organizationId })
      },
      ready: ['core_organizations'],
      aside: OrganizationAside,
      bordered: true
    }
  },
  {
    path: '/organization/:organizationId/member/:userId',
    name: 'Organization Member Edit',
    component: OrganizationUserEditor,
    props: (route) => ({ organizationId: route.params.organizationId, userId: route.params.userId }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.$route.params.organizationId })
      },
      ready: ['core_organizations'],
      aside: OrganizationAside,
      bordered: true
    }
  },
  {
    path: '/organization/:organizationId/member',
    name: 'Organization Member Create',
    component: OrganizationUserEditor,
    props: (route) => ({ organizationId: route.params.organizationId }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.$route.params.organizationId })
      },
      ready: ['core_organizations'],
      aside: OrganizationAside,
      bordered: true
    }

  },
  {
    path: '/organization/:organizationId/projects',
    name: 'Organization Projects',
    component: OrganizationDataplants,
    props: (route) => ({ organizationId: route.params.organizationId }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.$route.params.organizationId })
      },
      ready: ['core_organizations'],
      aside: OrganizationAside,
      bordered: true
    }
  },
  {
    path: '/organization/:organizationId/plan',
    name: 'Organization Plan',
    component: OrganizationPlan,
    props: (route) => ({ organizationId: route.params.organizationId }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.$route.params.organizationId })
      },
      ready: ['core_organizations', 'core_accounts', 'core_prices'],
      aside: OrganizationAside,
      bordered: true
    }
  },
  {
    path: '/organization/:organizationId/settings',
    name: 'Organization Settings',
    component: OrganizationSettings,
    props: (route) => ({ organizationId: route.params.organizationId }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_ORGANIZATION_ID', { organizationId: this.$route.params.organizationId })
      },
      ready: ['core_organizations'],
      aside: OrganizationAside,
      bordered: true
    }
  },
  {
    path: '/qb/:dataplantId',
    name: 'Query Builder',
    component: IframeService,
    props: (route) => ({ dataplantId: route.params.dataplantId, service: 'qb' }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_DATAPLANT_ID', { dataplantId: this.$route.params.dataplantId })
      },
      onReady: onDataplantIdReady,
      title: (route, parent) => `QB - ${parent(route)}`,
      ready: ['core_organizations'],
      theme: 'v3'
    }
  },
  {
    path: '/app/:dataplantId/:appName',
    name: 'App Manager',
    component: IframeService,
    props: (route) => ({ dataplantId: route.params.dataplantId, service: 'appservice', query: { appName: route.params.appName, appType: 'app' } }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_DATAPLANT_ID', { dataplantId: this.$route.params.dataplantId })
      },
      onReady: onDataplantIdReady,
      title: (route, parent) => `APP Manager - ${parent(route)}`,
      ready: ['core_organizations'],
      theme: 'v3'
    }
  },
  {
    path: '/api/:dataplantId/:appName',
    name: 'API Manager',
    component: IframeService,
    props: (route) => ({ dataplantId: route.params.dataplantId, service: 'appservice', query: { appName: route.params.appName, appType: 'api' } }),
    meta: {
      async onMounted () {
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_DATAPLANT_ID', { dataplantId: this.$route.params.dataplantId })
      },
      onReady: onDataplantIdReady,
      title: (route, parent) => `API Manager - ${parent(route)}`,
      ready: ['core_organizations'],
      theme: 'v3'
    }
  },
  {
    path: '/user',
    name: 'Profile',
    component: Profile,
    meta: {
      title: 'Profile - Data Platform',
      ready: ['core_organizations']
    }
  },
  {
    path: '/accounts',
    name: 'Accounts',
    component: Accounts,
    meta: {
      title: 'Accounts - Data Platform',
      ready: ['core_accounts', 'core_organizations']
    }
  },
  {
    path: '/no_new_account',
    name: 'No new account',
    component: NoNewAccount,
    meta: {
      title: 'No New Account - Data Platform',
      fullscreen: true
    }
  },
  {
    path: '*',
    redirect: '/home'
  }
].map(route => {
  const { path } = route
  route.path = ''
  return {
    path: path,
    component: RouteWrapper,
    children: [
      route
    ]
  }
})

const router = new VueRouter({
  mode: 'hash',
  routes
})

Vue.$router = router

export default router
