import DpeLeftButton from './DpeLeftButton.vue'
import DpeRightButton from './DpeRightButton.vue'
import DpeMainTable from './DpeMainTable.vue'
import DpeLeftKpi from './DpeLeftKpi.vue'
import DpeRightKpi from './DpeRightKpi.vue'

export default {
  DpeLeftButton,
  DpeRightButton,
  DpeMainTable,
  DpeLeftKpi,
  DpeRightKpi
}

export {
  DpeLeftButton,
  DpeRightButton,
  DpeMainTable,
  DpeLeftKpi,
  DpeRightKpi
}
