<template lang="pug">
.kpi.app(
  v-tooltip="$t('project.home.app.kpi_tooltip_left')"
)
  fp-loader(v-if="loading" type="chart")
  .value(
    v-if="!loading"
  ) {{ value }}
  .text(
    v-if="!loading"
  ) {{ $t('project.home.app.deployed_apps', [value], isNaN(value) ? 0 : value) }}
</template>

<script>
export default {
  computed: {
    applications () {
      return this.$store.getters.PROJECT_HOME_APPLICATIONS
    },
    value () {
      if (!this.canAppserviceApplicationsRead) return '-'
      return this.applications?.filter(app => app.type === 'app' && app.isDeployed)?.length ?? '-'
    },
    loading () {
      return this.$store.getters.PROJECT_HOME_APPLICATIONS_LOADING
    },
    canAppserviceApplicationsRead () {
      return this.$store.getters.ACL('canAppserviceApplicationsRead')
    }
  }
}
</script>

<style lang="less">

</style>
