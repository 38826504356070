export default {
  'consumption-storage': {
    category: ['consumption'],
    description: 'consumption-storage',
    hidden: false,
    i18n: {},
    id: 'consumption-storage',
    image: require('./assets/data_stored.svg'),
    long_description: 'consumption-storage',
    name: 'consumption-storage',
    owner: 'forepaas',
    preview: [],
    type: 'widget'
  },
  'consumption-dataplant': {
    category: ['consumption'],
    description: 'consumption-dataplant',
    hidden: false,
    i18n: {},
    id: 'consumption-dataplant',
    image: require('./assets/projects.svg'),
    long_description: 'consumption-dataplant',
    name: 'consumption-dataplant',
    owner: 'forepaas',
    preview: [],
    type: 'widget'
  },
  'consumption-fpu': {
    category: ['consumption'],
    description: 'consumption-fpu',
    hidden: false,
    i18n: {},
    id: 'consumption-fpu',
    image: require('./assets/fpu_s.svg'),
    long_description: 'consumption-fpu',
    name: 'consumption-fpu',
    owner: 'forepaas',
    preview: [],
    type: 'widget'
  },
  'consumption-fpug': {
    category: ['consumption'],
    description: 'consumption-fpug',
    hidden: false,
    i18n: {},
    id: 'consumption-fpug',
    image: require('./assets/fpu_g.svg'),
    long_description: 'consumption-fpug',
    name: 'consumption-fpug',
    owner: 'forepaas',
    preview: [],
    type: 'widget'
  }
}
