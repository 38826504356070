<template lang="pug">
.kpi.control-center(
  v-tooltip="$t('project.home.control-center.kpi_tooltip_right')"
)
  fp-loader(v-if="loading" type="chart")
  .value(
    v-if="!loading"
  ) {{ value }}
  .text(
    v-if="!loading"
  ) {{ $t('project.home.cc.alert_triggerings_this_week', [value], isNaN(value) ? 0 : value) }}
</template>

<script>
import moment from 'moment'

export default {
  data () {
    return {
      value: 0,
      loading: false
    }
  },
  computed: {
    projectId () {
      return this.$route.params?.projectId
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    serviceReady () {
      return !Object.keys(this.project?.display_settings?.services_wizard || {}).includes('control-center')
    }
  },
  watch: {
    serviceReady: {
      handler (val) {
        if (val) this.load()
      }
    },
    '$store.getters.PROJECT_CARD_INFO_LOADING': {
      handler (val) {
        if (!val) this.load()
      }
    }
  },
  async mounted () {
    if (!this.$store.getters.PROJECT_CARD_INFO_LOADING) await this.load()
  },
  methods: {
    async load () {
      if (this.serviceReady) {
        this.loading = true

        try {
          const metrics = await this.$api.CC.metrics.alertingTriggerings(null, { start: moment().subtract(7, 'days').unix(), end: moment().unix() })
          const int = metrics === undefined ? 0 : parseInt(metrics)
          this.value = int ?? '-'
        } catch (err) {
          this.value = '-'
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="less">

</style>
