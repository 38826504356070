<template lang="pug">
  .appservice-select-api
    fpui-input-label {{ 'appservice.create.selectapi.label' | translate }}
    .apis
      .api(@click="$emit('input','')",:class="{ 'active': value === '' }")
        i.fp4.fp4-circle-xmark-small
        span NONE
      .api(
        v-for="api in apis"
        @click="$emit('input',api.app_name)"
        :class="{ 'active': api.app_name === value }"
      )
        i.fp4(:class="{'fp4-check-circle':api.state==='10','fp4-triangle-exclamation-small':api.state!==10}")
        span {{api.app_name}}

</template>

<script>
export default {
  props: {
    value: {
      required: true,
      type: String
    },
    apis: {
      required: true,
      type: Array
    }
  }
}
</script>

<style lang="less">
.appservice-select-api {
  .apis {
    .api {
      height: 120px;
      padding: 10px;
      display: inline-block;
      position: relative;
      box-shadow: 0 2px 10px 0 rgb(0 0 0 / 10%);
      border-radius: 4px;
      border: 2px solid white;
      transition: all 0.25s;
      margin: 10px;
      width: calc(33.33333% - 20px);
      overflow: hidden;
      cursor: pointer;
      &:hover,&.active {
        border-color: #10B6E9;
        background-color: #E6F8FD;
      }
      i {
        text-align: center;
        position: relative;
        height: 75px;
        width: 75px;
        margin: 12.5px 10px 0 10px;
        overflow: hidden;
        float: left;
        border: 1px solid #EBEBEB;
        border-radius: 20px;
        background-color: white;
        transition: box-shadow 0.25s;
        line-height: 75px;
        font-size: 40px;
        &.fp4-check-circle {
          color: @green;
        }
      }
      span {
        line-height: 100px;
        margin-left: 20px;
        font-size : 16px;
      }
    }
  }
}
</style>
