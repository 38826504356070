<template lang="pug">
.services(v-if='config')
  fp-loader(v-if="addLoading")
  .service(v-for="service in servicesList")
    .title-box
      .title(
        :id="service.category"
        :style="{color: service.color}"
        ) {{ $t(`services.title.${service.category}`) }}
    .details-box
      .detail-row(
          v-for="(serv) in service.services"
          v-if="!serv.hidden"
        )
        .detail-header(
          @click="trackExpandAndCollapseArrow(serv)"
        )
          .detail-title-description
            .detail-img-title(
              :class="[serv.id]"
            )
              .detail-image
                i.fp4(
                  v-if="serv.icon"
                  :class="serv.icon"
                )
                img(
                  v-if="serv.image"
                  :src="serv.image"
                )
              .detail-title
                span {{ $t(serv.displayName) }}
              .detail-title-active(v-if="isServiceInTheProject(serv.serviceId)")
                i.fp4.fp4-check-bold
                .text {{ $t('services.service.active') }}
            .detail-description {{ $t(serv.smallDescription) }}

          .arrow
            i.fp4.fp4-angle-up(v-if="serviceOpened === serv.id")
            i.fp4.fp4-angle-down(v-if="!serviceOpened")

        transition(name="slide-fade")
          .detail-more-text(
              v-show="serviceOpened === serv.id"
            ) {{ $t(serv.longDescription) }}
            .detail-more-text-buttons(v-if="service.category !== 'end_to_end'")
              .use-service-button(
                :class="[`project-list-${serv.id}`]"
              )
                a(
                  v-if="isFromOrganization(serv)"
                  :href="openFromOrganization(serv)"
                  @click.stop="openFromOrganization(serv, true)"
                )
                  fpui-button(
                    color="blue-flash"
                    auto-width
                    :disabled="serv.id === 'storage-engines' && !canStorageEnginesRead"
                  ) {{ $t('services.buttons.open_service') }}

                fpui-button(
                  v-else-if="!isFromOrganization(serv) && projectId && !isServiceInTheProject(serv.serviceId)"
                  color="blue-flash"
                  auto-width
                  @click="addService(serv)"
                ) {{ $t('services.buttons.get_started') }}

                fpui-button.open-this-project-button(
                  v-else-if="!isFromOrganization(serv) && projectId && isServiceInTheProject(serv.serviceId)"
                  color="blue-flash"
                  auto-width
                  @click="openService(serv)"
                  :disabled="!projectIsReady"
                  v-tooltip="{ content: projectTooltip, delay: 0 }"
                ) {{ $t('services.buttons.use_this_service') }}

                fpui-button(
                  v-else
                  color="blue-flash"
                  auto-width
                  icon='fp4 fp4-angle-down'
                  @click="open(serv)"
                ) {{ $t('services.buttons.use_service') }}
                //- TODO QuickProjectCreation

                .projects-list(
                  v-if="useService && useService.id === serv.id"
                  :class="[dropdownClasses]"
                )
                  open-in-project(
                    :organization-id="organizationId"
                    :service-active="useService"
                    @close="open(null)"
                  )

              fpui-button(
                color="white"
                @click.stop="redirectAndTrackDocBtn(serv)"
                target="_blank"
                auto-width
              ) {{ $t('services.buttons.documentation') }}

            .data-project-template(v-if="service.category === 'end_to_end'")
              data-project-templates(
                :organization-id="organizationId"
                @create-project="createProject"
                :from-services="true"
              )
              .box-title-link.underline-hover(
                @click="browseTemplates"
              )
                span {{ $t('core.data_project_creation.templates.browse_all') }}
                i.fp4.fp4-angle-right
</template>

<script>
import _get from 'lodash/get'
import DataplantCreationFullscreen from '@/shared/components/project/DataplantCreationFullscreen/DataplantCreationFullscreen.vue'
import DataProjectTemplates from '@/shared/components/project/DataProjectTemplates'
import OpenInProject from '@/shared/components/app/OpenInProject.vue'
import SubscriptionNew from '@/core/components/Subscription/New.vue'
import Store from '@/shared/components/store'
import Config from '@/shared/Config'
import QuickProjectCreation from '@/shared/components/app/QuickProjectCreation'

export default {
  components: {
    DataProjectTemplates,
    OpenInProject
  },
  props: {
    organizationId: { type: String, default: '' },
    projectId: { type: String, default: '' },
    filteredServiceList: { type: Array, required: true }
  },
  data () {
    return {
      useService: null,
      serviceOpened: null,
      config: null,
      addLoading: false,
      healthCheckInterval: null
    }
  },
  computed: {
    subscription () {
      return this.organization.subscription
    },
    subscriptionStatus () {
      if (this.organization.bypass_billing) return 'active'
      if (!this.subscription) return null
      return this.subscription?.status
    },
    organization () {
      if (this.organizationId === '') return false
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    dropdownClasses () {
      const view = document.querySelector('.list-of-services')?.getBoundingClientRect()
      if (!view || !this.useService) return 'bottom'
      const elementTop = document.querySelector(`.project-list-${this.useService?.id}`).getBoundingClientRect().top
      if (!elementTop) return 'bottom'
      const height = 379
      if (elementTop - view.top + height > view.height) return 'top'
      return 'bottom'
    },
    canStorageEnginesRead () {
      return this.$acl(this.organizationId).can('core', 'storage_instance', 'read')
    },
    servicesList () {
      return this.filteredServiceList.map(category => {
        category.services = category.services.filter(service => {
          if (this.config?.DATA_PLATFORM_ACCESS && service.id === 'mlm') return false
          if (!this.config?.DATA_PLATFORM_ACCESS && service.dataplatform) return false
          return true
        })
        return category
      })
    },
    canCreateProject () {
      return this.$acl(this.config.CMP ? this.organizationId : true).can('core', 'dataplants', 'create')
    },
    project () {
      if (this.projectId) {
        return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
      }
      return {}
    },
    projectIsReady () {
      return this.project.isReady && !this.project.isLimited
    },
    projectTooltip () {
      if (!this.project.isReady) return this.$t('header.project_is_not_ready')
      return ''
    }
  },
  async mounted () {
    this.config = await Config()
  },
  destroyed () {
    if (this.healthCheckInterval) clearInterval(this.healthCheckInterval)
  },
  methods: {
    trackExpandAndCollapseArrow (service) {
      this.serviceOpened = this.serviceOpened === service.id ? null : service.id

      this.$analytics.track('Expand/collapse service section', {
        service: this.$t(service.displayName),
        action: this.serviceOpened ? 'expand' : 'collapse'
      })
    },
    redirectAndTrackDocBtn (service) {
      this.$analytics.track('Expand/collapse service section', {
        service: this.$t(service.displayName)
      })
      window.open(service.documentation, '_blank')
    },
    openFromOrganization (service, click = true) {
      if (click) {
        if (service?.id === 'storage-engines' && !this.canStorageEnginesRead) return
        window.localStorage.setItem('tracking-from', 'services list page')
      }

      let link = ''
      if (service.id === 'billing-explorer') link = `${window.location.origin}/#/organization/${this.organizationId}/plan?tabActive=billing`
      if (service.id === 'storage-engines') link = `${window.location.origin}/databases/#/${this.organizationId}`

      return link
    },
    noProjectsInService () {
      const projects = this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId)
      return !projects?.length
    },
    open (service) {
      // If there is no projects in the clicked service, open the 'create a new project' modal when the 'open in project' button is clicked
      if (service && this.noProjectsInService()) {
        this.createQuickProject(service)
      } else {
      // Due to the click listener we need to delay the opening
        setTimeout(() => {
          this.useService = service
        }, 100)
      }
    },
    async createProject (template = null) {
      // Verifies the status subscription to either open the modal to create a project
      // Or the one to subscribe to a plan it is necessary to comment this part and to
      // Add a mock storageConfigurations obect in DataplantCreationFullscreen to test
      // This functionnality if you have no plans
      if (!this.subscription) await this.organization.loadSubscription()
      if (
        (['active', 'non_renewing'].includes(this.subscriptionStatus)) ||
        (this.subscription?.status === 'in_trial' && this.subscription?.payment_valid && this.subscription?.scheduled_status !== 'cancelled')
      ) {
        if (!this.$acl(this.organizationId).can('core', 'dataplants', 'create')) return
        this.trackOpenProjectFunnel(template)
        this.$modal.show(DataplantCreationFullscreen, {
          organizationId: this.organizationId,
          template
        }, {
          height: '100%',
          width: '100%',
          adaptative: true,
          classes: []
        })
      } else {
        this.updateTrialToRealPlan()
      }
    },
    async updateTrialToRealPlan () {
      this.$modal.show(SubscriptionNew, {
        organizationId: this.organizationId,
        trial: true
      }, {
        height: 'auto',
        width: 900
      })
    },
    async createQuickProject (service) {
      if (!this.subscription) await this.organization.loadSubscription()
      if (
        (['active', 'non_renewing'].includes(this.subscription?.status)) ||
        (this.subscription?.status === 'in_trial' && this.subscription?.payment_valid && this.subscription?.scheduled_status !== 'cancelled')
      ) {
        if (!this.canCreateProject) return
        this.$analytics.track('Open new project funnel', {
          from: 'services list page',
          channel: 'new single service'
        })
        this.$modal.show(QuickProjectCreation, {
          organizationId: this.organizationId,
          module: ['api', 'app'].includes(service?.id) ? 'appservice' : service?.id,
          afterConfirm: () => {
            this.$router.push(`home/${this.organizationId}`)
          }
        }, {
          height: 'auto',
          width: '600px',
          adaptative: true,
          classes: []
        })
      } else {
        this.updateTrialToRealPlanForCreateQuickProject(service)
      }
    },
    async updateTrialToRealPlanForCreateQuickProject (service) {
      await this.organization.loadSubscription()
      this.$modal.show(SubscriptionNew, {
        organizationId: this.organizationId,
        trial: this.organization?.subscription?.status === 'in_trial' && this.organization?.subscription?.scheduled_status === 'cancelled',
        onConfirm: async () => {
          await this.organization.loadSubscription()
          this.createProject(service)
        }
      }, {
        height: 'auto',
        width: 900
      })
    },
    isFromOrganization (service) {
      return service.fromOrganization
    },
    trackOpenProjectFunnel (template) {
      if (template) {
        this.$analytics.track('Open new project funnel', {
          from: 'services list page',
          template: template.id,
          channel: 'new project template'
        })
      } else {
        this.$analytics.track('Open new project funnel', {
          from: 'services list page',
          channel: 'new project'
        })
      }
    },
    async templateById (selectedTemplate) {
      try {
        const storeTemplates = this.$store.getters.STORE_PACKAGES.project?.templates
        const modules = this.$store.getters.STORE_PACKAGES.project?.modules
        const template = storeTemplates[selectedTemplate.id]
        const meta = await this.$api.STORE.metas('project', 'templates', selectedTemplate.id, 'latest')
        const services = Object.keys(meta.options?.services).map(service => {
          let icon = ''
          try {
            icon = require(`@/core/components/Dataplant/assets/${service}.svg`)
          } catch (err) {
            icon = require('@/shared/assets/img/ovhcloud_logo_simplified_blue.png')
          }
          return {
            img: icon,
            service: _get(modules, [service, 'description'])
          }
        })
        return {
          id: selectedTemplate.id,
          services: services,
          title: template.description,
          description: template.long_description,
          image: template.image
        }
      } catch (err) {
        console.error('DataProjectCreation error : ', err)
      }
    },
    browseTemplates () {
      this.$modal.show(Store, {
        name: this.$t('core.onboarding.store_template'),
        type: 'project.templates',
        onSelect: async (template, modal) => {
          const selectedTemplate = await this.templateById(template)
          this.createProject(selectedTemplate)
          modal.$emit('close')
        }
      }, {
        width: 980,
        height: 640
      })
    },
    isServiceInTheProject (serviceId) {
      return this.project?.services?.includes(serviceId)
    },
    async addService (service) {
      // If service already installing -> redirect to project home
      if (Object.keys(this.project?.display_settings?.services_wizard || {}).includes(service.id)) {
        window.open(`${window.location.origin}/#/project/${this.project.id}`, '_self')
        this.$emit('close')
        return
      }

      this.addLoading = true
      let serviceToUse = service.id
      if (['api', 'app'].includes(service.id)) serviceToUse = 'appservice'
      if (['lakehouse', 'data-catalog'].includes(service.id)) serviceToUse = 'dm'
      if (['aab'].includes(service.id)) serviceToUse = 'gab'
      const packageUrl = `${this.config.STORE}/v1/packages/project/modules/${serviceToUse}/latest`
      let serviceHealth = null

      if (service.id === 'dpe') serviceHealth = this.$api.DPE
      if (['lakehouse', 'data-catalog', 'dm'].includes(service.id)) serviceHealth = this.$api.DM
      if (service.id === 'am') serviceHealth = this.$api.QUERY_ADMIN
      if (service.id === 'mlm') serviceHealth = this.$api.ML
      if (service.id === 'iam') serviceHealth = this.$api.IAM
      if (service.id === 'control-center') serviceHealth = this.$api.CC
      if (service.id === 'storage-engines') serviceHealth = this.$api.STORAGE
      if (service.id === 'api' || service.id === 'app') serviceHealth = this.$api.APPSERVICE
      if (service.id === 'aab') serviceHealth = this.$api.GAB
      if (!serviceHealth) {
        this.addLoading = false
        return
      }

      try {
        if (service.id === 'aab') this.$fpuiMessageBlock.success(this.$t('services.add_services.creating.long'))

        await this.project.addService(this.projectId, packageUrl)
        // Refresh project to get last info about services wizard
        await this.project.refresh()
        const servicesWizard = {
          ...this.project.display_settings.services_wizard || {},
          [service.id]: new Date()
        }
        // Update 'display_settings.services_wizard' when a service is being installed
        this.project.update('display_settings.services_wizard', servicesWizard, false)
        await this.project.save()
        await this.$store.dispatch('REFRESH_DATAPLANT_BY_DATAPLANT_ID', { dataplantId: this.project.id, ignoreCache: true })

        this.healthCheckInterval = setInterval(async () => {
          try {
            if (service.id === 'aab') {
              // aab is taking too much time to be ready. To not block user we redirect to project home
            } else await serviceHealth.health()
            // If health is working, clear interval
            clearInterval(this.healthCheckInterval)
            // Refresh project to get last info about services wizard
            await this.project.refresh()
            // Remove services wizard from object then redirect to the service
            if (service.id === 'aab') {
              this.$fpuiMessageBlock.success(this.$t('services.add_services.created_no_redirect.wait'))
              this.addLoading = false
            } else {
              const serviceRemoved = { ...this.project.display_settings.services_wizard || {} }
              delete serviceRemoved[service.id]
              // Remove the value from 'display_settings.services_wizard' when a service is installed
              this.project.update('display_settings.services_wizard', serviceRemoved, false)
              await this.project.save()
              this.addLoading = false
              this.$fpuiMessageBlock.success(this.$t('services.add_services.created_no_redirect'))
            }
            window.open(`${window.location.origin}/#/project/${this.projectId}`, '_self')
          } catch (err) {}
        }, 5000)
      } catch (error) {
        console.error(error)
        clearInterval(this.healthCheckInterval)
        this.$fpuiMessageBlock.error(this.$t('services.add_services.creating.error'))
        this.addLoading = false
      }
    },
    async openService (service) {
      let serviceUrl = `${window.location.origin}/${service.id}/#/${this.project.id}`
      // If the clicked service is not ready, redirect to the project home (especially needed for gab)
      if (Object.keys(this.project?.display_settings?.services_wizard || {}).includes(service.id)) {
        serviceUrl = `${window.location.origin}/#/project/${this.project.id}`
        window.open(serviceUrl, '_self')
        return
      }
      if (['api', 'app'].includes(service.id)) {
        serviceUrl = `${window.location.origin}/#/project/${this.project.id}`
      }
      if (['cc', 'control-center'].includes(service.id)) {
        if (this.project.id) serviceUrl = `${window.location.origin}/cc/#/${this.config.ORGANIZATION_ID}/${this.project.id}`
        else serviceUrl = `${window.location.origin}/cc/#/${this.config.ORGANIZATION_ID}`
      }
      if (service.id === 'mlm') serviceUrl = `${window.location.origin}/ml/#/${this.project.id}`
      window.open(serviceUrl, '_self')
    }
  }
}
</script>

<style lang="less">
.services {
  display: grid;
  grid-gap: 10px;
  padding: 10px 0;

  .service {
    display: grid;
    grid-template-columns: 166px 1fr;
    padding: 10px 0px 20px;

    &:not(:last-child) {
      border-bottom: 1px solid #E4E7EC;
    }
  }
  .details-box {
    height: auto;
  }

  .title-box {
    width: 166px;
    border-right: 1px solid #E4E7EC;
    padding-right: 20px;

    .title {
      padding-top: 10px;
      padding-left: 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #3E4550;
    }
  }
  .detail-row {
    border-bottom: 1px solid #E4E7EC;
    padding: 10px 0px 9px 24px;

    &:last-of-type {
      border-bottom: none;
    }
  }

  .detail-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    &:hover {
      .detail-title {
        text-decoration: underline;
      }
      .arrow {
        cursor: pointer;
        color: @blue;
      }
    }
  }

  .detail-img-title {
    display: flex;
    align-items: center;
    margin-bottom: 3px;
    margin-left: -4px;

    .fp4-dataplants{
      font-size: 22px;
      color: #0089C0;
    }

    img {
      width: 28px;
    }

    &.end-to-end {
      margin-left: 0;
    }
    &.dm {
      margin-left: -2px;
    }
    &.app, &.storage-engines {
      margin-left: -6px;
    }
    &.api {
      margin-left: -8px;
    }
    .detail-title-active {
      margin-left: 14px;
      display: flex;
      align-items: center;
      color: white;
      height: 22px;
      padding: 2px 8px 2px 2px;
      align-items: center;
      gap: 4px;
      border-radius: 16px;
      background: #9EDF10;
      .fp4.fp4-check-bold {
        font-size: 14px;
      }
      .text {
        text-align: center;
        font-size: 12px;
        font-weight: 600;
        line-height: 12px;
        letter-spacing: -0.12px;
      }
    }
  }

  .detail-title {
    margin-left: 6px;
    align-self: center;

    span {
      font-weight: 400;
      font-size: 16px;
      line-height: 20px;
      color: #3E4550;
    }
  }

  .arrow {
    margin-right: 10px;
    color: #97A7B7;
    i {
      font-size: 20px;
    }
  }

  .detail-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #748294;
  }

  .slide-fade-enter-active, .slide-fade-leave-active {
    transition: all .25s ease;
  }
  .slide-fade-enter, .slide-fade-leave-to {
    transform: translateY(-25%);
    opacity: 0;
  }

  .detail-more-text{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 10px 24px 0px 0px;
    gap: 10px;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #748294;
  }

  .detail-more-text-buttons{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 12px;
    margin: 7px 0px;

    .use-service-button {
      position: relative;
      .projects-list {
        position: absolute;
        top: 40px;
        right: 0;
        width: 294px;
        background: #FFF;
        box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
        border-radius: 7px;
        z-index: 999;

        &.top {
          top: auto;
          bottom: 40px;
        }
      }
    }
  }

  .box-title-link {
    &.underline-hover {
      margin-top: 5px;
      display: flex;
      align-items: center;
      cursor: pointer;
      font-weight: 600;
      font-size: 12px;
      line-height: 20px;
      color: @blue_flash;
      i {
        font-size: 16px;
        margin-left: 2px;
      }
      &:hover {
        span {
          text-decoration: underline;
        }
      }
    }
  }
  .data-project-templates{
    grid-gap: 10px;
    justify-content: flex-start;
  }
}
</style>
