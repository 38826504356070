
<template lang="pug">
  .access-manager-add-rule
    fpui-modal
      fpui-modal-header(
        :title="$t('organization.users.edit_user.permission.modal.title.rule_editor')"
        @close='$emit("close")'
      )
      fpui-modal-body(v-if="rule")
        fpui-input-text(
          :label="$t('organization.users.edit_user.permission.tab.title.name')"
          v-model="rule.Name"
        )
        br
        fpui-input-select(
          :label="$t('organization.users.edit_user.permission.tab.title.attribute')"
          v-if="resource==='dataplants'"
          v-model="rule.Attributes.dataplantId"
          :options="dataplants.concat([{label:'-- All --',value:'*'}])"
        )
        br
        fpui-input-select.action(
          :label="$t('organization.users.edit_user.permission.tab.title.action')"
          v-model="rule.Action"
          :clearable="false"
          :options="actions.concat([{label:'-- All --',value:'*'}])"
        )
        br
      fpui-modal-footer(:buttons="buttons")
</template>

<script>
import _cloneDeep from 'lodash/cloneDeep'
export default {
  props: {
    resource: { type: String, required: true },
    parentRule: { type: Object, required: false, default: null },
    onConfirm: { type: Function, required: true },
    actions: { type: Array, required: true },
    organizationId: { type: String, required: true }
  },
  data () {
    return {
      rule: null
    }
  },




  computed: {
    dataplants () {
      return this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId).map(dataplant => ({ label: dataplant.name, value: dataplant.dataplant_id }))
    },
    buttons () {
      return [{
        label: this.$t('cancel'),
        type: 'close',
        handler: () => {
          this.$emit('close')
        }
      }, {
        label: this.$t('confirm'),
        color: 'blue-flash',
        // disabled: this.owner.email === this.currentOwnerEmail,
        async handler () {
          this.onConfirm(this.rule)
          this.$emit('close')
        }
      }]
    }
  },
  mounted () {
    this.rule = this.parentRule
      ? _cloneDeep(this.parentRule)
      : {
          Effect: 'Allow',
          Name: '',
          Action: '*',
          Resource: this.resource,
          Attributes: {}
        }
  }
}
</script>

<style lang="less">
.access-manager-add-rule {
  .action {
    text-transform: capitalize;
  }
}
</style>
