<template lang="pug">
  .dataplant-block.subscription-paused-cancelled
    fp-loader(v-if="loading" type="page")
    i.fp4.fp4-triangle-exclamation-small
    .title {{ $t('homeblock.subscription.title') }}
    .description {{ $t('homeblock.subscription.reactivate.description') }}
    fpui-button(
      color="blue-flash"
      @click="reactivateSubscription"
    ) {{ $t('homeblock.subscription.reactivate.button') }}
</template>

<script>
import ConfirmModal from '@/shared/components/modals/Confirm'
import SubscriptionNew from '@/core/components/Subscription/New.vue'
export default {
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    }
  },
  methods: {
    async reactivateSubscription () {
      if (this.organization?.subscription?.status === 'non_renewing') {
        if (await ConfirmModal(this, {
          title: this.$t('onboarding.blocks.scale_reactivate.title'),
          content: this.$t('subscription.reactivate.confirmation'),
          yes: { color: 'green', text: this.$t('home.dataplant.inactive_subscription.reactivate') }
        })) {
          this.loading = true
          try {
            await this.organization.reactivateSubscription()
          } catch (err) {
            this.$fpuiMessageBlock.error(err)
          }
          this.loading = false
        }
      } else {
        this.$modal.show(SubscriptionNew, {
          organizationId: this.organizationId,
          trial: true
        }, {
          height: 'auto',
          width: 900
        })
      }
    }
  }
}
</script>

<style lang="less">
  .dataplant-block.subscription-paused-cancelled {
    background: transparent;
    box-shadow: none;
    border: 1px dashed rgba(151, 167, 183, 0.32);
    justify-content: center;
    background-position: left 50% top 28px;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    padding-top: 203px;

    i {
      font-size: 100px;
      color: #F62172;
      position: absolute;
      top: 95px;
      left: 0;
      right: 0;
      text-align: center;
    }
    .title {
      color: #3E4550;
      font-size: 18px;
      font-weight: bold;
      line-height: 22px;
      text-align: center;
      margin-bottom: 10px;
    }
    .description {
      width: 230px;
      margin: auto;
      margin-bottom: 15px;
      color: #3E4550;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 22px;
      text-align: center;
    }
    .button-container {
      display: flex;
      justify-content: center;
    }
  }
</style>
