<template lang="pug">
  .account-select(:ref='selectAccountRef' @click="toggle()")
    img(:src='defaultPicture')
    .content
      .company {{companyName}}
      .id {{ value }}
    i.fp4.fp4-chevron-down
    .dropdown(v-if="open" @click="$event.stopPropagation()")
      fpui-input-search(:key-id="'search-account'" v-model="search").search
      .dropdown-inner
        .customer-account(v-for="acc in customerAccounts" @click="select(acc)",:class="{ active: acc._id === value }")
          img(:src='defaultPicture')
          .content
            .company {{acc.billing_address && acc.billing_address.company || acc.company || acc.email }}
            .id {{acc._id}}
        .customer-account.customer-account-new(@click="newAccount(); $event.stopPropagation()")
          img(:src="require('@/core/assets/img/new.svg')")
          .content
            .company {{$t('accounts.new')}}
</template>
<script>
import _get from 'lodash/get'
export default {
  props: {
    value: {
      type: String,
      required: true
    },
    accounts: { type: Array, required: true }
  },
  data () {
    return {
      defaultPicture: require('@/core/assets/img/account.svg'),
      open: false,
      search: '',
      selectAccountRef: `account_select_${this._uid}`
    }
  },
  computed: {
    companyName () {
      const account = this.$store.getters.ACCOUNT_BY_ID(this.value)
      const fullName = [account.first_name, account.last_name].filter(e => e).join(' ')
      return _get(account, 'billing_address.company') || account.company || fullName || account.email
    },
    customerAccounts () {
      return this.accounts
        .filter(account => !this.search || account._id.indexOf(this.search.toLowerCase()) !== -1 ||
      account.billing_address.company.toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    }
  },
  mounted () {
    document.addEventListener('click', this.onClickOutside)
  },
  destroyed () {
    document.removeEventListener('click', this.onClickOutside)
  },
  methods: {
    toggle () {
      this.open = !this.open
    },
    select (account) {
      this.$emit('input', account._id)
      this.open = false
    },
    onClickOutside (event) {
      if (!this.$refs[this.selectAccountRef].contains(event.target)) {
        this.open = false
      }
    },
    newAccount () {
      this.$emit('newAccount')
      this.open = false
    }
  }
}
</script>
<style lang="less">
  .account-select {
    position        : absolute;
    z-index         : 2;
    text-align      : left;
    top             : 25px;
    left            : 51px;
    height          : 40px;
    border          : 1px solid rgba(151, 167, 183, 0.21);
    border-radius   : 5px;
    background-color: #FFFFFF;
    display         : inline-block;
    padding         : 3px 13px;
    img {
      display       : inline-block;
      vertical-align: middle;
      width         : 27px;
      margin-right  : 10px;
    }
    .content {
      vertical-align: middle;
      display       : inline-block;
      .company {
        font-size  : 13px;
        font-weight: 600;
        line-height: 17px;
      }
      .id {
        color      : @grey;
        font-size  : 11px;
        line-height: 14px;
      }
    }
    i {
      vertical-align: middle;
      color         : @blue;
      font-weight   : bold;
      font-size     : 14px;
      margin-left   : 25px;
    }

    .dropdown {
      top          : 0;
      padding      : 1px;
      left         : 0;
      min-width    : 100%;
      box-shadow   : 0 12px 35px 0 rgba(62, 69, 80, 0.55);
      border-radius: 5px;
      position     : absolute;
      background   : white;
      .search {
        input {
          height      : 40px;
          border-left : 0;
          border-right: 0;
          border-top  : 0;
          background  : white;
        }
        i.fp4 {
          top: 4px;
          left: -25px;
        }
      }
      .dropdown-inner {
        max-height: 300px;
        overflow  : auto;
        .customer-account {
          cursor: pointer;
          height        : 50px;
          padding       : 10px 18px;
          font-weight   : 400;
          font-size     : 14px;
          letter-spacing: 0;
          line-height   : 24px;
          .company {
            font-weight: normal;
          }
          &:hover,
          &.active {
            .company {
              color      : @blue;
              font-weight: 600;
            }
          }
        }
      }
      .customer-account-new {
        border-top: 1px solid #eee;
      }
    }
  }
</style>
