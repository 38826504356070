<template lang="pug">
.left-button.dm
  a(
    :href="link"
    @click="goTo"
  )
    fpui-button(
      color="white"
      :disabled="disabled"
      v-tooltip="{ content: !canDmDatabaseRead || !canDmDatabaseWrite ? $t('dashboard.access.tooltip') : $t('project.home.data-catalog.button_tooltip_right'), delay: 0 }"
    ) {{ $t('project.home.data-catalog.add_file') }}
</template>

<script>
export default {
  props: {
    editing: { type: Boolean, required: true }
  },
  computed: {
    projectId () {
      return this.$route.params?.projectId
    },
    link () {
      return `/data-catalog/#/${this.projectId}/source?tags.path=&package=file-upload`
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    serviceReady () {
      return !Object.keys(this.project?.display_settings?.services_wizard || {}).includes('dm')
    },
    canDmDatabaseRead () {
      return this.$store.getters.ACL('canDmDatabaseRead')
    },
    canDmDatabaseWrite () {
      return this.$store.getters.ACL('canDmDatabaseWrite')
    },
    disabled () {
      return !this.canDmDatabaseRead || !this.canDmDatabaseWrite || !this.serviceReady || this.editing
    }
  },
  methods: {
    goTo (e) {
      if (this.disabled) e.preventDefault()
    }
  }
}
</script>

<style lang="less">

</style>
