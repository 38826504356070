import IamLeftButton from './IamLeftButton.vue'
import IamRightButton from './IamRightButton.vue'
import IamMainTable from './IamMainTable.vue'
import IamLeftKpi from './IamLeftKpi.vue'
import IamRightKpi from './IamRightKpi.vue'

export default {
  IamLeftButton,
  IamRightButton,
  IamMainTable,
  IamLeftKpi,
  IamRightKpi
}

export {
  IamLeftButton,
  IamRightButton,
  IamMainTable,
  IamLeftKpi,
  IamRightKpi
}
