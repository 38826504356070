import Config from '@/shared/Config'
import { User } from '@/shared/plugins/Api/KING/Users'
import TokenInjector from '@/shared/plugins/Api/TokenInjector'
import FpStore from '@/shared/store'
import moment from 'moment'
import Vue from 'vue'
import Vuex from 'vuex'
import support from './support'
import projectHome from './projectHome'
import acl from './acl'

const TIME_BETWEEN_CHECK_SESSION = (900 + Math.random() * 900) * 1000 // Every 15min-30-min

const TIME_BEFORE_REFRESH_SESSION = 3600 * 1000 // ! hour

Vue.use(Vuex)

const Store = {
  modules: { support, projectHome, acl },
  state: {
    cmpSession: null,
    ready: {
      core_organizations: false,
      core_accounts: false,
      core_prices: false,
      users_subscriptions: false,
      users_orga_subscriptions: false,
      acl: false
    }
  },
  mutations: {
    SET_READY (state, { key, value }) { state.ready[key] = value } // Not WS
  },
  actions: {
    async LOGIN_DONE ({ commit, dispatch }) {
      Promise.all([
        {
          key: 'core_organizations',
          name: 'LOAD_ORGANIZATIONS'
        }, {
          key: 'core_accounts',
          name: 'LOAD_ACCOUNTS'
        }, {
          key: 'core_prices',
          name: 'LOAD_PRICES'
        }
      ].map(async action => {
        try {
          if (
            typeof (action.access) === 'undefined' ||
            (typeof (action.access) === 'function' && await action.access()) ||
            await action.access
          ) await dispatch(action.name)
        } catch (err) {
          if (err.message.includes('Error: timeout of')) {
            err.message = `ServerError:Timeout:${action.name}`
          }
          Vue.fpuiMessageBlock.error(err)
          console.error(err)
        } finally {
          commit('SET_READY', { key: action.key, value: true })
        }
      }))
    },

    async CHECK_CMP_SESSION ({ commit, dispatch }, options = {}) {
      let user = null
      try {
        user = await Vue.api.KING.users.me()
      } catch (_err) {
        // Not logged, redirect to king login page
        const config = await Config()
        document.location.href = `${config.KING}/oauth/authorize?client_id=hq&response_type=token&redirect_uri=${window.location.origin}`
        return
      }
      user = new User(user)

      commit('SET_SESSION', user)
      if (Vue.$analytics && user.email) {
        Vue.$analytics.identify(user.email, {}, FpStore.getters.ANALYTICS_CONFIGURATION)
      }
      // if session to old
      if (!options.refresh && user.token && moment() - moment(user.token.updated_at) > TIME_BEFORE_REFRESH_SESSION) {
        return dispatch('REFRESH')
      }

      setTimeout(_ => {
        dispatch('CHECK_CMP_SESSION')
      }, TIME_BETWEEN_CHECK_SESSION)

      dispatch('LOGIN_DONE')
    },

    async REFRESH_CURRENT_USER ({ commit }) {
      // Use to force refresh user informations
      const user = new User(await Vue.api.KING.users.me())
      commit('SET_SESSION', user)
    },

    async INIT_CHARGEBEE (_, currencyCode = 'EUR') {
      const config = await Config()
      // After include, Init Chargebee
      window.Chargebee.init({
        site: config.CHARGEBEE[currencyCode.toUpperCase()].SITE || config.CHARGEBEE.EUR.SITE,
        publishableKey: config.CHARGEBEE[currencyCode.toUpperCase()].APIKEY || config.CHARGEBEE.EUR.APIKEY
      })
    },

    async REFRESH ({ dispatch }) {
      try {
        await Vue.api.KING.users.me()
        await TokenInjector.delete()
      } catch (_err) {
        await Vue.api.KING.logout()
        document.location.href = window.location.origin
      }
      dispatch('CHECK_CMP_SESSION', { refresh: true })
    },
    async LOGOUT_CMP () {
      await Vue.api.KING.logout()
      document.location.href = window.location.origin
    }
  },

  getters: {
    READY (state) { return (key = 'root') => state.ready[key] },
    READY_ORGANIZATION (state) { return state.ready.core_organizations }
  }
}
export default Store
