<template lang="pug">
.dataplant-task-list(
  v-if="!allBlocksHidden"
)
  fp-loader(v-if="loading")
  .content
    header(v-if="!dataplants.length")
      h4 {{ $t('onboarding.welcome.title', [currentUser.firstname || currentUser.lastname ]) }}
      span.description {{ $t('onboarding.welcome.description') }}

    .blocks
      .block.block-no-organization.active(v-if="organizationId == ''")
        no-organization
      .block(
        v-for="block in blocks"
        v-if="!block.hide()"
        :class='[block.active() ? activeClass : inactiveClass, block.cssClasses]'
      )
        i.fp4.fp4-xmark(
          v-if="block.displayCross ? block.displayCross() : false"
          @click="block.closeForever"
        )
        .title
          h4 {{ block.title }}
          .time
            i.fp4.fp4-clock-dark
            span {{ block.time }}
        .block-content
          p.description(v-html="$sanitize(block.description)")
          .cta-button
            fpui-button(
              :color="block.color || 'green'"
              @click="block.cta.click"
              :disabled='!block.active() || (block.cta.disabled ? block.cta.disabled() : false)'
            ) {{ block.cta.text }}
            .subtitle(v-if="block.cta.subtitle") {{ block.cta.subtitle() }}
</template>

<script>
import SubscriptionNew from '@/core/components/Subscription/New.vue'

export default {
  props: {
    organizationId: { type: String, default: '' }
  },
  data () {
    return {
      loading: false,
      activeClass: 'active',
      inactiveClass: 'inactive'
    }
  },
  computed: {
    organizations () {
      return this.$store.getters.ORGANIZATIONS
    },
    organization () {
      if (this.organizationId === '') return false
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    currentUser () {
      return this.$store.getters.SESSION
    },
    dataplants () {
      if (!this.organization) return []
      return this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId)
    },
    subscription () {
      return this.organization?.subscription || null
    },
    subscriptionStatus () {
      if (this.organization.bypass_billing) return 'active'
      if (!this.subscription) return null
      return this.subscription?.status
    },
    subscriptionInTrial () {
      return this.subscriptionStatus === 'in_trial'
    },
    hasValidPayment () {
      return this.subscription?.payment_valid || !(this.subscription?.scheduled_status === 'cancelled' && this.subscription?.status === 'in_trial')
    },
    roleInOrganization () {
      if (this.organizationId === '') return {}
      const user = this.$store.getters.SESSION
      if (!user) return {}
      const orga = this.$store.getters.ORGANIZATIONS
        .find(orga => orga._id === this.organizationId)
      if (!orga) return {}
      const orgaUser = orga.users.find(user => user.id === user.email)
      return orgaUser?.role || {}
    },
    blocks () {
      return [
        {
          id: 'invite-members',
          title: this.$t('onboarding.blocks.invite_teammates.title'),
          time: '1 minute',
          description: this.$t('onboarding.blocks.invite_teammates.description'),
          cssClasses: ['invite-teammates'],
          cta: {
            text: this.$t('onboarding.blocks.invite_teammates.cta'),
            click: () => {
              this.currentUser.update('onboarding.access.invite', true, true)
              this.$router.push(`/organization/${this.organizationId}/members`)
            }
          },
          hide: () => {
            // Hide if: For orga admin: when clicking on the button, for user: allways
            if (this.organizationId === '') return false
            const { steps_displayed } = this.currentUser.onboarding
            return this.roleInOrganization !== 'admin' || this.currentUser?.onboarding?.access?.invite || !steps_displayed?.invite_members
          },
          active: () => {
            // Actif if: There's a Dataplant on the orga && (There's an active subscription on the orga || The user already has a billing account with at least one sub)
            if (this.dataplants.length) return true
          },
          displayCross: () => {
            const { steps_displayed } = this.currentUser.onboarding
            if (this.subscription?.status === 'in_trial' || this.subscription?.status === 'cancelled') return false
            return steps_displayed?.invite_members && this.subscription
          },
          closeForever: () => {
            this.currentUser.update('onboarding.steps_displayed.invite_members', false, true)
          }
        }, {
          id: 'get-a-plan',
          title: this.$t('onboarding.blocks.scale.title'),
          time: '2 minutes',
          description: this.$t('onboarding.blocks.scale.description'),
          cssClasses: ['scaling'],
          color: 'blue-flash',
          cta: {
            text: this.$t('onboarding.blocks.scale.cta'),
            click: () => {
              this.createSubscription()
            }
          },
          hide: () => {
            // Hide if: User not admin of organization || User becomes part of an organization with an active subscription plan
            if (this.organizationId === '') return false
            if (this.subscription?.status !== 'in_trial') return true
            if (this.subscription?.status === 'in_trial' && this.subscription?.scheduled_status === 'cancelled' && this.subscription.payment_valid) return false
            if (this.hasValidPayment) return true
            return this.roleInOrganization !== 'admin'
          },
          active: () => {
            // Actif if: User is in an organization
            if (!this.organization) return false
            return true
          }
        }
      ]
    },
    allBlocksHidden () {
      return !this.blocks.some(b => !b.hide())
    }
  },
  async mounted () {
    if (this.organization) {
      this.loading = true
      await this.organization.loadSubscription()
      this.loading = false
    }
    // If trial expired, put the step Get a Plan in first (when status is `in_trial` only)
    if (this.subscription?.status === 'in_trial' && (this.subscription?.trial_end && this.subscription?.trial_end < Math.floor(Date.now() / 1000)) && this.blocks[0].id !== 'get-a-plan') {
      this.blocks.unshift(this.blocks.pop())
    }
  },
  methods: {
    createSubscription (hasTrial) {
      this.$modal.show(SubscriptionNew, {
        organizationId: this.organizationId,
        trial: hasTrial || this.subscriptionInTrial,
        refreshAfterComplete: true
      }, {
        height: 'auto',
        width: 900
      })
    }
  }
}
</script>

<style lang="less">
.dataplant-task-list {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: initial;
    .content {
      box-shadow: unset;
      background: transparent;
      margin-top: 21px !important;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      flex-direction: column;

      .dataplants-blocks {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        margin-bottom: 46px;
      }

      header {
        width: 526px;
        margin-bottom: 46px;
        text-align: center;
        h4 {
          font-size: 32px !important;
          font-weight: bold !important;
          letter-spacing: 0;
          line-height: 40px;
          color: #3E4550;
          margin-bottom: 21px;
        }
        span.description {
          font-size: 14px;
          line-height: 22px;
        }
      }

      .blocks {
        margin-bottom: 121px;
        .block {
          background-color: white;
          padding: 41px 500px 40px 65px;
          box-shadow: 0 6px 12px 0 rgba(52, 65, 78, 0.2);
          border-radius: 5px;
          position: relative;
          margin-bottom: 20px;
          &.active {
            box-shadow: inset 0 2px 0 0 #9EDF10, 0 6px 12px 0 rgba(52,65,78,0.2);

            &.scaling {
              box-shadow: inset 0 2px 0 0 @blue_flash, 0 6px 12px 0 rgba(52, 65, 78, 0.2);
            }
          }
          &.inactive {
            .title, .block-content {
              filter: grayscale(1) opacity(70%);
            }
          }
          .fp4-xmark {
            position: absolute;
            top: 15px;
            right: 20px;
            cursor: pointer;
            font-size: 20px;
            color: #CBD3DB;
          }
          .title {
            display: flex;
            align-content: center;
            align-items: center;
            h4 {
              font-size: 20px;
              color: @text-color;
              font-weight: 600;
              margin: 0;
              line-height: 20px;
            }
            .time {
              margin-left: 8px;
              color: @green;
              font-size: 14px;
              font-weight: 600;
              i {
                margin-right: 5px;
                vertical-align: -20%;
              }
            }
          }
          .block-content {
            p.description {
              margin: 15px 0 19px 0;
              font-size: 14px;
              line-height: 22px;
              a {
                text-decoration: revert;
                font-size: 14px;
              }
            }
            .cta-button {
              display: flex;
              flex-direction: row;
              flex-wrap: nowrap;
              justify-content: flex-start;
              align-content: center;
              align-items: center;
              .fpui-button {
                height: 38px;
                width: 230px;
              }
              .subtitle {
                padding-left: 15px;
                color: #B2BECA;
                font-size: 14px;
              }
            }
          }

          // Specific blocks
          &.block-no-organization {
            display: flex;
            justify-content: center;
            align-content: center;
            align-items: center;
            padding: 0 65px 20px 65px;
            > .no-organization {
              width: 100%;
              background-size: 433.333333333px 170.666666667px;
              h4 {
                margin-top: 220px !important;
              }
            }
          }

          // Specific blocks
          &.free-trial {
            background-image: url('~@/core/assets/img/onboarding/free-trial-bg.png');
            background-repeat: no-repeat;
            background-position: center right 100px;
            background-size: 286px 210px;
          }
          &.visualize-data {
            background-image: url('~@/core/assets/img/onboarding/visualize-bg.png');
            background-repeat: no-repeat;
            background-position: center right 100px;
            background-size: 286px 190px;
          }
          &.invite-teammates {
            background-image: url('~@/core/assets/img/onboarding/invite-bg.png');
            background-repeat: no-repeat;
            background-position: center right 30px;
            background-size: 440px 150px;
          }
          &.scaling {
            background-image: url('~@/core/assets/img/onboarding/scale-bg.png');
            background-repeat: no-repeat;
            background-position: top right -30px;
            background-size: 80%;
            .time {
              color: @blue_flash;
            }
          }
        }
      }
    }
  }
</style>
