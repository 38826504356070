<template lang="pug">
.kpi.mlm(
  v-tooltip="$t('project.home.mlm.kpi_tooltip_left')"
)
  fp-loader(v-if="loading" type="chart")
  .value(
    v-if="!loading"
  ) {{ value }}
  .text(
    v-if="!loading"
  ) {{ $t('project.home.mlm.running_ntebooks', [value], isNaN(value) ? 0 : value) }}
</template>

<script>
export default {
  computed: {
    value () {
      // Value are refreshed in MlmMainTable when resize card
      return this.$store.getters.PROJECT_HOME_NOTEBOOKS?.filter(n => n.status === 'STARTED')?.length ?? '-'
    },
    loading () {
      return this.$store.getters.PROJECT_HOME_NOTEBOOKS_LOADING
    }
  }
}
</script>

<style lang="less">

</style>
