<template lang="pug">
.left-button.api
  fpui-button(
    color="white"
    :disabled="!canAppserviceApplicationsCreate || !serviceReady || editing"
    icon-left
    icon="fp4 fp4-plus"
    v-tooltip="{ content: !canAppserviceApplicationsCreate ? $t('dashboard.access.tooltip') : $t('project.home.api.button_tooltip_left'), delay: 0 }"
    @click="onClick"
  ) {{ $t('project.home.api.create') }}
</template>

<script>
export default {
  props: {
    editing: { type: Boolean, required: true }
  },
  computed: {
    projectId () {
      return this.$route.params?.projectId
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    serviceReady () {
      return !Object.keys(this.project?.display_settings?.services_wizard || {}).includes('api') && !Object.keys(this.project?.display_settings?.services_wizard || {}).includes('app')
    },
    canAppserviceApplicationsCreate () {
      return this.$store.getters.ACL('canAppserviceApplicationsCreate')
    }
  },
  methods: {
    onClick () {
      this.$analytics.track('Click on project home card’s shortcut CTA', {
        service: 'API',
        action: 'create an api'
      })
      this.$emit('click')
    }
  }
}
</script>

<style lang="less">

</style>
