<template lang="pug">
  .cell-image-text
    img(v-if="image" :src='image')
    .text {{ text }}
</template>
<script>
export default {
  props: {
    text: { type: String, default: '' },
    image: { type: String, default: '' }
  }
}
</script>
<style lang="less">
  .cell-image-text {
    display: flex;
    align-items: center;
    img {
      margin-right: 5px;
      width: 31px;
    }
    .text {
      text-transform: capitalize;
    }
  }
</style>
