import Services from '@/core/helpers/services.js'

export const defaultCategories = () => {
  return Services.map(s => s.category)
}

export const getDefaultCategoriesForService = (service) => {
  let category = ''
  Services.forEach(categ => {
    const services = categ.services.map(s => s.id)
    if (services.includes(service)) category = categ.category
    if (service === 'gab' && services.includes('aab')) category = categ.category
  })

  return category || 'custom'
}

export const getCategoryColor = (category) => {
  return Services.find(s => s.category === category)?.color || '#B2BECA'
}

export const getDefaultTemplateForServices = (services) => {
  if (!Array.isArray(services)) return

  // All service list
  // DM / data-catalog / lakehouse / AM / DPE / Appservice (API + APP) / MLM / CC / IAM => 9 in total -> 10 with GAB
  // TODO: do a -1 when removing the DM later

  // End-to-end
  if ((services.length === 8 && !services.includes('gab') && !services.includes('mlm')) ||
    (services.length === 9 && ((services.includes('gab') && !services.includes('mlm')) || (!services.includes('gab') && services.includes('mlm')))) ||
    (services.length === 10 && services.includes('gab'))
  ) { // 10 is for GAB in dev
    const template = [
      { x: 0, y: 0, width: 2, height: 2, i: 'analytics', color: getCategoryColor('analytics'), type: 'category' },
      { x: 0, y: 0, width: 1, height: 1, i: 'data-catalog', category: 'analytics', type: 'service' },
      { x: 0, y: 1, width: 1, height: 1, i: 'lakehouse', category: 'analytics', type: 'service' },
      { x: 1, y: 1, width: 1, height: 1, i: 'am', category: 'analytics', type: 'service' },
      { x: 1, y: 0, width: 1, height: 1, i: 'dpe', category: 'analytics', type: 'service' },
      { x: 2, y: 0, width: 2, height: 2, i: 'application-services', color: getCategoryColor('application-services'), type: 'category' },
      { x: 2, y: 0, width: 1, height: 2, i: 'api', category: 'application-services', type: 'service' },
      { x: 3, y: 0, width: 1, height: 2, i: 'app', category: 'application-services', type: 'service' },
      { x: 0, y: 2, width: 3, height: 2, i: 'admin-tools', color: getCategoryColor('admin-tools'), type: 'category' },
      { x: 0, y: 2, width: 1, height: 2, i: 'control-center', category: 'admin-tools', type: 'service' },
      { x: 1, y: 2, width: 2, height: 2, i: 'iam', category: 'admin-tools', type: 'service' }
    ]

    // If we have either MLM or GAB in the list of the services
    if (services.includes('mlm') || services.includes('gab')) {
      // We push the AI category
      template.push({ x: 0, y: 2, width: 1, height: 2, i: 'artificial-intelligence', color: getCategoryColor('artificial-intelligence'), type: 'category' })

      // We then correctly position the gab && || mlm depending on which components we have
      if (services.includes('mlm') && services.includes('gab')) {
        template.push({ x: 0, y: 2, width: 1, height: 1, i: 'mlm', category: 'artificial-intelligence', type: 'service' })
        template.push({ x: 0, y: 3, width: 1, height: 1, i: 'gab', category: 'artificial-intelligence', type: 'service' })
      } else if (services.includes('mlm')) {
        template.push({ x: 0, y: 2, width: 1, height: 2, i: 'mlm', category: 'artificial-intelligence', type: 'service' })
      } else if (services.includes('gab')) {
        template.push({ x: 0, y: 2, width: 1, height: 2, i: 'gab', category: 'artificial-intelligence', type: 'service' })
      }

      // And we move the admin tools category to the right
      const adminToolsIndex = template.findIndex(t => t.i === 'admin-tools')
      if (adminToolsIndex > -1) template[adminToolsIndex] = { x: 1, y: 2, width: 3, height: 2, i: 'admin-tools', color: getCategoryColor('admin-tools'), type: 'category' }

      const controlCenterIndex = template.findIndex(t => t.i === 'control-center')
      if (controlCenterIndex > -1) template[controlCenterIndex] = { x: 1, y: 2, width: 1, height: 2, i: 'control-center', category: 'admin-tools', type: 'service' }

      const iamIndex = template.findIndex(t => t.i === 'iam')
      if (iamIndex > -1) template[iamIndex] = { x: 2, y: 2, width: 2, height: 2, i: 'iam', category: 'admin-tools', type: 'service' }
    }

    return template
  }

  // Machine Learning template
  if (
    (services.length === 8 && services.includes('dm') && services.includes('dpe') && services.includes('mlm') && !services.includes('gab') && services.includes('appservice') && services.includes('control-center') && services.includes('iam') && !services.includes('am')) ||
    (services.length === 8 && services.includes('dm') && services.includes('dpe') && services.includes('gab') && !services.includes('mlm') && services.includes('appservice') && services.includes('control-center') && services.includes('iam') && !services.includes('am')) ||
    (services.length === 9 && services.includes('dm') && services.includes('dpe') && services.includes('mlm') && services.includes('gab') && services.includes('appservice') && services.includes('control-center') && services.includes('iam') && !services.includes('am'))
  ) {
    const template = [
      { x: 0, y: 0, width: 1, height: 2, i: 'analytics', color: getCategoryColor('analytics'), type: 'category' },
      { x: 0, y: 0, width: 1, height: 1, i: 'data-catalog', category: 'analytics', type: 'service' },
      { x: 0, y: 1, width: 1, height: 1, i: 'lakehouse', category: 'analytics', type: 'service' },
      { x: 1, y: 0, width: 1, height: 2, i: 'dpe', category: 'analytics', type: 'service' },
      { x: 2, y: 0, width: 2, height: 2, i: 'application-services', color: getCategoryColor('application-services'), type: 'category' },
      { x: 2, y: 0, width: 1, height: 2, i: 'api', category: 'application-services', type: 'service' },
      { x: 3, y: 0, width: 1, height: 2, i: 'app', category: 'application-services', type: 'service' },
      { x: 2, y: 2, width: 2, height: 2, i: 'admin-tools', color: getCategoryColor('admin-tools'), type: 'category' },
      { x: 2, y: 2, width: 1, height: 2, i: 'control-center', category: 'admin-tools', type: 'service' },
      { x: 3, y: 2, width: 1, height: 2, i: 'iam', category: 'admin-tools', type: 'service' }
    ]

    // If we have either MLM or GAB in the list of the services
    if (services.includes('mlm') || services.includes('gab')) {
      // We push the AI category
      template.push({ x: 0, y: 2, width: 1, height: 2, i: 'artificial-intelligence', color: getCategoryColor('artificial-intelligence'), type: 'category' })

      // We then correctly position the gab && || mlm depending on which components we have
      if (services.includes('mlm') && services.includes('gab')) {
        template.push({ x: 0, y: 2, width: 1, height: 1, i: 'mlm', category: 'artificial-intelligence', type: 'service' })
        template.push({ x: 0, y: 3, width: 1, height: 1, i: 'gab', category: 'artificial-intelligence', type: 'service' })
      } else if (services.includes('mlm')) {
        template.push({ x: 0, y: 2, width: 1, height: 2, i: 'mlm', category: 'artificial-intelligence', type: 'service' })
      } else if (services.includes('gab')) {
        template.push({ x: 0, y: 2, width: 1, height: 2, i: 'gab', category: 'artificial-intelligence', type: 'service' })
      }

      // And we move the admin tools category to the right
      const adminToolsIndex = template.findIndex(t => t.i === 'admin-tools')
      if (adminToolsIndex > -1) template[adminToolsIndex] = { x: 1, y: 2, width: 3, height: 2, i: 'admin-tools', color: getCategoryColor('admin-tools'), type: 'category' }

      const controlCenterIndex = template.findIndex(t => t.i === 'control-center')
      if (controlCenterIndex > -1) template[controlCenterIndex] = { x: 1, y: 2, width: 1, height: 2, i: 'control-center', category: 'admin-tools', type: 'service' }

      const iamIndex = template.findIndex(t => t.i === 'iam')
      if (iamIndex > -1) template[iamIndex] = { x: 2, y: 2, width: 2, height: 2, i: 'iam', category: 'admin-tools', type: 'service' }
    }

    return template
  }

  // Analytics template
  if (services.length === 5 && services.includes('dm') && services.includes('dpe') && services.includes('am') && services.includes('control-center') && services.includes('iam')) {
    return [
      { x: 0, y: 0, width: 3, height: 2, i: 'analytics', color: getCategoryColor('analytics'), type: 'category' },
      { x: 0, y: 0, width: 1, height: 1, i: 'data-catalog', category: 'analytics', type: 'service' },
      { x: 0, y: 1, width: 1, height: 1, i: 'lakehouse', category: 'analytics', type: 'service' },
      { x: 2, y: 0, width: 2, height: 2, i: 'am', category: 'analytics', type: 'service' },
      { x: 1, y: 0, width: 1, height: 2, i: 'dpe', category: 'analytics', type: 'service' },
      { x: 0, y: 2, width: 3, height: 2, i: 'admin-tools', color: getCategoryColor('admin-tools'), type: 'category' },
      { x: 0, y: 2, width: 1, height: 2, i: 'control-center', category: 'admin-tools', type: 'service' },
      { x: 1, y: 2, width: 2, height: 2, i: 'iam', category: 'admin-tools', type: 'service' }
    ]
  }

  // Single service template
  if (services.length === 3 && services.includes('control-center') && services.includes('iam')) {
    const tiles = [
      { x: 2, y: 0, width: 2, height: 2, i: 'admin-tools', color: getCategoryColor('admin-tools'), type: 'category' },
      { x: 2, y: 0, width: 1, height: 2, i: 'control-center', category: 'admin-tools', type: 'service' },
      { x: 3, y: 0, width: 1, height: 2, i: 'iam', category: 'admin-tools', type: 'service' }
    ]

    if (services.includes('dm') || services.includes('am') || services.includes('dpe')) {
      tiles.push({ x: 0, y: 0, width: 2, height: 2, i: 'analytics', color: getCategoryColor('analytics'), type: 'category' })
      if (services.includes('dm')) {
        tiles.push({ x: 0, y: 0, width: 1, height: 2, i: 'data-catalog', category: 'analytics', type: 'service' })
        tiles.push({ x: 1, y: 0, width: 1, height: 2, i: 'lakehouse', category: 'analytics', type: 'service' })
      }
      if (services.includes('am')) tiles.push({ x: 0, y: 0, width: 2, height: 2, i: 'am', category: 'analytics', type: 'service' })
      if (services.includes('dpe')) tiles.push({ x: 0, y: 0, width: 2, height: 2, i: 'dpe', category: 'analytics', type: 'service' })
    }

    if (services.includes('mlm') || services.includes('gab')) {
      tiles.push({ x: 0, y: 0, width: 2, height: 2, i: 'artificial-intelligence', color: getCategoryColor('artificial-intelligence'), type: 'category' })
      if (services.includes('mlm')) tiles.push({ x: 0, y: 0, width: 1, height: 2, i: 'mlm', category: 'artificial-intelligence', type: 'service' })
      if (services.includes('gab')) tiles.push({ x: 0, y: 0, width: 1, height: 2, i: 'gab', category: 'artificial-intelligence', type: 'service' })
    }

    if (services.includes('appservice')) {
      tiles.push({ x: 0, y: 0, width: 2, height: 2, i: 'application-services', color: getCategoryColor('application-services'), type: 'category' })
      tiles.push({ x: 0, y: 0, width: 1, height: 2, i: 'api', category: 'application-services', type: 'service' })
      tiles.push({ x: 0, y: 0, width: 1, height: 2, i: 'app', category: 'application-services', type: 'service' })
    }

    return tiles
  }

  const tiles = [
    { x: 0, y: 2, width: 3, height: 2, i: 'admin-tools', color: getCategoryColor('admin-tools'), type: 'category' },
    { x: 0, y: 2, width: 1, height: 2, i: 'control-center', category: 'admin-tools', type: 'service' },
    { x: 1, y: 2, width: 2, height: 2, i: 'iam', category: 'admin-tools', type: 'service' }
  ]

  if (services.includes('dm') || services.includes('am') || services.includes('dpe')) {
    tiles.push({ x: 0, y: 0, width: 1, height: 2, i: 'analytics', color: getCategoryColor('analytics'), type: 'category' })
    if (services.includes('dm')) {
      tiles.push({ x: 0, y: 0, width: 1, height: 1, i: 'data-catalog', category: 'analytics', type: 'service' })
      tiles.push({ x: 0, y: 1, width: 1, height: 1, i: 'lakehouse', category: 'analytics', type: 'service' })
    }
    if (services.includes('am')) tiles.push({ x: 1, y: 0, width: 1, height: 1, i: 'am', category: 'analytics', type: 'service' })
    if (services.includes('dpe')) tiles.push({ x: 1, y: 1, width: 1, height: 1, i: 'dpe', category: 'analytics', type: 'service' })
  }

  if (services.includes('appservice')) {
    tiles.push({ x: 3, y: 0, width: 1, height: 4, i: 'application-services', color: getCategoryColor('application-services'), type: 'category' })
    tiles.push({ x: 3, y: 0, width: 1, height: 2, i: 'api', category: 'application-services', type: 'service' })
    tiles.push({ x: 3, y: 2, width: 1, height: 2, i: 'app', category: 'application-services', type: 'service' })
  }

  if (services.includes('mlm') || services.includes('gab')) {
    tiles.push({ x: 1, y: 0, width: 1, height: 2, i: 'artificial-intelligence', color: getCategoryColor('artificial-intelligence'), type: 'category' })
    if (services.includes('mlm') && services.includes('gab')) {
      tiles.push({ x: 1, y: 0, width: 1, height: 1, i: 'mlm', category: 'artificial-intelligence', type: 'service' })
      tiles.push({ x: 1, y: 1, width: 1, height: 1, i: 'gab', category: 'artificial-intelligence', type: 'service' })
    } else if (services.includes('mlm')) tiles.push({ x: 1, y: 0, width: 1, height: 2, i: 'mlm', category: 'artificial-intelligence', type: 'service' })
    else if (services.includes('gab')) tiles.push({ x: 1, y: 0, width: 1, height: 2, i: 'gab', category: 'artificial-intelligence', type: 'service' })
  }

  return tiles
}
