<template lang="pug">
.widget-options-container
  i.fp4.fp4-ellipsis(
    @click="open"
  )
  .tile-options(
    v-if="showOptions"
    ref="widget-options"
  )
    .options-list
      ul
        li(
          v-for="opt in options"
          v-if="!opt.hidden"
          :class="{ disabled: opt.disabled, [opt.id]: true }"
          :key="opt.label"
          @click="() => opt.action()"
        )
          i.fp4(
            v-if="opt.icon"
            :class="opt.icon"
          )
          span {{ opt.label }}
</template>

<script>
export default {
  props: {
    tile: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      showOptions: false
    }
  },
  computed: {
    options () {
      return [
        {
          id: 'delete',
          label: this.$t('home.orga.widgets.delete_tile'),
          icon: 'fp4-trash-can',
          action: async () => {
            this.$emit('delete-tile', this.tile)
            this.showOptions = false
          }
        }
      ]
    }
  },
  async mounted () {
    document.addEventListener('click', this.close)
  },
  destroyed () {
    document.removeEventListener('click', this.close)
  },
  methods: {
    async open () {
      this.showOptions = !this.showOptions
      this.$emit('active-tile', this.tile)
      await this.$nextTick()
      // Check if the project options dropdown goes out of screen
      const el = this.$refs['widget-options']
      if (el) {
        const rect = el.getBoundingClientRect()
        if ((rect.x + rect.width + 2) > window.innerWidth) el.style.right = '0'
      }
    },
    close (event) {
      if (event && this.isParent(event.target)) return
      this.showOptions = false
      this.$emit('deactive-tile', this.tile)
    },
    isParent (target) {
      if (target === this.$el) return true
      if (target.parentNode) return this.isParent(target.parentNode)
      return false
    }
  }
}
</script>

<style lang="less">
.widget-options-container {
  .fp4-ellipsis {
    font-size: 20px;
    color: #CBD3DB;
    cursor: pointer;
    display: none;

    &:hover {
      color: #0089c0;
    }
  }
  .tile-options {
    width: auto;
    background: white;
    box-shadow: 0px 3px 13px rgba(151, 167, 183, 0.3);
    border-radius: 7px;
    position: absolute;
    top: 18px;
    right: -70px;
    z-index: 10;

    .options-list {
      ul {
        padding: 0;
        margin: 0;
        li {
          display: flex;
          justify-content: center;
          align-items: center;
          list-style: none;
          padding: 12px 20px;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.01em;
          color: #3E4550;
          cursor: pointer;

          i {
            font-size: 18px;
          }

          span {
            padding-left: 10px;
          }

          &:last-of-type {
            border-bottom-left-radius: 7px;
            border-bottom-right-radius: 7px;
          }

          &.disabled {
            border: unset !important;
            cursor: not-allowed !important;
            color: rgba(62,69,80,0.65);
          }

          &.delete {
            color: @red;
            &:hover {
              color: darken(@red, 15%);
            }
          }
        }
      }
    }
  }
}
</style>
