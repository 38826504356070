<template lang="pug">
  .no-subscription
    .content
      h4 {{ $t('dataplant.create.welcome') }}
      p {{ $t('dataplant.create.text') }}
      .button
        fpui-button(
          color="green"
          @click="createSubscription"
          auto-width
        ) {{ $t('dataplant.create.get_started_now') }}
</template>

<script>
import SubscriptionNew from '@/core/components/Subscription/New.vue'

export default {
  props: {
    organizationId: { type: String, required: true },
    upgradePlan: { type: Boolean, required: false }
  },
  mounted () {
    if (this.$route.query?.new_subscription) {
      this.$modal.show(SubscriptionNew, {
        organizationId: this.organizationId,
        trial: this.upgradePlan
      }, {
        height: 'auto',
        width: 900
      })
    }
  },
  methods: {
    createSubscription () {
      this.$modal.show(SubscriptionNew, {
        organizationId: this.organizationId,
        trial: this.upgradePlan
      }, {
        height: 'auto',
        width: 900
      })
    }
  }
}
</script>

<style lang="less">
.no-subscription {
    display: flex;
    justify-content: center;
    height: 100%;
    width: 100%;
    background-image: url('./assets/welcome@2x.png');
    background-position: 50% 50px;
    background-repeat: no-repeat;
    background-size: 775px 230px;
    .content {
      box-shadow: unset;
      background: transparent;
      margin-top: 256px!important;
      text-align: center;
      width: 526px;

      h4 {
        font-size: 32px !important;
        font-weight: bold !important;
        letter-spacing: 0;
        line-height: 40px;
        color: #3E4550;
        margin-bottom: 21px;
      }

      p {
        font-size: 14px;
        letter-spacing: 0;
        line-height: 22px;
        color: #3E4550;
        margin: 0;
      }

      .button {
        display: flex;
        justify-content: center;
        .fpui-button {
          margin-top: 50px;
          margin-bottom: 20px;
        }
      }
    }
  }
</style>
