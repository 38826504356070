import AmLeftButton from './AmLeftButton.vue'
import AmRightButton from './AmRightButton.vue'
import AmMainTable from './AmMainTable.vue'
import AmSecondTable from './AmSecondTable.vue'
import AmLeftKpi from './AmLeftKpi.vue'
import AmRightKpi from './AmRightKpi.vue'

export default {
  AmLeftButton,
  AmRightButton,
  AmMainTable,
  AmSecondTable,
  AmLeftKpi,
  AmRightKpi
}

export {
  AmLeftButton,
  AmRightButton,
  AmMainTable,
  AmSecondTable,
  AmLeftKpi,
  AmRightKpi
}
