<template lang="pug">
.kpi.am(
  v-tooltip="isSecond ? $t('project.home.am.kpi_tooltip_second') : $t('project.home.am.kpi_tooltip_minute')"
)
  fp-loader(v-if="loading" type="chart")
  .value(
    v-if="!loading"
  ) {{ value }}
  .text(
    v-if="!loading"
  ) {{ isSecond ? $t('project.home.am.queries_per_second') : $t('project.home.am.queries_per_minute') }}
</template>

<script>
import moment from 'moment'

import Config from '@/shared/Config'

export default {
  data () {
    return {
      config: null
    }
  },
  computed: {
    trinoActive () {
      return this.$store.getters.PROJECT_TRINO_ACTIVE
    },
    hasTrinoOrMutualized () {
      return !!this.$store.getters.QB_RESOURCES_ACTIVE_OR_MUTUALIZED
    },
    isAPHP () {
      return this.hasTrinoOrMutualized && this.config?.CLUSTER === 'k8s-aphp'
    },
    isDataPlatform () {
      return this.hasTrinoOrMutualized && this.config?.DATA_PLATFORM_ACCESS
    },
    queriesHistory () {
      return this.$store.getters.PROJECT_HOME_QUERY_HISTORY
    },
    loading () {
      return this.$store.getters.PROJECT_HOME_QUERY_HISTORY_LOADING
    },
    queriesLastHour () {
      const date1HourAgo = moment().subtract(60, 'minutes')
      const queriesHistoryLastHour = this.queriesHistory.filter(qh => moment(qh.created_at).isAfter(date1HourAgo))
      return queriesHistoryLastHour.length
    },
    value () {
      if (!this.trinoActive && !this.isAPHP && !this.isDataPlatform) return '-'

      let value = Math.ceil(this.queriesLastHour / 3600)
      if (value <= 1) value = Math.ceil(this.queriesLastHour / 60)

      try {
        value = value.toFixed(2)
      } catch (err) {}

      return value
    },
    isSecond () {
      return Math.ceil(this.queriesLastMinute / 60) >= 1
    }
  },
  async mounted () {
    this.config = await Config()
  }
}
</script>

<style lang="less">

</style>
