<template lang="pug">
.dataplants-tree(:class='{ "has-no-subscription": !subscription }')
  fp-loader(v-if="pageLoading", type="page")
  fp-title(
    :border="false"
    :parent="$t('organization.dataplants')"
  )
    .actions-buttons(v-show="subscription && !pageLoading")
      fpui-input-search(
        v-model='searchValue'
      )
      fpui-button(
        :auto-width="true"
        :disabled="!canCreateDataplant"
        v-tooltip.left-start="!canCreateDataplant ? $t('home.dataplant.label.new_dataplant.no_rights') : ''"
        color='blue-flash'
        icon='fp4 fp4-plus'
        icon-left
        @click="add"
      ) {{ $t('home.dataplant.label.new_dataplant') }}
  no-subscription(
    :organization-id="organizationId"
    v-if="!subscription && !pageLoading"
  )
  .resource-content(v-if='subscription && !pageLoading')
    fpui-tree-view(
      :no-header='true'
      :columns='columns'
      :value="value"
      :actions="actions"
      :draggable="false"
      icon="fp4 fp4-dataplants"
      type="projects"
      :parser="{ id: 'id' }"
      @add="add"
      @edit="edit"
    )
</template>

<script>
import moment from 'moment'
import Bytes from '@/shared/filters/Bytes'
import CellName from './CellName'
import DataplantCreationFullscreen from '@/shared/components/project/DataplantCreationFullscreen/DataplantCreationFullscreen.vue'
import CannotDoInTrialSubscription from '@/core/components/CannotDoInTrialSubscription.vue'
import NoSubscription from '@/core/components/Home/NoSubscription'

export default {
  components: {
    NoSubscription
  },
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      searchValue: '',
      pageLoading: true,
      subscription: null
    }
  },
  computed: {
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    value () {
      if (!this.searchValue) return this.dataplants
      return this.dataplants.filter(dataplant => `${dataplant._id}-${dataplant.name}`.toLowerCase().includes(this.searchValue.toLowerCase()))
    },
    columns () {
      return [
        {
          label: this.$t('organization.dataplants.name'),
          grow: 2,
          sortable: (row) => row.name,
          target: CellName
        },
        {
          label: this.$t('organization.dataplants.fpu'),
          grow: 1,
          sortable: true,
          target: (row) => {
            return row.fpu
          }
        },
        {
          label: this.$t('organization.dataplants.storage'),
          grow: 1,
          sortable: true,
          target: (row) => {
            return Bytes(row.datatank * 1024 * 1024 * 1024, 0)
          }
        },
        {
          label: this.$t('organization.dataplants.created'),
          grow: 1,
          sortable: (row) => {
            return row.created_at
          },
          target: (row) => {
            return moment(row.created_at).format(this.$t('date.formatter.short'))
          }
        }
      ]
    },
    actions () {
      return {
        folder: {
          if () { return false }
        },
        copy: {
          if () { return false }
        },
        remove: { // Can't remove dp from treeview
          if: () => false
        }
      }
    },
    dataplants () {
      return this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId)
    },
    canCreateDataplant () {
      return this.$acl(this.organizationId).can('core', 'dataplants', 'create')
    }
  },
  async mounted () {
    this.$store.commit('SET_OPEN_SIDE_BAR', false)
    this.pageLoading = true
    if (!this.organization.subscription) {
      await this.organization.loadSubscription()
    }
    this.subscription = this.organization?.subscription
    this.pageLoading = false
  },
  methods: {
    edit (item) {
      this.$router.push(`/project/${item.id}`)
    },
    add () {
      if ((['active', 'non_renewing'].includes(this.subscription?.status)) || (this.subscription?.status === 'in_trial' && this.subscription?.payment_valid && this.subscription?.scheduled_status !== 'cancelled') || (this.subscription?.status === 'in_trial' && this.subscription?.scheduled_status === 'cancelled' && !this.dataplants.length)) {
        this.$analytics.track('Open new project funnel', { from: 'organization home', channel: 'new project' })
        this.$modal.show(DataplantCreationFullscreen, {
          organizationId: this.organizationId
        }, {
          height: '100%',
          width: '100%',
          adaptative: true,
          classes: []
        })
      } else {
        this.$modal.show(CannotDoInTrialSubscription, {
          organizationId: this.organizationId
        }, {
          height: '323px',
          width: '472px'
        })
      }
    }
  }
}
</script>

<style lang="less">
  .dataplants-tree{
    height: 100%;
    overflow: hidden;
    &.has-no-subscription {
      background: linear-gradient(0deg, rgba(246, 249, 252, 0) 0%, #F6F9FC 100%) !important;
    }

    .no-subscription {
      height: calc(100% - 87px);
    }

    .resource-content {
      height: calc(100% - 120px);
      text-align: left;
    }
  }
</style>
