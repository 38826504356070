<template lang="pug">
.kpi.dpe(
  v-tooltip="$t('project.home.dpe.kpi_tooltip_left')"
)
  fp-loader(v-if="loading" type="chart")
  .value(
    v-if="!loading"
  ) {{ value }}
  .text(
    v-if="!loading"
  ) {{ $t('project.home.dpe.scheduled_triggers', [value], isNaN(value) ? 0 : value) }}
</template>

<script>
export default {
  data () {
    return {
      value: '-',
      loading: false
    }
  },
  computed: {
    projectId () {
      return this.$route.params?.projectId
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    serviceReady () {
      return !Object.keys(this.project?.display_settings?.services_wizard || {}).includes('dpe')
    },
    canDpeJobRead () {
      return this.$store.getters.ACL('canDpeJobRead')
    }
  },
  watch: {
    serviceReady: {
      handler (val) {
        if (val) this.load()
      }
    },
    '$store.getters.PROJECT_CARD_INFO_LOADING': {
      handler (val) {
        if (!val) this.load()
      }
    }
  },
  async mounted () {
    if (!this.$store.getters.PROJECT_CARD_INFO_LOADING) await this.load()
  },
  methods: {
    async load () {
      if (this.canDpeJobRead && this.serviceReady) {
        this.loading = true

        try {
          const jobsScheduled = await this.$api.DPE.events.list()
          this.value = jobsScheduled.filter(j => j.active && j.type === 'cron').length ?? '-'
        } catch (err) {
          this.value = '-'
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="less">

</style>
