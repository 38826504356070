<template lang="pug">
  .organization
    fp-title(
      :border="true"
      :parent="$t('organization.overview')"
    )
    .page-content
      .kpis
        .fp-container
          .fp-column.graph-col
            .fixed-height.fp-box
              .box-title.center.first {{ 'organization.users' | translate }}
              .box-stats
                .box-stats-items
                  .value {{ kpis.users.count }}
                  .label {{ 'organization.users' | translate }}
                .box-stats-items
                  .value {{ kpis.users.admin }}
                  .label {{ 'organization.admins' | translate }}
                .box-stats-items
                  .value {{ kpis.users.active }}
                  .label {{ 'organization.active_users' | translate }}
              .box-title.big {{ 'organization.storage_consumption_over_time' | translate }}
              .box-content
                consumption-chart(:loading="loading" type="storage" :organization-id="organizationId")

          .fp-column.graph-col
            .fixed-height.fp-box
              .box-title.center.first {{ 'organization.dataplants' | translate }}
              .box-stats
                .box-stats-items
                  .value {{ kpis.dataplants.count }}
                  .label {{ 'organization.dataplants' | translate }}
                .box-stats-items
                  .value {{ kpis.dataplants.apps }}
                  .label {{ 'organization.apps' | translate }}
                .box-stats-items
                  .value {{ kpis.dataplants.models }}
                  .label {{ 'organization.models' | translate }}
              .box-title.big {{ 'organization.dataplant_consumption_over_time' | translate }}
              .box-content
                consumption-chart(:loading="loading" type="dataplant" :organization-id="organizationId")
          .fp-column.graph-col.box-relative
            .fixed-height.fp-box
              .box-title.center.first {{ 'organization.resources' | translate }}
              .box-stats
                .box-stats-items
                  .value {{ kpis.resources.memory }} {{ 'unit.gb' | translate }}
                  .label {{ 'organization.memory' | translate }}
                .box-stats-items
                  .value {{ kpis.resources.cpu }}
                  .label {{ 'organization.cpus' | translate }}
              .box-title.big {{ 'organization.fpu_consumption_over_time' | translate }}
              .box-content
                fpui-input-select.select-type-fpu(
                  v-model="ctype"
                  :clearable="false"
                  :options="ctypes"
                )
                consumption-chart(v-if="ctype === 'fpu-s'" :loading="loading" type="fpu" :organization-id="organizationId")
                consumption-chart(v-if="ctype === 'fpu-g'" :loading="loading" type="fpug" :organization-id="organizationId")
      .dataplants-current-usage.fp-container
        .fp-column
          .fp-no-padding.full.fp-box
            .box-title {{ 'organization.dataplants_current_usage' | translate }}
            .box-content(v-if="!$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(organizationId).length")
              .placeholder
                img(:src="require('@/core/assets/img/placeholder_chart.png')")
                .text {{ 'echarts.no-activity' | translate }}
            .box-content(v-if="$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(organizationId).length")
              .reverse-table
                .vhead
                  .vr
                    .vh.th {{ 'organization.dataplant' | translate | capitalize }}
                    .vh.td(
                      :class="{ odd: $index%2 == 0, even: $index%2 == 1 }"
                      v-for="(dataplant, $index) in $store.getters.DATAPLANTS_BY_ORGANIZATION_ID(organizationId)"
                    ) {{dataplant.name}}
                  .vr
                    .vh.th {{ 'organization.fpu_usage' | translate }}
                    .vh.td(
                      :class="{ odd: $index%2 == 0, even: $index%2 == 1 }"
                      v-for="(dataplant, $index) in $store.getters.DATAPLANTS_BY_ORGANIZATION_ID(organizationId)"
                    ) {{dataplant.getUsageDisplay()}}
                .vbody
                  .vr(v-for="srv in serviceNames")
                    .vd.th {{'organization.'+srv|translate}}
                    .vd.td(
                      :class="{ odd: $index%2 == 0, even: $index%2 == 1 }"
                      :style='{"background-color": getUsageBackgroundColor(dataplant, srv), color: getUsageColor(dataplant, srv)}'
                      v-for="(dataplant, $index) in $store.getters.DATAPLANTS_BY_ORGANIZATION_ID(organizationId)"
                    ) {{ dataplant.getUsageDisplay(srv) }}
</template>

<script>
import moment from 'moment'
import _sum from 'lodash/sum'
import _uniq from 'lodash/uniq'
import _flatten from 'lodash/flatten'
import _concat from 'lodash/concat'
import ConsumptionChart from './ConsumptionChart.vue'
export default {
  components: {
    ConsumptionChart
  },
  props: {
    organizationId: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      ctype: 'fpu-s',
      loading: true,
      ctypes: [{
        label: this.$t('organization.plan.billing.fpus'),
        value: 'fpu-s'
      }, {
        label: this.$t('organization.plan.billing.fpug'),
        value: 'fpu-g'
      }]
    }
  },
  computed: {
    serviceNames () { return _uniq(_flatten(_concat(this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId).map(dp => dp.serviceNames)))) },
    organization () {
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    users () {
      return this.organization?.accounts || this.organization?.users || []
    },
    consumptions () {
      return this.organization?.consumptions
    },
    kpis () {
      const resourcesStorage = this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId)

      return {
        users: {
          count: this.users.length,
          admin: this.users.filter(acc => acc.role === 'admin').length,
          active: this.users.filter(acc => {
            if (!acc.last_connected_at) return false
            const daySinceLastConnect = (moment() - moment(acc.last_connected_at)) / 3600000 / 24
            return daySinceLastConnect < 7
          }).length
        },
        dataplants: {
          count: this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId).length,
          apps: _sum(this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId).map(dp => dp.apps)),
          apis: _sum(this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId).map(dp => dp.apis)),
          models: _sum(this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId).map(dp => dp.models))
        },
        resources: {
          fpu: _sum(this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId).map(dp => dp.fpu)),
          memory: _sum(this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId).map(dp => dp.memory)),
          cpu: _sum(this.$store.getters.DATAPLANTS_BY_ORGANIZATION_ID(this.organizationId).map(dp => dp.cpu)),
          storage: isNaN(resourcesStorage) ? '-' : resourcesStorage
        }
      }
    }
  },
  watch: {
    async organizationId () {
      await this.loadOrganizationData()
    }
  },
  async mounted () {
    this.$store.commit('SET_OPEN_SIDE_BAR', false)
    this.loadOrganizationData()
  },
  methods: {
    async loadOrganizationData () {
      this.loading = true
      try {
        await this.organization.loadSubscription()
        await this.organization.loadConsumptions()
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    },
    getUsageBackgroundColor (dataplant, srv) {
      const value = dataplant.getUsage(srv)
      if (value <= 0) return 'rgba(246, 249, 252, 0.5)'
      if (value < 3) return '#00CCF9'
      return '#0089c0'
    },
    getUsageColor (dataplant, srv) {
      const value = dataplant.getUsage(srv)
      if (value <= 0) return '#3E4550'
      if (value < 3) return '#fff'
      return '#fff'
    }
  }
}
</script>

<style lang="less">
.organization {
  height:100%;
  background-color: rgba(151,167,183,.05);
  .page-content {
    height: calc(100% - 87px);
    overflow: auto;
  }
  .box-relative {
    position: relative;
  }
  .select-type-fpu {
    position: absolute;
    right:12px;
    top:125px;
    width: 100px;
  }
  .box-stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    font-weight: 600;
    margin-bottom: 20px;
    .box-stats-items {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-basis: 0px;
      flex-shrink: 1;
      flex-grow: 1;
      height: 67px;
      .value {
        font-size: 22px;
        color: @text-color;
        line-height: 40px;
      }
      .label {
        color: @grey-chart;
        font-size: 15px;
        line-height: 15px;
        text-align: center;
      }
    }
  }
  .dataplants-current-usage {
    .th.vd {
      text-align: center;
    }
    .vd.td {
      text-align: center;
    }
    .placeholder {
      text-align: center;
      padding: 50px 0;
      img {
        width: 100px;
        display: inline-block
      }
      .text {
        color: #97a7b7;
        font-weight: 700;
        font-size: 12px;
      }
    }
  }

}
</style>
