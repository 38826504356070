import DmLeftButton from './DmLeftButton.vue'
import DmRightButton from './DmRightButton.vue'
import DmMainTable from './DmMainTable.vue'
import DmSecondTable from './DmSecondTable.vue'
import DmLeftKpi from './DmLeftKpi.vue'
import DmRightKpi from './DmRightKpi.vue'

export default {
  DmLeftButton,
  DmRightButton,
  DmMainTable,
  DmSecondTable,
  DmLeftKpi,
  DmRightKpi
}

export {
  DmLeftButton,
  DmRightButton,
  DmMainTable,
  DmSecondTable,
  DmLeftKpi,
  DmRightKpi
}
