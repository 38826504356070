<template lang="pug">
.kpi.control-center(
  v-tooltip="$t('project.home.control-center.kpi_tooltip_left')"
)
  fp-loader(v-if="loading" type="chart")
  .value(
    v-if="!loading"
    :style="{ color: value && value !== '-' ? '#F62172' : '#0089C0' }"
  ) {{ value }}
  .text(
    v-if="!loading"
  ) {{ $t('project.home.cc.active_alerts', [value], isNaN(value) ? 0 : value) }}
</template>

<script>
export default {
  computed: {
    value () {
      // Value are refreshed in ControlCenterMainTable when resize card
      return this.$store.getters.PROJECT_HOME_ACTIVE_ALERTS?.filter(a => a.status === 'active')?.length ?? '-'
    },
    loading () {
      return this.$store.getters.PROJECT_HOME_ACTIVE_ALERTS_LOADING
    }
  }
}
</script>

<style lang="less">

</style>
