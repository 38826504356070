<template lang="pug">
.data-project-creation(
  :style="{ 'margin-top': type === 'templates' ? '10px' : '21px' }"
)
  .fp-box.no-bg
    .box-title.with-link
      .title(
        v-if="type !== 'templates'"
      ) {{ $t('core.data_project_creation.title.' + type) }}
      .box-title-link.underline-hover(
        v-if="type !== 'templates'"
        @click="browseTemplates"
      )
        span {{ $t('core.data_project_creation.templates.browse_all') }}
        i.fp4.fp4-angle-right
    data-project-templates(
      v-if="type === 'templates'"
      :organization-id="organizationId"
      @create-project="createProject"
    )
    .empty(v-else-if="type === 'empty'")
      .empty-container
        .presentation-container
          img(:src="require('@/core/assets/img/project.svg')")
          .title {{ $t('core.data_project_creation.' + type + '.title') }}
          .subtitle {{ $t('core.data_project_creation.' + type + '.subtitle') }}
        .cta-container
          fpui-button.cta(
            color='green'
            icon='fp4 fp4-plus'
            icon-left
            auto-width
            :disabled="!canCreateProject"
            v-tooltip="{ content: !canCreateProject ? disabledMessage : '', delay: 0 }"
            @click='createProject()'
          ) {{ $t('core.data_project_creation.' + type + '.cta') }}
</template>

<script>
import _get from 'lodash/get'
import Store from '@/shared/components/store'
import DataplantCreationFullscreen from '@/shared/components/project/DataplantCreationFullscreen/DataplantCreationFullscreen.vue'
import SubscriptionNew from '@/core/components/Subscription/New.vue'
import DataProjectTemplates from '@/shared/components/project/DataProjectTemplates'

export default {
  components: {
    DataProjectTemplates
  },
  props: {
    type: {
      type: String,
      required: true,
      validator (v) { return ['empty', 'templates'].includes(v) }
    },
    organizationId: { type: String, default: () => '' },
    canCreateProject: { type: Boolean, default: true }
  },
  computed: {
    organization () {
      if (this.organizationId === '') return false
      return this.$store.getters.ORGANIZATION_BY_ID(this.organizationId)
    },
    subscription () {
      return this.organization?.subscription || null
    },
    subscriptionStatus () {
      if (this.organization.bypass_billing) return 'active'
      if (!this.subscription) return null
      return this.subscription?.status
    },
    disabledMessage () {
      return this.$t('core.onboarding.no_permission_to_create')
    }
  },
  async mounted () {
    await this.$store.dispatch('STORE_INIT', ['project'])
  },
  methods: {
    async createProject (template = null) {
      // Verifies the status subscription to either open the modal to create a project
      // Or the one to subscribe to a plan it is necessary to comment this part and to
      // This functionnality if you have no plans
      if (!this.subscription) await this.organization.loadSubscription()
      if (
        (['active', 'non_renewing'].includes(this.subscriptionStatus)) ||
        (this.subscription?.status === 'in_trial' && this.subscription?.payment_valid && this.subscription?.scheduled_status !== 'cancelled')
      ) {
        if (!this.$acl(this.organizationId).can('core', 'dataplants', 'create')) return
        this.trackOpenProjectFunnel(template)
        this.$modal.show(DataplantCreationFullscreen, {
          organizationId: this.organizationId,
          template
        }, {
          height: '100%',
          width: '100%',
          adaptative: true,
          classes: []
        })
      } else {
        this.updateTrialToRealPlan(template)
      }
    },
    async updateTrialToRealPlan (template = null) {
      await this.$modal.show(SubscriptionNew, {
        organizationId: this.organization._id,
        trial: this.organization?.subscription?.status === 'in_trial' && this.organization?.subscription?.scheduled_status === 'cancelled',
        onConfirm: () => this.createProject(template)
      }, {
        height: 'auto',
        width: 900
      })
    },
    // Also sends the template data so the DataplantCreationFullscreen starts at the second step
    browseTemplates () {
      this.$modal.show(Store, {
        name: this.$t('core.onboarding.store_template'),
        type: 'project.templates',
        onSelect: async (template, modal) => {
          const selectedTemplate = await this.templateById(template)
          this.createProject(selectedTemplate)
          modal.$emit('close')
        }
      }, {
        width: 980,
        height: 640
      })
    },
    async templateById (selectedTemplate) {
      try {
        const storeTemplates = this.$store.getters.STORE_PACKAGES.project?.templates
        const modules = this.$store.getters.STORE_PACKAGES.project?.modules
        const template = storeTemplates[selectedTemplate.id]
        const meta = await this.$api.STORE.metas('project', 'templates', selectedTemplate.id, 'latest')
        const services = Object.keys(meta.options?.services).map(service => {
          let icon = ''
          try {
            icon = require(`@/core/components/Dataplant/assets/${service}.svg`)
          } catch (err) {
            icon = require('@/core/assets/img/logo.png')
          }
          return {
            img: icon,
            service: _get(modules, [service, 'description'])
          }
        })
        return {
          id: selectedTemplate.id,
          services: services,
          title: template.description,
          description: template.long_description,
          image: template.image
        }
      } catch (err) {
        console.error('DataProjectCreation error : ', err)
      }
    },
    trackOpenProjectFunnel (template) {
      if (template) {
        this.$analytics.track('Open new project funnel', { from: 'welcome page', template: template.id, channel: 'new project template' })
      } else {
        this.$analytics.track('Open new project funnel', { from: 'welcome page', channel: 'new project' })
      }
    }
  }
}
</script>

<style lang="less">
.data-project-creation {
  margin-top: 21px;
  .empty {
    min-height: 220px;
    background: @white;
    border: 1px solid #E4E7EC;
    box-shadow: 0px 2px 4px rgba(52, 65, 78, 0.05);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    .empty-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      width: 350px;
      .presentation-container {
        padding-top: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      .title {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: @blue_flash;
        .box-title-link {
          &.underline-hover {
            &:hover {
              span {
                text-decoration: underline;
              }
            }
          }
        }
      }
      .subtitle {
        font-size: 14px;
        line-height: 20px;
        color: #748294;
        text-align: center;
        padding-top: 4px;
      }
      .cta-container {
        padding: 24px 0 20px 0;
      }
    }
  }
}
</style>
