<template lang="pug">
.main-table.am
  fpui-table(
    :columns='columns'
    :actions='actions'
    :data='items'
    :loading="loading"
    sort-on="updated_at"
    :height="fromLarge ? '143px' : '115px'"
    :empty="trinoActive || isAPHP || isDataPlatform ? {} : { text: $t('project_home.am.no_trino') }"
    :pagination="{ disabled: true }"
  )
</template>

<script>
import _orderBy from 'lodash/orderBy'
import moment from 'moment'

import Config from '@/shared/Config'

export default {
  props: {
    fromLarge: { type: Boolean, default: false }
  },
  data () {
    return {
      itemLimit: this.fromLarge ? 4 : 3,
      config: null
    }
  },
  computed: {
    trinoActive () {
      return this.$store.getters.PROJECT_TRINO_ACTIVE
    },
    hasTrinoOrMutualized () {
      return !!this.$store.getters.QB_RESOURCES_ACTIVE_OR_MUTUALIZED
    },
    isAPHP () {
      return this.hasTrinoOrMutualized && this.config?.CLUSTER === 'k8s-aphp'
    },
    isDataPlatform () {
      return this.hasTrinoOrMutualized && this.config?.DATA_PLATFORM_ACCESS
    },
    queryHistory () {
      return _orderBy(this.$store.getters.PROJECT_HOME_QUERY_HISTORY?.filter(qh => qh.dashboard_id), ['created_at'], ['desc'])
    },
    items () {
      let items = []
      let dashboards = this.$store.getters.PROJECT_HOME_DASHBOARDS || []
      if (dashboards.length) {
        dashboards = dashboards.map(d => {
          const qh = this.queryHistory.find(qh => qh.dashboard_id === d._id || qh.dashboard_id === d.shared_id)
          return {
            ...d,
            lastExecution: qh?.created_at
          }
        })
          .filter(d => d.lastExecution)
        items = _orderBy(dashboards, ['lastExecution'], ['desc']).slice(0, this.itemLimit)
      }

      return items
    },
    loading () {
      return this.$store.getters.PROJECT_HOME_DASHBOARDS_LOADING || this.$store.getters.PROJECT_HOME_QUERY_HISTORY_LOADING
    },
    actions () {
      return []
    },
    columns () {
      return [
        {
          label: () => {
            const href = `am/#/${this.$route.params.projectId}/dashboards`
            return `<a class='cell-link' href="${href}">${this.$t('project.home.am.recently_used_dashboards')}</a>`
          },
          name: 'name',
          target: row => {
            const href = `am/#/${this.$route.params.projectId}/dashboard/${row._id}`
            return `<a class='cell-link' href="${href}">${row.display_name}</a>`
          },
          paddingLeft: 24,
          grow: 1.2
        },
        {
          label: this.$t('project.home.am.last_viewed'),
          name: 'updated_at',
          reverse: true,
          target: (row) => {
            if (!row.lastExecution) return '-'
            return moment(row.lastExecution).fromNow()
          },
          tooltip: (row) => {
            if (!row.lastExecution) return '-'
            return moment(row.lastExecution).format('DD/MM/YYYY HH:mm')
          },
          grow: 0.8
        }
      ]
    },
    canAmQueryHistory () {
      return this.$store.getters.ACL('canAmQueryHistory')
    },
    canAmDashboardRead () {
      return this.$store.getters.ACL('canAmDashboardRead')
    }
  },
  async mounted () {
    this.config = await Config()
    if (!this.loading) {
      try {
        this.$store.dispatch('LOAD_AM_RESOURCES')
        if (this.canAmQueryHistory) this.$store.dispatch('LOAD_QUERY_HISTORY')
        if (this.canAmDashboardRead) this.$store.dispatch('LOAD_DASHBOARDS')
      } catch (err) {}
    }
  }
}
</script>

<style lang="less">
</style>
