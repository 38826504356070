<template lang="pug">
  .appservice-git-step
    fp-loader(v-if="loading")
    fpui-input-text(
      :placeholder="$t('appservice.create.repository.placeholder')"
      :label="$t('appservice.create.repository.label')"
      :value="value.repository.url"
      @input="(v)=>value.update('repository.url',v,false)"
      autofocus
    )

    .http(v-if="protocol === 'http' || protocol === 'https'")
      p {{ $t('appservice.create.authentication.text') }}
      fpui-input-radio(
        :placeholder="$t('appservice.create.authentication.placeholder')"
        :options="auths"
        :label="$t('appservice.create.authentication.label')"
        :value="value.repository.auth"
        @input="v => value.update('repository.auth', v, false)"
      )
      .authentication-form(v-if="value.repository.auth === 'private'")
        fpui-input-text(
          :placeholder="$t('appservice.create.repository.user.placeholder')"
          :label="$t('appservice.create.repository.user.label')"
          :value="value.repository.user"
          @input="v => value.update('repository.user', v, false)"
        )
        fpui-input-password(
          :placeholder="$t('appservice.create.repository.password.placeholder')"
          :label="$t('appservice.create.repository.password.label')"
          :value="value.repository.password"
          @input="(v)=>value.update('repository.password',v,false)"
        )
        br

    .ssh(v-if="protocol==='ssh'")
      p {{'appservice.create.ssh.text'|translate}}
      fpui-input-textarea(
        v-if="sshKey"
        :value="sshKey",
        :disabled="true"
      )
      br


    .branches(v-if="protocol === 'ssh' || protocol === 'http' || protocol === 'https'")
      fpui-button(
        color="blue"
        @click="fetch"
        v-if="!branches"
        :auto-width="true"
      ) {{'appservice.create.branch.fetch'|translate}}
      fpui-input-select(
        :placeholder="$t('appservice.create.branch.placeholder')"
        v-if="branches"
        :options="branches"
        :label="$t('appservice.create.branch.label')"
        :value="value.repository.branch"
        @input="(v)=>value.update('repository.branch',v,false)"
      )
      br
    br

</template>

<script>
import parseUrl from 'parse-url'

export default {
  props: {
    value: {
      required: true,
      type: Object
    }
  },
  data () {
    return {
      loading: false,
      sshKey: null,
      branches: null
    }
  },
  computed: {
    protocol () {
      if (!this.value.repository.url) return null
      const protocol = parseUrl(this.value.repository.url).protocol
      if (protocol === 'git') return 'ssh'
      return protocol
    },
    auths () {
      return [{
        label: this.$t('appservice.create.repository.public'),
        value: 'public'
      }, {
        label: this.$t('appservice.create.repository.private'),
        value: 'private'
      }]
    }
  },
  watch: {
    'value.repository.url' () {
      this.branches = null
    }
  },
  created () {
    if (this.value.repository.authentication === undefined) {
      this.value.update('repository.authentication', false, false)
      this.value.update('repository.auth', '', false)
    }
  },
  async mounted () {
    this.sshKey = await this.$api.APPSERVICE.sshPublic()
  },
  methods: {
    async fetch () {
      this.loading = true
      try {
        const branches = await this.$api.APPSERVICE.fetch(this.value.repository)
        this.branches = branches.map(b => ({ label: b, value: b }))
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less">
.appservice-git-step {
}
</style>
