<template lang="pug">
.accounts
  fp-loader(v-if="loading")
  .accounts-header
    account-select(
      :accounts="accounts",
      v-model="accountId"
      v-if="accounts.length && accountId"
      @newAccount="newAccount()"
    )
    .buttons-container
      .delete-button-container(:style="{ 'margin-right': getMarginRight }")
        fpui-button.delete-account(
          :disabled='!canDelete'
          color='red'
          icon="fp4 fp4-trash-can"
          icon-left
          @click="deleteAccount"
          auto-width
          noshadow
          reverse
        ) {{ $t('accounts.delete') }}
        fpui-helper(
          v-if='!canDelete'
          :text="$t('accounts.delete.helper')"
        )
      fpui-button(
        color="blue-flash",
        @click="save",
        v-if="accountId"
        :disabled="!account.hasChanges"
        auto-width
      ) {{ $t('button.save') }}
  account-view(v-if="accountId",:account-id="accountId")
</template>

<script>
import AccountView from './Account'
import AccountSelect from './AccountSelect'
import ConfirmModal from '@/shared/components/modals/Confirm'

export default {
  components: {
    AccountView,
    AccountSelect
  },
  async beforeRouteLeave (to, from, next) {
    if (!this.account?.hasChanges) return next()
    if (await ConfirmModal(this, {
      title: this.$t('modal.close_without_saving'),
      yes: { color: 'red' },
      icon: 'fp4 fp4-triangle-exclamation-small'
    })) next()
  },
  data () {
    return {
      loading: false,
      subscriptions: [],
      accountId: null
    }
  },
  computed: {
    getMarginRight () {
      if (this.$t('button.save') === 'Save') return '0px'
      return '20px'
    },
    account () {
      return this.$store.getters.ACCOUNT_BY_ID(this.accountId)
    },
    accounts () {
      return this.$store.getters.ACCOUNTS
    },
    canDelete () {
      return (this.subscriptions || []).length === 0 && this.accounts.length > 0
    }
  },
  watch: {
    accountId: {
      async handler (val) {
        const sub = await this.account.getSubscriptions()
        this.subscriptions = (sub || []).filter(s => !s.deleted_at && s.type === 'Plan')
      }
    }
  },
  destroyed () {
    window.removeEventListener('beforeunload', this.beforeUnload)
  },
  async mounted () {
    window.addEventListener('beforeunload', this.beforeUnload)
    // Init account
    if (this.accounts.length) {
      this.accountId = this.accounts[0]._id
    } else {
      await this.newAccount()
    }
  },
  methods: {
    async save () {
      this.loading = true
      try {
        await this.account.save()
        await this.$store.dispatch('LOAD_ACCOUNTS')
      } catch (err) {
        this.$fpuiMessageBlock.error(err)
        console.error(err)
      }
      this.loading = false
    },
    async newAccount () {
      this.loading = true
      const account = await this.$store.dispatch('CREATE_ACCOUNT')
      this.accountId = account._id
      this.loading = false
    },
    beforeUnload () {
      if (this.account.hasChanges) {
        event.preventDefault()
        event.returnValue = ''
      }
    },
    async deleteAccount () {
      const confirmResult = await ConfirmModal(this, {
        contentHtml: true,
        content: `<span style="font-size:14px">${this.$t('treeview.remove.modal.head')} <span style="font-weight:600; text-transform: capitalize" > ${this.account.billing_address?.company || this.account.company}</span>${this.$t('treeview.remove.modal.question_mark')}<br />${this.$t('treeview.remove.modal.tail')} </span><br>`,
        title: this.$t('accounts.delete.confirm'),
        yes: {
          text: this.$t('fpui-modal-confirm.remove'),
          color: 'red'
        }
      })
      if (!confirmResult) return false
      this.account.remove()
      this.$store.commit('REMOVE_ACCOUNT', this.account._id)
      this.accountId = this.accounts[0]._id
    }
  }
}
</script>

<style lang="less">
.accounts {
  position: relative;
  top: 6px;
  height: calc(~"100% - 44px");
  overflow: hidden;
  margin-left: 84px;
  margin-right: 83px;
  box-shadow: 0 3px 13px 0 rgb(151 167 183 / 30%);
  background-color: white;
  .accounts-header {
    height: 89px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .account-select {
      margin-left: 30px;
    }
    .buttons-container{
      margin-right: 30px;
      display: flex;
      align-items: center;
      .delete-button-container {
        width: 215px;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .fpui-helper {
          position: relative;
          margin-left: 0px;
          left: -10px;
        }
      }
    }
  }
  .account {
    height: calc(~"100% - 84px");
    .fpui-tabs {
      height : 100%;
      .fpui-tabs-contents {
        height: calc(~"100% - 29px");
        background: @background-color;
        box-shadow: none;
        overflow: auto;
      }
    }
  }
  .contact {
    margin: auto;
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .billing-infos {
    margin: auto;
    padding-top: 20px;
  }
  .receipts {
    width: calc(100% - 50px);
    margin: auto;
    padding-top: 20px;
  }
  .subscriptions {
    margin: auto;
    padding-top: 20px;
  }
}
</style>
