<template lang="pug">
  .appservice-create.fpui-modal
    fp-loader(v-if="loading")
    fpui-modal-header(
      :title="$t(`appservice.store.add.${type}`)"
      @close='$emit("close")'
      closeTopButton
    )
    fpui-modal-body
      // For custom mode we will use a stepper to import it
      div(v-if="template === 'import'")
        fpui-steps(
          ref="stepper"
          @complete="onComplete",
          :headers="true",
          :stepper="false"
          next-color="blue-flash"
          v-if="app"
        )
          fpui-step(:header="$t('appservice.create.import.head')")
            .git-links
              .git-link(@click="select(gitProvider)",v-for="gitProvider in gitProviders",:class="{disabled:gitProvider.disabled,active: gitProvider.id === app.repository.subtype }")
                i.fa(:class="`fa-${gitProvider.id}`")
                div {{gitProvider.id.toUpperCase()}}

            .zip-dropzone(
              v-dropzone="dropzoneOptions"
            )
              input(
                type="file"
                @change="onDrop($event.target.files)"
              )
              .placeholder
                i.fp4.fp4-up-from-line
                div.text {{ 'appservice.create.import.zip.drop.text' | translate }}
                div.desc(v-html="$t('appservice.create.import.zip.drop.desc')")
                //- If var is put in v-html -> use $sanitize()

          fpui-step(
            :header="$t('appservice.create.git.head')"
            v-if="app.repository.type === 'git' && app.repository.subtype === 'gitlab'"
          )
            gitlab-step(
              :value="app"
            )
          fpui-step(
            :header="$t('appservice.create.git.head')"
            v-if="app.repository.type === 'git' && app.repository.subtype === 'bitbucket'"
          )
            bitbucket-step(
              :value="app"
            )
          fpui-step(
            :header="$t('appservice.create.git.head')"
            v-if="app.repository.type === 'git' && app.repository.subtype === 'github'"
          )
            github-step(
              :value="app"
            )
          fpui-step(
            :header="$t('appservice.create.git.head')"
            :validate="gitReady"
            v-if="app.repository.type === 'git' && app.repository.subtype === 'git'"
          )
            git-step(
              :value="app"
            )
          fpui-step(
            :header="$t('appservice.create.settings.head')"
            :validate="!createDisabled"
            :disabled="createStepDisabled"
          )
            create-step(
              :value="app",
              :type="type"
              :applications="applications",
            )
      // For store mode, we go direct to last step
      div(v-else)
        create-step(
          v-if="app"
          :value="app",
          :type="type"
          :applications="applications",
        )
    fpui-modal-footer(:buttons="buttons",v-if="template !== 'import'")
</template>

<script>
import Config from '@/shared/Config'
import CreateStep from './CreateStep.vue'
import GitStep from './GitStep.vue'
import GitlabStep from './GitlabStep.vue'
import GithubStep from './GithubStep.vue'
import BitbucketStep from './BitbucketStep.vue'
import { Application } from '@/shared/plugins/Api/APPSERVICE/Applications'
export default {
  components: { CreateStep, GitStep, GitlabStep, GithubStep, BitbucketStep },
  props: {
    applications: {
      required: true,
      type: Array
    },
    type: {
      required: true,
      type: String
    },
    template: {
      required: true,
      type: String
    },
    onCreate: {
      required: true,
      type: Function
    }
  },
  data () {
    return {
      loading: false,
      app: null
    }
  },
  computed: {
    gitProviders () {
      return [{
        id: 'gitlab',
        disabled: true
      }, {
        id: 'bitbucket',
        disabled: true
      }, {
        id: 'github',
        disabled: false
      }, {
        id: 'git',
        disabled: false
      }]
    },
    createStepDisabled () {
      if (this.app.repository.type === 'git') {
        return !this.app.repository.branch || !this.app.repository.url
      }
      return !this.app.file
    },
    gitReady () {
      return !!this.app.repository?.branch
    },
    createDisabled () {
      return !this.app?.display_name
    },
    buttons () {
      return [
        {
          label: this.$t('button.cancel'),
          type: 'close',
          handler: () => {
            this.$emit('close')
          }
        }, {
          label: this.$t('button.create'),
          color: 'green',
          disabled: this.createDisabled,
          handler: async () => {
            try {
              this.loading = true
              await this.app.create()
              this.$emit('close')
              this.onCreate(this.app)
            } catch (err) {
              console.error(err)
              this.$fpuiMessageBlock.error(err)
            }
            this.loading = false
          }
        }
      ]
    },
    dropzoneOptions () {
      return {
        onDrop: this.onDrop
      }
    }
  },
  async mounted () {
    const config = await Config()
    this.app = new Application({
      type: this.type,
      url: this.template !== 'import' ? `${config.STORE}/v1/packages/${this.type}/templates/${this.template}/latest` : null
    })
  },
  methods: {
    onDrop (files) {
      this.app.update('repository.type', 'zip', false)
      this.app.update('repository.subtype', null, false)
      this.app.update('url', '', false)
      this.app.update('file', files[0], false)
      process.nextTick(() => {
        this.$refs.stepper.next()
      })
    },
    select (provider) {
      if (provider.disabled) return
      this.app.update('repository.type', 'git', false)
      this.app.update('repository.subtype', provider.id, false)
    },
    async onComplete () {
      // Prevent second click on create
      if (this.loading) return

      try {
        this.loading = true
        await this.app.create()
        this.$emit('close')
        this.onCreate(this.app)
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    }
  }
}
</script>

<style lang="less">
.appservice-create.fpui-modal {

  .git-links {
    .git-link {
      text-align: center;
      display: inline-block;
      width: calc(25% - 10px);
      height: 170px;
      margin: 5px;
      cursor: pointer;
      box-shadow: 0 3px 10px 0 rgb(0 0 0 / 20%);
      position: relative;
      font-size: 20px;
      font-weight: 200;
      i {
        padding: 30px;
        color: #0089c0;
        font-size: 60px;
        display: block;
      }
      &:hover,&.active {
        background: #0089c0;
        color: white;
        i {
          color: white;
        }
      }
      &.disabled {
        cursor: not-allowed;
        opacity: 0.6;
      }
    }
  }
  .zip-dropzone {
    width: 50%;
    margin: 5px auto;
    text-align: center;
    position: relative;
    height: 170px;
    border: 1px dashed #ddd;
    font-size: 20px;
    font-weight: 200;
    i {
      padding: 20px;
      font-size: 60px;
      color: #0089c0;
      display: block;
    }
    .desc {
      color: #ccc;
      font-size: 12px;
    }
    input {
      position: absolute;
      left:0;
      right: 0;
      cursor: pointer;
      width: 100%;
      bottom: 0;
      top: 0;
      opacity: 0;
    }
    &:hover {
      .text {
        font-weight: 400;
      }
    }
  }
  .fpui-steps-content {
    padding-top: 10px;
  }
  .fpui-steps-footer {
    .actions {
      justify-content: flex-end;
      > * {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }
}
</style>
