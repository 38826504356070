<template lang="pug">
.second-table.dm
  fpui-table(
    :columns='columns'
    :actions='actions'
    :data='items'
    :loading="loading"
    :force-loader="true"
    sort-on="created_at"
    :height="'143px'"
    :pagination="{ disabled: true }"
  )
</template>

<script>
import _orderBy from 'lodash/orderBy'
import moment from 'moment'

export default {
  props: {
    fromLarge: { type: Boolean, default: false }
  },
  data () {
    return {
      items: [],
      loading: false,
      itemLimit: 4
    }
  },
  computed: {
    actions () {
      return []
    },
    columns () {
      return [
        {
          label: () => {
            const href = `lakehouse/#/${this.$route.params.projectId}/datasets`
            return `<a class='cell-link' href="${href}">${this.$t('project.home.lakehouse.recently_created_datasets')}</a>`
          },
          name: 'name',
          target: row => {
            const href = `lakehouse/#/${this.$route.params.projectId}/dataset/${row._id}`
            return `<a class='cell-link' href="${href}">${row.display_name}</a>`
          },
          paddingLeft: 24,
          grow: 1.2
        },
        {
          label: this.$t('project.home.lakehouse.created_at_dataset'),
          name: 'created_at',
          reverse: true,
          target: row => {
            if (!row.created_at) return '-'
            return moment(row.created_at).fromNow()
          },
          tooltip: (row) => {
            if (!row.created_at) return '-'
            return moment(row.created_at).format('DD/MM/YYYY HH:mm')
          },
          grow: 0.8
        }
      ]
    },
    projectId () {
      return this.$route.params?.projectId
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    serviceReady () {
      return !Object.keys(this.project?.display_settings?.services_wizard || {}).includes('dm')
    },
    canDmDatabaseRead () {
      return this.$store.getters.ACL('canDmDatabaseRead')
    }
  },
  watch: {
    serviceReady: {
      handler (val) {
        if (val) this.load()
      }
    },
    '$store.getters.PROJECT_CARD_INFO_LOADING': {
      handler (val) {
        if (!val) this.load()
      }
    }
  },
  async mounted () {
    if (!this.$store.getters.PROJECT_CARD_INFO_LOADING) await this.load()
  },
  methods: {
    async load () {
      if (this.canDmDatabaseRead && this.serviceReady) {
        this.loading = true

        try {
          const datasets = await this.$api.DM.databases.list()
          this.items = _orderBy(datasets, ['created_at'], ['desc']).slice(0, this.itemLimit)
        } catch (err) {
          // Do nothing
        }

        this.loading = false
      }
    }
  }
}
</script>

<style lang="less">
</style>
