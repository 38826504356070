<template lang="pug">
.main-table.control-center
  fpui-table(
    :columns='columns'
    :actions='actions'
    :data='items'
    :loading="loading"
    :empty="emptyPlaceholder"
    :class="{ custom: canCcAlertRead }"
    :height="!items.length ? '84px' : `${(items.length + 1) * 30 > 290 ? 290 : (items.length + 1) * 30}px`"
    :pagination="{ disabled: true }"
  )
</template>

<script>
import moment from 'moment'
import Duration from '@/shared/filters/Duration'
import Config from '@/shared/Config'

export default {
  props: {
    fromLarge: { type: Boolean, default: false }
  },
  data () {
    return {
      config: null
    }
  },
  computed: {
    items () {
      return this.$store.getters.PROJECT_HOME_ACTIVE_ALERTS?.filter(a => a.status === 'active') || []
    },
    loading () {
      return this.$store.getters.PROJECT_CARD_INFO_LOADING || this.$store.getters.PROJECT_HOME_ACTIVE_ALERTS_LOADING
    },
    actions () {
      return []
    },
    columns () {
      const columns = [
        {
          name: 'name',
          label: () => {
            const href = `cc/#/${this.config?.ORGANIZATION_ID}/${this.config?.PROJECT_ID}/alerts`
            return `<a class='cell-link' href="${href}">${this.$t('project.home.cc.name')}</a>`
          },
          target: row => {
            let href = ''
            if (row.id === 'storageEngine') {
              href = `cc/#/${this.config?.ORGANIZATION_ID}/alert/${row._id}`
            }
            href = `cc/#/${this.config?.ORGANIZATION_ID}/${this.config?.PROJECT_ID}/alert/${row._id}`

            return `
            <div class="alert-name-container status-name-container">
              <i class="fp4 fp4-triangle-exclamation"></i><a class='cell-link' href="${href}">${row.display_name}</a>
            </div>
            `
          },
          paddingLeft: 24,
          grow: 1.2
        }
      ]

      if (this.items.length) {
        columns.push({
          name: 'duration',
          label: this.$t('project.home.cc.duration'),
          target: (row) => {
            return Duration((moment() - moment(row.activated_at)) / 1000)
          },
          grow: 0.8
        })
      }

      return columns
    },
    emptyPlaceholder () {
      return this.canCcAlertRead ? { text: this.$t('project.home.cc.no_alerts'), icon: 'fp4 fp4-circle-check' } : {}
    },
    canCcAlertRead () {
      return this.$store.getters.ACL('canCcAlertRead')
    }
  },
  async mounted () {
    this.config = await Config()
    if (!this.loading && this.canCcAlertRead) {
      try {
        this.$store.dispatch('LOAD_ACTIVE_ALERTS')
      } catch (err) {}
    }
  }
}
</script>

<style lang="less">
.main-table.control-center {
  height: auto !important;
  .fpui-table {
    &.custom {
      .fpui-table_empty-body {
        background-color: #F6F9FC;
        border-radius: 10px;
        margin: 15px 20px 12px 20px;
        font-style: italic;
        height: auto;
        i {
          color: @green;
        }
      }
    }
    .alert-name-container {
      display: flex;
      align-items: center;
      .fp4-triangle-exclamation {
        margin-right: 5px;
        color: #F62172;
        font-size:16px;
      }
      .cell-link {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
  }
}
</style>
