import '@/shared'
import Analytics from '@/shared/Analytics'
import { setConfig } from '@/shared/Config'
import I18n from '@/shared/I18n'
import '@/shared/Sentry'
import FpStore from '@/shared/store'
import 'font-awesome/css/font-awesome.min.css'
import Vue from 'vue'
import App from './App'
import appConfig from './config/global'
import FpRouter from './router'
import './StatusPageWidget'
import coreStore from './store'

FpStore.registerModule('core', coreStore)
setConfig(appConfig)
I18n.load('core')

// Vue config
Vue.config.devtools = process.env.NODE_ENV === 'development'
Vue.config.productionTip = true
Analytics.init(FpRouter, 'FPUI')

// eslint-disable-next-line no-new
new Vue({
  el: '#root',
  components: { App },
  template: '<App/>',
  router: FpRouter,
  store: FpStore
})
