import AppLeftButton from './AppLeftButton.vue'
import AppMainTable from './AppMainTable.vue'
import AppLeftKpi from './AppLeftKpi.vue'
import AppRightKpi from './AppRightKpi.vue'

export default {
  AppLeftButton,
  AppMainTable,
  AppLeftKpi,
  AppRightKpi
}

export {
  AppLeftButton,
  AppMainTable,
  AppLeftKpi,
  AppRightKpi
}
