<template lang="pug">
.left-button.mlm
  a(
    :href="link"
    @click="goTo"
  )
    fpui-button(
      color="white"
      :disabled="disabled"
      v-tooltip="{ content: !canMlNotebookWrite ? $t('dashboard.access.tooltip') : $t('project.home.mlm.button_tooltip_left'), delay: 0 }"
    ) {{ $t('project.home.ml.new_notebook') }}
</template>

<script>
export default {
  props: {
    editing: { type: Boolean, required: true }
  },
  computed: {
    projectId () {
      return this.$route.params?.projectId
    },
    link () {
      return `/ml/#/${this.projectId}/notebooks?newNotebook=true`
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    serviceReady () {
      return !Object.keys(this.project?.display_settings?.services_wizard || {}).includes('mlm')
    },
    canMlNotebookWrite () {
      return this.$store.getters.ACL('canMlNotebookWrite')
    },
    disabled () {
      return !this.canMlNotebookWrite || !this.serviceReady || this.editing
    }
  },
  methods: {
    goTo (e) {
      if (this.disabled) {
        e.preventDefault()
      } else {
        this.$analytics.track('Click on project home card’s shortcut CTA', {
          service: 'Machine Learning Manager',
          action: 'new notebook'
        })
      }
    }
  }
}
</script>

<style lang="less">

</style>
