<template lang="pug">
.second-table.am
  fpui-table(
    :columns='columns'
    :actions='actions'
    :data='items'
    :loading="loading"
    sort-on="updated_at"
    height="143px"
    :empty="trinoActive || isAPHP || isDataPlatform ? {} : { text: $t('project_home.am.no_trino') }"
    :pagination="{ disabled: true }"
  )
</template>

<script>
import _orderBy from 'lodash/orderBy'
import moment from 'moment'

import Config from '@/shared/Config'

export default {
  props: {
    fromLarge: { type: Boolean, default: false }
  },
  data () {
    return {
      queries: [],
      loadingQueries: false,
      itemLimit: 4,
      config: null
    }
  },
  computed: {
    trinoActive () {
      return this.$store.getters.PROJECT_TRINO_ACTIVE
    },
    hasTrinoOrMutualized () {
      return !!this.$store.getters.QB_RESOURCES_ACTIVE_OR_MUTUALIZED
    },
    isAPHP () {
      return this.hasTrinoOrMutualized && this.config?.CLUSTER === 'k8s-aphp'
    },
    isDataPlatform () {
      return this.hasTrinoOrMutualized && this.config?.DATA_PLATFORM_ACCESS
    },
    queryHistory () {
      return _orderBy(this.$store.getters.PROJECT_HOME_QUERY_HISTORY?.filter(qh => !qh.dashboard_id && qh.query_id), ['created_at'], ['desc'])
    },
    items () {
      let items = []
      if (this.queries.length) {
        const queries = this.queries.map(q => {
          const qh = this.queryHistory.find(qh => qh.query_id === q._id)
          return {
            ...q,
            lastExecution: qh?.created_at
          }
        })
          .filter(q => q.lastExecution)
        items = _orderBy(queries, ['lastExecution'], ['desc']).slice(0, this.itemLimit)
      }

      return items
    },
    loading () {
      return this.loadingQueries || this.$store.getters.PROJECT_HOME_QUERY_HISTORY_LOADING
    },
    actions () {
      return []
    },
    columns () {
      return [
        {
          label: () => {
            const href = `am/#/${this.$route.params.projectId}/queries`
            return `<a class='cell-link' href="${href}">${this.$t('project.home.am.recently_used_queries')}</a>`
          },
          name: 'name',
          target: row => {
            const href = `am/#/${this.$route.params.projectId}/query/${row._id}`
            return `<a class='cell-link' href="${href}">${row.display_name}</a>`
          },
          paddingLeft: 24,
          grow: 1.2
        },
        {
          label: this.$t('project.home.am.last_played'),
          name: 'updated_at',
          reverse: true,
          target: (row) => {
            if (!row.lastExecution) return '-'
            return moment(row.lastExecution).fromNow()
          },
          tooltip: (row) => {
            if (!row.lastExecution) return '-'
            return moment(row.lastExecution).format('DD/MM/YYYY HH:mm')
          },
          grow: 0.8
        }
      ]
    },
    projectId () {
      return this.$route.params?.projectId
    },
    project () {
      return this.$store.getters.DATAPLANT_BY_ID(this.projectId)
    },
    serviceReady () {
      return !Object.keys(this.project?.display_settings?.services_wizard || {}).includes('am')
    },
    canAmQueryRead () {
      return this.$store.getters.ACL('canAmQueryRead')
    }
  },
  watch: {
    serviceReady: {
      handler (val) {
        if (val) this.load()
      }
    },
    '$store.getters.PROJECT_CARD_INFO_LOADING': {
      handler (val) {
        if (!val) this.load()
      }
    }
  },
  async mounted () {
    this.config = await Config()
    if (!this.$store.getters.PROJECT_CARD_INFO_LOADING) await this.load()
  },
  methods: {
    async load () {
      if (this.canAmQueryRead && this.serviceReady) {
        this.loadingQueries = true

        try {
          this.queries = await this.$api.QUERY_ADMIN.queries.list()
        } catch (err) {
          // Do nothing
        }

        this.loadingQueries = false
      }
    }
  }
}
</script>

<style lang="less">
</style>
