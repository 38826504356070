<template lang="pug">
  .billing-infos
    fp-loader(v-if="loading")
    .fp-container
      .fp-column
        .fp-row
          .fp-box
            .box-title {{ $t('accounts.billing.card.title') }}
            .display-payments(v-if="payments.length > 0")
              payment-radio-group(
                :payments="payments"
                @setPrimary="setPrimary"
                @removePayment="removePayment"
              )
              fpui-button(
                v-if="!isShowAddPayment"
                @click="toggleAddCard",
                auto-width
                color='white'
                icon='fp4 fp4-plus'
                icon-left
              ) {{ $t('accounts.billing.card.add_option') }}
            .no-payments(v-if="!payments.length || isShowAddPayment")
              .label {{ $t('accounts.billing.card.info') }}
              .card-input
                fp-loader(v-if="loadingChargeBee")
                div(v-else)
                  card-component(
                    ref="cardComponent"
                    :classes="classes"
                    :styles="styles"
                  )
                    .box-line
                      .label {{ $t('accounts.billing.card.number') }}
                      card-number.card-input-line.card-number
                    .box-line
                      .label {{ $t('accounts.billing.card.expiration') }}
                      card-expiry.card-input-line
                    .box-line
                      .label {{ $t('accounts.billing.card.cvv') }}
                      card-cvv.card-input-line
                .buttons-line.footer-buttons
                  fpui-button.cancel(
                    v-if="payments.length > 0"
                    @click="toggleAddCard",
                    auto-width
                    color='white'
                    style="margin-right: 10px"
                  ) {{ $t('button.cancel') }}
                  fpui-button(
                    id="tokenize"
                    type="submit"
                    color="blue-flash",
                    @click="onSubmit",
                    auto-width
                  ) {{ $t('button.submit') }}
      .fp-column(v-if="account.billing_address")
        .fp-row
          .fp-box
            .box-title {{ $t('accounts.billing.info.title') }}
            fpui-input-checkbox(v-model="useSameInfo") {{ $t('accounts.billing.info.use_same') }}
            .name-container
              fpui-input-text(
                :disabled="useSameInfo"
                :label="$t('accounts.billing.info.recipient_first_name')",
                :value="account.billing_address.first_name",
                @input="(value) => update('billing_address.first_name', value)"
              )
              fpui-input-text(
                :disabled="useSameInfo"
                :label="$t('accounts.billing.info.recipient_last_name')",
                :value="account.billing_address.last_name",
                @input="(value) => update('billing_address.last_name', value)"
              )
            .box-line
              fpui-input-text(
                :disabled="useSameInfo"
                :label="$t('accounts.email')",
                :value="account.billing_address.email",
                @input="(value) => update('billing_address.email', value)"
              )
            .box-line
              fpui-input-text(
                :disabled="useSameInfo"
                :label="$t('accounts.company')",
                :value="account.billing_address.company",
                @input="(value) => update('billing_address.company', value)"
              )
            .box-line
              fpui-input-text(
                :label="$t('accounts.billing.info.vat_number')",
                :value="account.vat_number",
                optional
                @input="(value) => update('vat_number', value)"
              )
            .box-line
              .label {{ $t('accounts.phone') }}
              fpui-input-phone(
                :disabled="useSameInfo"
                :value="account.billing_address.phone",
                @input="(value) => update('billing_address.phone', value)",
                :preferedCountries="['fr', 'us']"
              )
            .box-line
              fpui-input-text(
                :disabled="useSameInfo"
                :label="$t('accounts.line1')",
                :value="account.billing_address.line1",
                @input="(value) => update('billing_address.line1', value)"
              )
            .box-line
              fpui-input-text(
                :disabled="useSameInfo"
                :label="$t('accounts.line2')",
                :value="account.billing_address.line2",
                @input="(value) => update('billing_address.line2', value)"
              )
            .box-line
              .line3-container
                fpui-input-text(
                  :disabled="useSameInfo"
                  :label="$t('accounts.zip')",
                  :value="account.billing_address.zip",
                  @input="(value) => update('billing_address.zip', value)"
                )
                fpui-input-text(
                  :disabled="useSameInfo"
                  :label="$t('accounts.city')",
                  :value="account.billing_address.city",
                  @input="(value) => update('billing_address.city', value)"
                )
            .box-line
              fpui-input-country(
                :disabled="useSameInfo"
                :label="$t('accounts.country')",
                :value="account.billing_address.country",
                @input="(value) => update('billing_address.country', value)",
              )
              fpui-input-state(
                :disabled="useSameInfo"
                :country="account.billing_address.country"
                :label="$t('accounts.state')",
                :value="account.billing_address.state",
                @input="(value) => update('billing_address.state', value)",
              )
</template>
<script>
import { CardComponent, CardNumber, CardExpiry, CardCvv } from '@chargebee/chargebee-js-vue-wrapper'
import PaymentRadioGroup from './PaymentRadioGroup'

export default {
  components: { CardComponent, CardNumber, CardExpiry, CardCvv, PaymentRadioGroup },
  props: {
    account: { type: Object, required: true },
    contactChanged: { type: Boolean, default: () => {} }
  },
  data () {
    return {
      payments: [],
      loading: true,
      loadingChargeBee: true,
      useSameInfo: false,
      isShowAddPayment: false,
      field: [
        'first_name', 'last_name',
        'email',
        'company',
        'phone',
        'line1',
        'line2',
        'line3',
        'zip',
        'city',
        'country'
      ],
      classes: {
        focus: 'focus',
        invalid: 'invalid',
        empty: 'empty',
        complete: 'complete'
      },
      styles: {
        base: {
          color: '#3E4550',
          fontSize: '14px',
          '::placeholder': {
            color: 'rgba(62,69,80,0.35)'
          }
        }
      }
    }
  },
  watch: {
    useSameInfo: function (val) {
      if (val) {
        const billingCopy = {}
        for (const key of this.field) {
          billingCopy[key] = this.account[key]
        }
        this.update('billing_address', billingCopy)
      }
    },
    contactChanged: {
      handler (newVal) {
        // React according to the result of update contact
        if (newVal && this.useSameInfo) {
          const billingCopy = {}
          for (const key of this.field) {
            billingCopy[key] = this.account[key]
          }
          this.update('billing_address', billingCopy)
        }
        this.$emit('update:contactChanged', false)
      }
    }
  },
  async mounted () {
    this.loadingChargeBee = true
    this.loading = true
    await this.$store.dispatch('INIT_CHARGEBEE', this.account.preferred_currency_code)
    this.loadingChargeBee = false
    try {
      this.payments = await this.account.getPaymentSources()
    } catch (err) {
      this.$fpuiMessageBlock.error(err)
    }
    this.loading = false
    this.initCheckBox()
  },
  methods: {
    async setPrimary (payment) {
      this.loading = true
      await this.account.setPaymentPrimary(payment.id)
      this.payments = await this.account.getPaymentSources()
      this.loading = false
    },
    async removePayment (payment) {
      this.loading = true
      await this.account.removePaymentSource(payment.id)
      this.payments = await this.account.getPaymentSources()
      this.loading = false
    },
    initCheckBox () {
      // Check if the contactChangedCheckbox should be check
      for (const key of this.field) {
        if (this.account[key] !== this.account.billing_address?.[key]) {
          return
        }
      }
      this.useSameInfo = true
    },
    update (key, value) {
      if (Object.keys(this.account).length > 0) {
        this.account.update(key, value, false)
      }
    },
    async onSubmit (e) {
      e.preventDefault()
      this.loading = true
      try {
        const data = await this.$refs.cardComponent.tokenize()
        await this.account.createCard(data.token)
        this.payments = await this.account.getPaymentSources()
        this.toggleAddCard()
      } catch (err) {
        console.error(err)
        this.$fpuiMessageBlock.error(err)
      }
      this.loading = false
    },
    toggleAddCard () {
      this.isShowAddPayment = !this.isShowAddPayment
    }
  }
}
</script>
<style lang="less">
  .billing-infos {
    .fp-container {
      .fp-box {
        padding-bottom: 20px;
        .box-line {
          margin-top: 15px;
        }
        .card-input {
          .card-input-line{
            height       : 32px;
            margin-bottom: 15px;
            border-radius: 3px;
            background   : rgba(151, 167, 183, 0.06) none repeat scroll 0% 0%;
            border       : 1px solid rgba(151, 167, 183, 0.21);
            padding      : 8px 12px 12px 10px;
            >div {
              height: 32px;
              >iframe {
                height: 32px !important;
              }
            }
          }
          .buttons-line {
            display: flex;
            justify-content: flex-end;
            margin-top: 30px;
            .footer-buttons {
              display: flex;
            }
          }
        }
        .fpui-input-checkbox {
          .content-checkbox {
            font-weight: 600;
          }
        }
        .name-container {
          margin-top: 15px;
          display: flex;
          justify-content: space-between;
          .fpui-input-text {
            width: 48%;
          }
        }
        .fpui-input-phone {
          width: 100%;
          padding-right: 0px;
        }
        .line3-container {
          display: flex;
          justify-content: space-between;
          .fpui-input-text {
            width: 48%;
          }
        }
      }
    }
  }
</style>
